import Chart from 'chart.js';
import moment from 'moment';

export const getDashboardTimeChartConfig = ({
  borderColor,
  data,
  yLabelString,
}: {
  borderColor: string;
  data: { x: number | string; y: number | string }[];
  yLabelString: string;
}): Chart.ChartConfiguration => {
  return {
    type: 'line',
    data: {
      datasets: [
        {
          borderColor,
          backgroundColor: borderColor,
          fill: false,
          pointRadius: 8,
          pointHoverRadius: 10,
          showLine: false,
          data,
          cubicInterpolationMode: 'monotone',
          steppedLine: 'middle',
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: { display: false },
      scales: {
        xAxes: [
          {
            type: 'time',
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Date',
            },
            ticks: {
              major: {
                fontStyle: 'bold',
                fontColor: '#FF0000',
              },
            },
          },
        ],
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
              labelString: yLabelString,
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          title: function (tooltipItem, data) {
            return JSON.stringify(
              moment(tooltipItem[0].xLabel).format('YYYY.MM.DD'),
            );
          },
        },
      },
    },
  };
};
