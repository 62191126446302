import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReduxState } from 'store';
import { JwtAuthTokenData } from 'types';

export interface AuthState {
  isLogged: boolean;
  isChecked: boolean;
  error: null | string;
  data: null | JwtAuthTokenData;
}

const initialState: AuthState = {
  isLogged: false,
  isChecked: false,
  error: null,
  data: null,
};

const selector = {
  state: (state: ReduxState) => state.app.auth,
};

const SLICE_NAME = '@app/auth';

const { actions, reducer } = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset() {
      return initialState;
    },

    identify(state, action: PayloadAction<JwtAuthTokenData>) {
      return {
        isLogged: true,
        isChecked: true,
        error: null,
        data: action.payload,
      };
    },

    anonymize(
      state,
      action: PayloadAction<undefined | { error?: AuthState['error'] }>,
    ) {
      return {
        ...initialState,
        isChecked: true,
      };
    },
  },
});

interface AuthSlice {
  action: typeof actions;
  reducer: typeof reducer;
  selector: typeof selector;
}

export const auth: AuthSlice = {
  action: actions,
  reducer,
  selector,
};
