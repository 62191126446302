import React, { useEffect, useState } from 'react';

import { AutoComplete } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { usePrevious } from 'react-use';

import './KitAutoComplete.scss';

const cx = createCn('KitAutoComplete');

interface Props extends SelectProps<any> {
  className?: string;
  value?: number | string;
}

export const KitAutoComplete: React.FC<Props> = ({
  className,
  value,
  ...options
}) => {
  const [search, setSearch] = useState<string>('');
  const [localValue, setLocalValue] = useState<string>();

  const onChange = (
    ...args: Parameters<Exclude<Props['onChange'], undefined>>
  ) => {
    setSearch(args[0] || '');
    setLocalValue(undefined);

    if (value !== undefined) {
      options.onChange && options.onChange(undefined, args[1]);
    }
  };

  const onSelect = (
    ...args: Parameters<Exclude<Props['onSelect'], undefined>>
  ) => {
    setSearch((args[1]?.label as undefined | string) ?? '');
    options.onSelect
      ? options.onSelect(...args)
      : options.onChange && options.onChange(...args);

    setLocalValue(args[0]);
  };

  const onBlur = (...args: Parameters<Exclude<Props['onBlur'], undefined>>) => {
    options.onBlur && options.onBlur(...args);

    if (!value && search) {
      setSearch('');
    }
  };

  const prevSearch = usePrevious(search);

  useEffect(() => {
    const text = options?.options?.find(
      (option: any) => option?.value === value?.toString(),
    )?.label as string;

    if (prevSearch !== text && localValue !== value?.toString()) {
      setLocalValue(value?.toString());
      setSearch(text ?? '');
    }
  }, [localValue, options, prevSearch, value]);

  return (
    <AutoComplete
      {...options}
      className={cn(
        cx({ error: !value && search.length && !options?.options?.length }),
        className,
      )}
      onChange={onChange}
      onSelect={onSelect}
      onBlur={onBlur}
      value={search}
    />
  );
};

KitAutoComplete.defaultProps = {
  disabled: false,
};
