import React from 'react';

import { createCn } from 'bem-react-classname';

import './KitFormContainer.scss';

const cx = createCn('KitFormContainer');

interface Props {
  children: React.ReactNode;
}

export const KitFormContainer: React.FC<Props> = ({ children }) => {
  return (
    <div className={cx()}>
      <div className={cx('block')}>{children}</div>
    </div>
  );
};
