import React from 'react';

import { Form, Input, Button, Select, Divider } from 'antd';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { useFormError } from 'hooks';
import { map } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { useLifecycles } from 'react-use';
import { server } from 'store';
import { AnyValues } from 'types';
import { Kit } from 'ui/Kit';

import './ProjectForm.scss';

const cx = createCn('ProjectForm');

interface Props {
  disabled?: boolean;
  onFinish: (values: any) => void;
  formError: ReturnType<typeof useFormError>;
  action: 'create' | 'edit';
  initialValues:
    | AnyValues<
        Parameters<typeof server.createProject.thunk.request>[0]['body']
      >
    | AnyValues<
        Parameters<typeof server.updateProjectById.thunk.request>[0]['body']
      >;
  options?: {
    canChangeSlug?: boolean;
  };
}

export const ProjectForm: React.FC<Props> = ({
  disabled,
  onFinish,
  formError,
  action,
  initialValues,
  options,
}) => {
  const dispatch = useDispatch();

  const defOptions = { canChangeSlug: true, ...options };

  useLifecycles(
    () => {
      dispatch(server.getLocalesList.thunk.request());
    },
    () => {
      dispatch(server.getLocalesList.action.reset());
      dispatch(server.createProject.action.reset());
      dispatch(server.updateProjectById.action.reset());
    },
  );

  const getLocalesListState = useSelector(server.getLocalesList.selector.state);

  const aggDisabled = disabled || getLocalesListState.isFetching;

  return (
    <div className={cn(cx())}>
      <Kit.ErrorContainer error={getLocalesListState.error}>
        <Kit.FormContainer>
          <Form
            name="form"
            className={cn(cx('form'))}
            initialValues={initialValues}
            onFinish={onFinish}
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
          >
            <Form.Item
              name="name"
              validateStatus={formError.status('name')}
              help={formError.note('name')}
              label="Name"
              labelAlign={'left'}
            >
              <Input size={'middle'} disabled={aggDisabled} />
            </Form.Item>

            <Form.Item
              name="slug"
              validateStatus={formError.status('slug')}
              label="Slug"
              labelAlign={'left'}
            >
              <Input
                size={'middle'}
                disabled={aggDisabled}
                readOnly={!defOptions.canChangeSlug}
              />
            </Form.Item>

            <Form.Item
              name={'description'}
              validateStatus={formError.status('description')}
              label="Description"
              labelAlign={'left'}
            >
              <Input.TextArea disabled={aggDisabled} />
            </Form.Item>

            {action === 'create' && (
              <>
                <Divider plain>Create the main version</Divider>

                <Form.Item label="Version name" labelAlign={'left'}>
                  <Input
                    size={'middle'}
                    readOnly={true}
                    value="Feature version"
                  />
                </Form.Item>

                <Form.Item label="Version param" labelAlign={'left'}>
                  <Input size={'middle'} readOnly={true} value="feature" />
                </Form.Item>

                <Form.Item
                  name={'localeIds'}
                  label="Languages"
                  labelAlign={'left'}
                >
                  <Select
                    mode="multiple"
                    optionFilterProp="title"
                    className={'ant-select-block-options'}
                  >
                    {map(getLocalesListState.data?.list, (locale) => (
                      <Select.Option
                        key={locale.id}
                        value={locale.id}
                        title={locale.name}
                      >
                        {locale.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Divider />
              </>
            )}

            <Form.Item
              validateStatus={formError.status()}
              help={formError.note() && formError.emptyDetails()}
              wrapperCol={{ offset: 12, span: 12 }}
            >
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size={'large'}
                disabled={aggDisabled}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </Kit.FormContainer>
      </Kit.ErrorContainer>
    </div>
  );
};
