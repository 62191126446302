import React from 'react';

import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { Kit } from 'ui/Kit';

interface Props {}

const cx = createCn('HomePage');

export const HomePage: React.FC = () => {
  return (
    <div className={cn(cx())}>
      <Kit.Title>Home Page</Kit.Title>

      <div>Hello!</div>
    </div>
  );
};
