import { useState } from 'react';

import { isEqual } from 'lodash-es';
import { OptionalKeys, Filter } from 'types';

export const useFilter = <T>(
  initial: Filter<T>,
): {
  initial: Filter<T>;
  values: Filter<T>;
  asset: (filter: OptionalKeys<T>) => Filter<T>;
  set: (filter: Filter<T>) => Filter<T>;
  reset: () => Filter<T>;
  isInitial: boolean;
} => {
  const [values, setValues] = useState<Filter<T>>(initial);

  const set = (values: Filter<T>) => {
    setValues(values);
    return values;
  };

  const reset = () => {
    return set(initial);
  };

  const asset = (newValues: OptionalKeys<T>): Filter<T> => {
    const mergedFilter: Filter<T> = {
      ...values,
      ...newValues,
    };

    return set(mergedFilter);
  };

  const isInitial = isEqual(initial, values);

  return { initial, values, asset, set, reset, isInitial };
};
