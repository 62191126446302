import React from 'react';

import { core } from 'core';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AddUserPage } from './pages/AddUserPage';
import { EditUserPage } from './pages/EditUserPage';
import { UserInfoPage } from './pages/UserInfoPage';
import { UsersListPage } from './pages/UsersListPage';

export const UsersModule = () => {
  return (
    <Switch>
      <Route path={core.toPage('/list')} exact>
        <UsersListPage />
      </Route>
      <Route path={core.toPage('/add')} exact>
        <AddUserPage />
      </Route>
      <Route path={core.toPage('/edit/:id(\\d+)')} exact>
        <EditUserPage />
      </Route>
      <Route path={core.toPage('/info/:id(\\d+)')} exact>
        <UserInfoPage />
      </Route>
      <Redirect to="/404" />
    </Switch>
  );
};
