import React, { useCallback } from 'react';

import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { core } from 'core';
import { useFormError } from 'hooks';
import { toInteger } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useLifecycles } from 'react-use';
import { server, AppThunk } from 'store';
import { Kit } from 'ui/Kit';
import { toasts } from 'ui/toasts';

import { ProjectInfo } from '../../shared/ProjectInfo';
import { ProjectVersionForm } from '../../shared/ProjectVersionForm';
import { ProjectsMenu } from '../../shared/ProjectsMenu';

import './AddProjectVersionPage.scss';

const cx = createCn('AddProjectVersionPage');

interface Props {}

export const AddProjectVersionPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const params = useParams<{ projectId: string }>();
  const projectId = toInteger(params.projectId);

  const refresh = useCallback(async () => {
    dispatch(
      server.getProjectById.thunk.request({
        params: { id: projectId },
      }),
    );
  }, [dispatch, projectId]);

  useLifecycles(
    () => {
      refresh();
    },
    () => {
      dispatch(server.getProjectById.action.reset());
    },
  );

  const getProjectByIdState = useSelector(server.getProjectById.selector.state);

  const createProjectVersionState = useSelector(
    server.createProjectVersion.selector.state,
  );
  const formError = useFormError(createProjectVersionState.error);

  const onFinish = useCallback(
    (values) => {
      dispatch(
        ((): AppThunk => async (dispatch, getState) => {
          await dispatch(
            server.createProjectVersion.thunk.request({
              body: values,
            }),
          );
          const { error, data } = server.createProjectVersion.selector.state(
            getState(),
          );

          toasts.result({
            title: 'Save projectVersion',
            error,
          });

          if (!error) {
            history.push(
              core.toPage(`/manage/${projectId}/versions/edit/${data?.id}`),
            );
          }
        })(),
      );
    },
    [dispatch, history, projectId],
  );

  return (
    <div className={cn(cx())}>
      <ProjectsMenu />
      <Kit.Title>Create project version</Kit.Title>

      <div className={cx('info')}>
        <ProjectInfo getProjectByIdState={getProjectByIdState} isInfo={false} />
      </div>

      {getProjectByIdState.data && (
        <ProjectVersionForm
          onFinish={onFinish}
          formError={formError}
          disabled={createProjectVersionState.isFetching}
          action={'create'}
          initialValues={{
            name: '',
            param: '',
            localeIds: getProjectByIdState.data.locales.map(
              (locale) => locale.id,
            ),
            projectId,
          }}
        />
      )}
    </div>
  );
};
