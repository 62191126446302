import React, { useCallback, useEffect, useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Modal, Form, Switch, Upload, Button } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { useFormError } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from 'react-use';
import { ServerState, server, AppThunk } from 'store';
import { KitFormCheckList } from 'ui/Kit/KitFormCheckList/KitFormCheckList';
import { ProjectVersionColor } from 'ui/ProjectVersionColor';
import { toasts } from 'ui/toasts';
import { formDataFromObject } from 'utils';

import './ImportMessagesFormModal.scss';

const cx = createCn('ImportMessagesFormModal');

interface Props {
  projectId: number;
  ico: string;
  visible: boolean;
  getProjectByIdState: ServerState['getProjectById'];
  close: () => void;
  success: () => void;
}

export const ImportMessagesFormModal: React.FC<Props> = ({
  projectId,
  ico,
  getProjectByIdState,
  close,
  visible,
  success,
}) => {
  const dispatch = useDispatch();
  const title = 'Import of messages';
  const [form] = Form.useForm();
  const { width } = useWindowSize();
  const [documentFile, setDocumentFile] = useState<RcFile | null>(null);

  const versions = getProjectByIdState.data?.versions ?? [];

  const initialValues = {
    projectVersionIds: versions.map((version) => version.id),
    rewriteValues: true,
    checkOriginal: true,
  };

  useEffect(() => {
    if (!visible) {
      return () => {
        dispatch(server.importMessages.action.reset());
        setDocumentFile(null);
        form.resetFields();
      };
    }
  }, [dispatch, form, visible]);

  const onFinish = useCallback(
    (values) => {
      dispatch(
        ((): AppThunk => async (dispatch, getState) => {
          await dispatch(
            server.importMessages.thunk.request({
              params: { projectId, ico },
              body: formDataFromObject({
                ...values,
                file: documentFile,
              }),
              config: {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              },
            } as any),
          );
          const { error } = server.importMessages.selector.state(getState());

          toasts.result({
            title,
            error,
          });

          if (!error) {
            success();
            close();
          }
        })(),
      );
    },
    [close, dispatch, documentFile, ico, projectId, success],
  );

  const importMessages = useSelector(server.importMessages.selector.state);
  const aggFetching = importMessages.isFetching;

  const formError = useFormError({});

  return (
    <Modal
      visible={visible}
      onCancel={close}
      onOk={() => {
        form.submit();
      }}
      title={title}
      confirmLoading={aggFetching}
      centered
      className={cx()}
      width={Math.min(width - 48, 1200)}
    >
      <Form
        form={form}
        className={cn(cx('form'))}
        initialValues={initialValues}
        onFinish={onFinish}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          validateStatus={formError.status('projectVersionIds')}
          help={formError.note('projectVersionIds')}
          label="XLSX document"
          labelAlign={'left'}
        >
          <Form.Item name="file" label="">
            <Upload
              name="file"
              listType="picture"
              className="uploader"
              showUploadList={false}
              customRequest={() => {}}
              beforeUpload={(file: RcFile) => {
                setDocumentFile(file);
                return true;
              }}
              fileList={[]}
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          <div className={cx('uploadFilename')}>{documentFile?.name ?? ''}</div>
        </Form.Item>

        <Form.Item
          validateStatus={formError.status('projectVersionIds')}
          help={formError.note('projectVersionIds')}
          label="Project versions"
          labelAlign={'left'}
        >
          <KitFormCheckList
            name="projectVersionIds"
            initialValues={initialValues.projectVersionIds}
            list={versions.map((version) => ({
              value: version.id,
              label: (
                <div className={cx('projectVersionList')}>
                  <ProjectVersionColor param={version.param} small />
                  <div className={cx('projectVersionListLabel')}>
                    {version.name}
                  </div>
                </div>
              ),
            }))}
            checkAll={(values: number[]) => {
              form.setFieldsValue({ projectVersionIds: values });
            }}
          />
        </Form.Item>

        <Form.Item
          name="rewriteValues"
          validateStatus={formError.status('rewriteValues')}
          help={formError.note('rewriteValues')}
          label="Rewrite not empty values"
          labelAlign={'left'}
          valuePropName="checked"
        >
          <Switch disabled={aggFetching} />
        </Form.Item>

        <Form.Item
          name="checkOriginal"
          validateStatus={formError.status('checkOriginal')}
          help={formError.note('checkOriginal')}
          label="Verify document"
          tooltip="The field structure shouldn't be different from the original document"
          labelAlign={'left'}
          valuePropName="checked"
        >
          <Switch disabled={aggFetching} />
        </Form.Item>

        <Form.Item
          validateStatus={formError.status()}
          help={formError.note() && formError.emptyDetails()}
          wrapperCol={{ offset: 12, span: 12 }}
        ></Form.Item>
      </Form>
    </Modal>
  );
};
