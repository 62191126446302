import React from 'react';

import { Alert } from 'antd';
import { get } from 'lodash-es';

import { KitModuleLoader } from '../KitModuleLoader';

interface Props {
  children: React.ReactNode;
  isFetching?: boolean;
  isFetched?: boolean;
  error: any;
}

export const KitLoadContainer: React.FC<Props> = ({
  children,
  isFetching = false,
  isFetched = false,
  error,
}) => {
  const errorText = get(error, 'data.message') || get(error, 'statusText');

  return (
    <>
      {isFetching && !isFetched && <KitModuleLoader />}

      {isFetched && children}

      {error && <Alert message={errorText} type="error" />}
    </>
  );
};
