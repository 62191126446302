import React from 'react';

import { core } from 'core';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ActionsListPage } from './pages/ActionsListPage';

export const ActionsModule = () => {
  return (
    <Switch>
      <Route path={core.toPage('/list')} exact>
        <ActionsListPage />
      </Route>
      <Redirect to="/404" />
    </Switch>
  );
};
