import React from 'react';

import { Spin } from 'antd';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';

import './KitModuleLoader.scss';

interface Props {}

const cx = createCn('KitModuleLoader');

export const KitModuleLoader: React.FC<Props> = () => {
  return (
    <div className={cn(cx())}>
      <div className={cn(cx('block1'))}>
        <Spin size="large" />
      </div>
      <div className={cn(cx('block2'))}>
        <Spin size="large" />
      </div>
      <div className={cn(cx('block3'))}>
        <Spin size="large" />
      </div>
      <div className={cn(cx('block4'))}>
        <Spin size="large" />
      </div>
      <div className={cn(cx('block5'))}>
        <Spin size="large" />
      </div>
    </div>
  );
};
