import React from 'react';

import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import './KitNavItem.scss';

interface Props {
  className?: string;
  to: string;
  disabled?: boolean;
  icon: FontAwesomeIconProps['icon'];
  text: string;
  exact?: boolean;
}

const cx = createCn('KitNavItem');

export const KitNavItem: React.FC<Props> = ({ className, to, icon, text }) => {
  return (
    <li className={cn(className, cx())}>
      <NavLink role="tab" className={cx('link')} to={to} exact>
        <div className={cx('icon')}>
          <FontAwesomeIcon icon={icon} />
        </div>
        <div className={cx('text')}>{text}</div>
      </NavLink>
    </li>
  );
};
