import React, { useCallback, useEffect } from 'react';

import { Modal, Form } from 'antd';
import { useFormError } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from 'react-use';
import { server, AppThunk } from 'store';
import { Kit } from 'ui/Kit';
import { toasts } from 'ui/toasts';

import { ProjectVersionForm } from '../ProjectVersionForm';

interface Props {
  projectId: number;
  projectVersionId: number;
  close: () => void;
}

export const EditProjectVersionFormModal: React.FC<Props> = ({
  projectId,
  close,
  projectVersionId,
}) => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const [form] = Form.useForm();

  const title = 'Edit project version';

  useEffect(() => {
    if (projectVersionId) {
      dispatch(
        server.getProjectVersionById.thunk.request({
          params: { id: projectVersionId },
        }),
      );
    }
    return () => {
      dispatch(server.getProjectVersionById.action.reset());
      dispatch(server.updateProjectVersionById.action.reset());
    };
  }, [dispatch, form, projectVersionId]);

  const getProjectVersionByIdState = useSelector(
    server.getProjectVersionById.selector.state,
  );

  const updateProjectVersionByIdState = useSelector(
    server.updateProjectVersionById.selector.state,
  );
  const formError = useFormError(updateProjectVersionByIdState.error);

  const onFinish = useCallback(
    (values) => {
      dispatch(
        ((): AppThunk => async (dispatch, getState) => {
          await dispatch(
            server.updateProjectVersionById.thunk.request({
              params: { id: projectVersionId },
              body: { projectId, ...values },
            }),
          );
          const { error } = server.updateProjectVersionById.selector.state(
            getState(),
          );

          toasts.result({
            title,
            error,
          });

          if (!error) {
            close();
          }
        })(),
      );
    },
    [close, dispatch, projectId, projectVersionId],
  );

  const aggFetching =
    updateProjectVersionByIdState.isFetching ||
    getProjectVersionByIdState.isFetching;

  const onOk = () => {
    form.submit();
  };

  const { data } = getProjectVersionByIdState;

  return (
    <Modal
      visible={true}
      onCancel={close}
      onOk={onOk}
      title={title}
      confirmLoading={aggFetching}
      centered
      width={Math.min(width - 48, 1200)}
    >
      <Kit.ErrorContainer
        error={getProjectVersionByIdState.error}
        isFetched={getProjectVersionByIdState.isFetched}
      >
        <ProjectVersionForm
          form={form}
          onFinish={onFinish}
          formError={formError}
          disabled={aggFetching}
          initialValues={{
            name: data?.version.name || '',
            param: data?.version.param || '',
            localeIds: data?.locales.map((locale) => locale.id) || [],
            projectId,
          }}
          id={projectVersionId}
          options={{
            canChangeParam: getProjectVersionByIdState.data?.canChangeParam,
          }}
        />
      </Kit.ErrorContainer>
    </Modal>
  );
};
