import React, { useCallback, useEffect } from 'react';

import { Modal, Form } from 'antd';
import { useFormError } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from 'react-use';
import { server, AppThunk } from 'store';
import { toasts } from 'ui/toasts';

import { ProjectVersionForm } from '../ProjectVersionForm';

interface Props {
  projectId: number;
  close: () => void;
  localeIds: number[];
}

export const AddProjectVersionFormModal: React.FC<Props> = ({
  projectId,
  close,
  localeIds,
}) => {
  const dispatch = useDispatch();
  const title = 'Create project version';
  const [form] = Form.useForm();

  useEffect(() => {
    return () => {
      dispatch(server.createProjectVersion.action.reset());
    };
  }, [dispatch, form]);

  const createProjectVersionState = useSelector(
    server.createProjectVersion.selector.state,
  );
  const formError = useFormError(createProjectVersionState.error);

  const onFinish = useCallback(
    (values) => {
      dispatch(
        ((): AppThunk => async (dispatch, getState) => {
          await dispatch(
            server.createProjectVersion.thunk.request({
              body: { projectId, ...values },
            }),
          );
          const { error } = server.createProjectVersion.selector.state(
            getState(),
          );

          toasts.result({
            title,
            error,
          });

          if (!error) {
            close();
          }
        })(),
      );
    },
    [close, dispatch, projectId],
  );

  const aggFetching = createProjectVersionState.isFetching;

  const onOk = () => {
    form.submit();
  };

  const { width } = useWindowSize();

  return (
    <Modal
      visible={true}
      onCancel={close}
      onOk={onOk}
      title={title}
      confirmLoading={aggFetching}
      centered
      width={Math.min(width - 48, 1200)}
    >
      <ProjectVersionForm
        form={form}
        onFinish={onFinish}
        formError={formError}
        disabled={aggFetching}
        initialValues={{
          name: '',
          param: '',
          localeIds,
          projectId,
        }}
      />
    </Modal>
  );
};
