import React, { useCallback, useEffect, useMemo } from 'react';

import { Modal, Form, Switch, Select, Input } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { ExportMessagesResultEnum, serverApi } from 'api/server';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { useFilter, useFormError } from 'hooks';
import fileDownload from 'js-file-download';
import { get, isEqual } from 'lodash-es';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious, useWindowSize } from 'react-use';
import { ServerState, server, AppThunk } from 'store';
import { KitFormCheckList } from 'ui/Kit/KitFormCheckList/KitFormCheckList';
import { ProjectVersionColor } from 'ui/ProjectVersionColor';
import { toasts } from 'ui/toasts';

import { toTagsOptions } from '../ManageMessagesPageUtils';

import './ExportMessagesFormModal.scss';

const cx = createCn('ExportMessagesFormModal');

interface Props {
  projectId: number;
  ico: string;
  visible: boolean;
  getProjectByIdState: ServerState['getProjectById'];
  close: () => void;
  success: () => void;
  initialFilter: {
    tags?: string[];
    search?: string;
    selectEmpty?: boolean;
    selectActive?: boolean;
  };
  tags: string[];
}

export const ExportMessagesFormModal: React.FC<Props> = ({
  projectId,
  ico,
  getProjectByIdState,
  close,
  visible,
  success,
  initialFilter,
  tags,
}) => {
  const dispatch = useDispatch();
  const title = 'Export of messages';
  const [form] = Form.useForm();
  const { width } = useWindowSize();

  const filter = useFilter<Props['initialFilter']>(initialFilter);
  const prevInitialFilter = usePrevious(initialFilter);

  const versions = getProjectByIdState.data?.versions ?? [];

  const initialValues = {
    projectVersionIds: versions.map((version) => version.id),
    groupVersions: true,
    ...initialFilter,
  };

  const tagsOptions = useMemo(() => toTagsOptions(tags, filter.values.tags), [
    tags,
    filter.values.tags,
  ]);

  useEffect(() => {
    if (!isEqual(initialFilter, prevInitialFilter)) {
      form.setFieldsValue({ ...initialFilter });
    }
  }, [form, initialFilter, prevInitialFilter]);

  useEffect(() => {
    if (!visible) {
      return () => {
        dispatch(server.exportMessages.action.reset());
      };
    }
  }, [dispatch, visible]);

  const onChangeTags = useCallback(
    (value: SelectValue, option: any) => {
      const tags = (value || undefined) as string[] | undefined;
      filter.asset({ tags });
    },
    [filter],
  );

  const onFinish = useCallback(
    (values) => {
      dispatch(
        ((): AppThunk => async (dispatch, getState) => {
          try {
            await dispatch(
              server.exportMessages.thunk.request({
                params: { projectId, ico },
                query: values,
              }),
            );
            const { error, data } = server.exportMessages.selector.state(
              getState(),
            );

            if (error) {
              throw new Error(get(error, 'data.message', 'Error'));
            }

            if (data?.result === ExportMessagesResultEnum.Empty) {
              toasts.info({
                title: 'Info',
                message: 'No messages found by the specified criteria',
              });
            } else {
              const content = (await serverApi
                .request({
                  url: data?.fileSrc,
                  responseType: 'blob',
                })
                .catch((error) => {
                  throw new Error(
                    `Downloading error: ` + (error?.statusText ?? 'unknown'),
                  );
                })) as Blob;

              fileDownload(
                content,
                `messages_${
                  getProjectByIdState.data?.project.slug
                }_${moment().format('YYYY-MM-DD_HH-mm-ss')}.xlsx`,
              );

              success();
              close();

              toasts.result({
                title,
                error,
              });
            }
          } catch (error) {
            toasts.failure({
              title,
              message: error.message,
            });
          }
        })(),
      );
    },
    [close, dispatch, getProjectByIdState.data, ico, projectId, success],
  );

  const exportMessages = useSelector(server.exportMessages.selector.state);
  const aggFetching = exportMessages.isFetching;

  const formError = useFormError({});

  return (
    <Modal
      visible={visible}
      onCancel={close}
      onOk={() => {
        form.submit();
      }}
      title={title}
      confirmLoading={aggFetching}
      centered
      className={cx()}
      width={Math.min(width - 48, 1200)}
    >
      <Form
        form={form}
        className={cn(cx('form'))}
        initialValues={initialValues}
        onFinish={onFinish}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          validateStatus={formError.status('projectVersionIds')}
          help={formError.note('projectVersionIds')}
          label="Project versions"
          labelAlign={'left'}
        >
          <KitFormCheckList
            name="projectVersionIds"
            initialValues={initialValues.projectVersionIds}
            list={versions.map((version) => ({
              value: version.id,
              label: (
                <div className={cx('projectVersionList')}>
                  <ProjectVersionColor param={version.param} small />
                  <div className={cx('projectVersionListLabel')}>
                    {version.name}
                  </div>
                </div>
              ),
            }))}
            checkAll={(values: number[]) => {
              form.setFieldsValue({ projectVersionIds: values });
            }}
          />
        </Form.Item>

        <Form.Item
          name="search"
          validateStatus={formError.status('search')}
          help={formError.note('search')}
          label="Search"
          labelAlign={'left'}
        >
          <Input placeholder="Search query" allowClear />
        </Form.Item>

        <Form.Item
          name="tags"
          validateStatus={formError.status('tags')}
          help={formError.note('tags')}
          label="Tags"
          labelAlign={'left'}
        >
          <Select
            mode="multiple"
            placeholder="Tags"
            onChange={onChangeTags}
            options={tagsOptions}
            value={filter.values.tags}
          />
        </Form.Item>

        <Form.Item
          name="groupVersions"
          validateStatus={formError.status('groupVersions')}
          help={formError.note('groupVersions')}
          label="Group versions"
          labelAlign={'left'}
          valuePropName="checked"
        >
          <Switch disabled={aggFetching} />
        </Form.Item>

        <Form.Item
          name="selectEmpty"
          validateStatus={formError.status('selectEmpty')}
          help={formError.note('selectEmpty')}
          label="Only empty"
          labelAlign={'left'}
          valuePropName="checked"
        >
          <Switch disabled={aggFetching} />
        </Form.Item>

        <Form.Item
          name="selectActive"
          validateStatus={formError.status('selectActive')}
          help={formError.note('selectActive')}
          label="Only active"
          labelAlign={'left'}
          valuePropName="checked"
        >
          <Switch disabled={aggFetching} />
        </Form.Item>

        <Form.Item
          validateStatus={formError.status()}
          help={formError.note() && formError.emptyDetails()}
          wrapperCol={{ offset: 12, span: 12 }}
        ></Form.Item>
      </Form>
    </Modal>
  );
};
