import React from 'react';

import { createCn } from 'bem-react-classname';
import cn from 'classnames';

import './KitFilterGrid.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const cx = createCn('KitFilterGrid');

export const KitFilterGrid: React.FC<Props> = ({ children, className }) => {
  return (
    <div className={cn(className, cx())}>
      <>{children}</>
    </div>
  );
};
