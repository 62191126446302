import React, { useCallback, useEffect, useState } from 'react';

import { ReloadOutlined, ClearOutlined } from '@ant-design/icons';
import { Table, Button, Input, Switch, Select } from 'antd';
import {
  SearchMessagesFieldEnum,
  SearchMessagesQuery,
  OrderByEnum,
} from 'api/server';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { core } from 'core';
import {
  useFilter,
  useNav,
  useUiMemory,
  useStateDebounce,
  useSort,
} from 'hooks';
import { isEqual, map } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useLifecycles } from 'react-use';
import { server, ServerState } from 'store';
import { Kit } from 'ui/Kit';
import { ProjectLocale } from 'ui/ProjectLocale';
import { ProjectVersionColor } from 'ui/ProjectVersionColor';
import { tablePaginationConfig } from 'utils';

import { EditMessagesFormModal } from '../../shared/EditMessagesFormModal';
import { MessageDefaultKey } from '../../shared/MessageDefaultKey';
import { ProjectsMenu } from '../../shared/ProjectsMenu';

import './ProjectMessagesPage.scss';

const cx = createCn('ProjectMessagesPage');

type Row = NonNullable<ServerState['searchMessages']['data']>['list'][number];

const initialEditMessage = {
  visible: false,
  projectId: 0,
  ico: '',
  editKey: '',
};

const initialFilter = {
  search: '',
  localeId: undefined,
  selectActive: false,
};

export const ProjectMessagesPage = () => {
  const dispatch = useDispatch();
  const [isSearched, setIsSearched] = useState<boolean>(false);

  const [editMessage, setEditMessage] = useState<{
    visible: boolean;
    projectId: number;
    ico: string;
    editKey: string;
  }>(initialEditMessage);

  const filter = useFilter<SearchMessagesQuery>(initialFilter);

  useEffect(() => {
    if (!isEqual(filter.values, initialFilter)) {
      setIsSearched(true);
    }
  }, [filter.values]);

  const [searchMessagesDebounce, searchMessagesClear] = useStateDebounce();

  const searchMessages = useCallback(
    async (query: SearchMessagesQuery, delay?: number) => {
      searchMessagesDebounce(() => {
        dispatch(
          server.searchMessages.thunk.request({
            query,
          }),
        );
      }, delay);
    },
    [dispatch, searchMessagesDebounce],
  );

  const searchMessagesState = useSelector(server.searchMessages.selector.state);

  const [memoryLimit, setMemoryLimit] = useUiMemory(
    'table.projects_list.nav.limit',
    tablePaginationConfig.defaultPageSize,
  );

  const nav = useNav(searchMessagesState.data?.nav, {
    limit: memoryLimit,
  });

  const sorting = useSort<SearchMessagesFieldEnum>(
    searchMessagesState.data?.sort,
    {
      field: SearchMessagesFieldEnum.Key,
      order: OrderByEnum.Asc,
    },
  );

  const onChangeSearch = useCallback(
    (event: any) => {
      const search = event.target.value.substr(0, 64);

      searchMessages(
        {
          ...filter.asset({ search }),
          ...nav.initialValues,
          ...sorting.initialValues,
        },
        800,
      );
    },
    [filter, searchMessages, nav.initialValues, sorting.initialValues],
  );

  const onChangeLocale = useCallback(
    (localeId: number) => {
      searchMessages(
        {
          ...filter.asset({ localeId }),
          ...nav.initialValues,
          ...sorting.initialValues,
        },
        0,
      );
    },
    [filter, searchMessages, nav.initialValues, sorting.initialValues],
  );

  useLifecycles(
    () => {
      // searchMessages({ ...nav.initialValues, ...sorting.initialValues });

      dispatch(server.getUsingLocalesList.thunk.request());
    },
    () => {
      searchMessagesClear();
      dispatch(server.searchMessages.action.reset());
      dispatch(server.getUsingLocalesList.action.reset());
    },
  );

  const getUsingLocalesListState = useSelector(
    server.getUsingLocalesList.selector.state,
  );

  const aggFetching =
    searchMessagesState.isFetching || getUsingLocalesListState.isFetching;

  const columns = [
    {
      title: 'Project / Version',
      key: 'project',
      width: 120,
      render: (row: Row) => (
        <>
          <div className={cx('project')}>
            <NavLink
              exact={true}
              activeClassName="active"
              to={core.toPage(`/info/${row.project.id}`)}
              key={row.project.id}
            >
              {row.project.name}
            </NavLink>
          </div>
          <div className={cx('version')}>
            <NavLink
              exact={true}
              activeClassName="active"
              to={core.toPage(
                `/manage/${row.project.id}/versions/info/${row.projectVersion.id}`,
              )}
              key={row.projectVersion.id}
            >
              <ProjectVersionColor
                key={row.projectVersion.param}
                param={row.projectVersion.param}
                title={row.projectVersion.param}
                small
              />
            </NavLink>
          </div>
        </>
      ),
    },

    {
      key: 'key',
      title: 'Key / Default message',
      render: (row: Row) => (
        <div>
          <div className={cx('key')}>
            <MessageDefaultKey value={row.defaultMessage.key} />
          </div>
          <div className={cx('defaultValue')}>{row.defaultMessage.value}</div>
        </div>
      ),
      width: 200,
      sorter: true,
      sortOrder: sorting.toAntOrder(SearchMessagesFieldEnum.Key),
    },

    // {
    //   key: 'defaultValue',
    //   title: 'Default message',
    //   width: 180,
    //   render: (row: Row) => (
    //     <div className={cx('defaultValue')}>{row.defaultMessage.value}</div>
    //   ),
    // },

    {
      title: 'Locales / Translated messages',
      key: 'languages',
      width: 480,
      render: (row: Row) => (
        <div className={cx('messages')}>
          {map(row.details, (detail) => (
            <div className={cx('message')} key={detail.locale.ico}>
              <div className={cx('locale')}>
                <NavLink
                  exact={true}
                  activeClassName="active"
                  to={core.toPage(
                    `/manage/${row.project.id}/messages/${detail.locale.ico}`,
                  )}
                  key={detail.locale.id}
                >
                  <ProjectLocale
                    ico={detail.locale.ico}
                    name={detail.locale.enName}
                    small
                  />
                </NavLink>
              </div>
              <div className={cx('value')}>
                <div
                  className={cx('valueOverflow')}
                  title={detail.localeMessage.value}
                >
                  {detail.localeMessage.value}
                </div>
              </div>
              <div className={cx('actionCol')}>
                <Kit.Actions className={cx('actions')}>
                  <Kit.Action
                    title={'Edit version messages'}
                    type={'edit'}
                    disabled={aggFetching}
                    onClick={() => {
                      setEditMessage({
                        visible: true,
                        projectId: row.project.id,
                        ico: detail.locale.ico,
                        editKey: row.defaultMessage.key,
                      });
                    }}
                  />
                </Kit.Actions>
              </div>
            </div>
          ))}
        </div>
      ),
    },
  ];

  return (
    <div className={cn(cx())}>
      <ProjectsMenu />
      <Kit.TitleActions title={`All projects / Managing messages`} />
      <Kit.FilterGrid>
        <Kit.FilterCol>
          <Input
            placeholder="Search query"
            allowClear
            onChange={onChangeSearch}
            readOnly={searchMessagesState.isFetching}
            value={filter.values.search}
          />
        </Kit.FilterCol>

        <Kit.FilterCol>
          <Select
            optionFilterProp="Locale"
            className={"ant-select-block-options'"}
            allowClear
            onChange={onChangeLocale}
            showSearch
            filterOption={(input, option) => {
              // console.log(
              //   input,
              //   option?.props.children
              //     .toLowerCase()
              //     .includes(input.toLowerCase()),
              // );
              // return true;
              return option?.children
                .toLowerCase()
                .includes(input.toLowerCase());
              //   option?.props.children
              //   .toLowerCase()
              //   .indexOf(input.toLowerCase()) >= 0 ||
              // option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >=
              //   0
            }}
          >
            {map(getUsingLocalesListState.data?.list, (locale) => (
              <Select.Option
                key={locale.id}
                value={locale.id}
                title={locale.name}
              >
                {locale.name}
              </Select.Option>
            ))}
          </Select>
        </Kit.FilterCol>

        <Kit.FilterCol switcher>
          <Switch
            onChange={(selectActive) => {
              searchMessages({
                ...filter.asset({ selectActive }),
                ...nav.initialValues,
                ...sorting.initialValues,
              });
            }}
            disabled={searchMessagesState.isFetching}
            checked={filter.values.selectActive}
          />
          <div>Select active</div>
        </Kit.FilterCol>

        <Kit.FilterCol handler>
          <Button
            type="default"
            onClick={() => {
              searchMessages({
                ...filter.reset(),
                ...nav.initialValues,
                ...sorting.initialValues,
              });
            }}
            className={cn('ant-reset-button')}
            icon={<ClearOutlined />}
            disabled={searchMessagesState.isFetching || filter.isInitial}
          >
            <span>Reset</span>
          </Button>{' '}
          <Button
            icon={<ReloadOutlined />}
            onClick={() =>
              searchMessages({
                ...filter.values,
                ...nav.values,
                ...sorting.values,
              })
            }
          >
            Refresh
          </Button>
        </Kit.FilterCol>
      </Kit.FilterGrid>
      <Kit.ErrorContainer error={searchMessagesState.error}>
        {isSearched ? (
          <Table
            dataSource={searchMessagesState.data?.list ?? []}
            columns={columns}
            rowKey={(row: Row) =>
              row.projectVersion.id + ' ' + row.defaultMessage.key
            }
            onChange={(pagination, filters, sorter) => {
              setMemoryLimit(pagination.pageSize as number);

              searchMessages({
                ...filter.values,
                ...(sorting.isAntChanged(sorter)
                  ? nav.initialValues
                  : nav.fromAntReq(pagination)),
                ...sorting.fromAntReq(sorter),
              });
            }}
            pagination={{
              ...tablePaginationConfig,
              ...nav.toAntPagination(),
            }}
            loading={searchMessagesState.isFetching}
            scroll={{ x: '100%' }}
          />
        ) : (
          <div className={cx('searchPlaceHolder')}>
            Please enter a search query to display results
          </div>
        )}
      </Kit.ErrorContainer>

      <EditMessagesFormModal
        {...editMessage}
        close={() => {
          setEditMessage(initialEditMessage);
        }}
        success={() => {
          searchMessages({
            ...filter.values,
            ...nav.values,
            ...sorting.values,
          });
        }}
      />
    </div>
  );
};
