import React, { useCallback } from 'react';

import { Spin } from 'antd';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { useFormError } from 'hooks';
import { toInteger } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useLifecycles } from 'react-use';
import { server, AppThunk, app } from 'store';
import { Kit } from 'ui/Kit';
import { toasts } from 'ui/toasts';

import { UserForm } from '../../shared/UserForm';
import { UsersMenu } from '../../shared/UsersMenu';

import './EditUserPage.scss';

const cx = createCn('EditUserPage');

interface Props {}

export const EditUserPage: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const id = toInteger(params.id);

  const refresh = useCallback(async () => {
    dispatch(
      server.getUserById.thunk.request({
        params: { id },
      }),
    );
  }, [dispatch, id]);

  useLifecycles(
    () => {
      refresh();
      dispatch(server.getProjectsList.thunk.request({}));
    },
    () => {
      dispatch(server.getUserById.action.reset());
      dispatch(server.updateUser.action.reset());
      dispatch(server.getProjectsList.action.reset());
    },
  );

  const authState = useSelector(app.auth.selector.state);

  const getUserByIdState = useSelector(server.getUserById.selector.state);
  const getProjectsListState = useSelector(
    server.getProjectsList.selector.state,
  );

  const updateUserState = useSelector(server.updateUser.selector.state);
  const formError = useFormError(updateUserState.error);
  const { data } = getUserByIdState;
  const initialValues = {
    firstName: data?.user.firstName || '',
    lastName: data?.user.lastName || '',
    email: data?.user.email || '',
    password: '',
    role: data?.user.role || '',
    state: data?.user.state || '',
    projectIds: data?.projectIds || [],
    changePassword: false,
  };

  const onFinish = useCallback(
    (values) => {
      dispatch(
        ((): AppThunk => async (dispatch, getState) => {
          await dispatch(
            server.updateUser.thunk.request({
              params: { id },
              body: values,
            }),
          );
          const { error } = server.updateUser.selector.state(getState());

          toasts.result({
            title: 'Update user',
            error,
          });

          if (!error) {
            refresh();
          }
        })(),
      );
    },
    [dispatch, id, refresh],
  );

  const aggFetching = updateUserState.isFetching || getUserByIdState.isFetching;

  return (
    <div className={cn(cx())}>
      <UsersMenu />
      <Kit.Title>
        User #{id} / edit{' '}
        {aggFetching && <Spin size="small" className="ant-header-spin" />}
      </Kit.Title>
      <Kit.LoadContainer
        error={getUserByIdState.error || getProjectsListState.error}
        isFetched={getUserByIdState.isFetched && getProjectsListState.isFetched}
        isFetching={
          getUserByIdState.isFetching || getProjectsListState.isFetching
        }
      >
        <UserForm
          action="edit"
          onFinish={onFinish}
          formError={formError}
          disabled={aggFetching}
          initialValues={initialValues}
          projectsList={getProjectsListState.data?.list || []}
          role={authState.data?.user?.role}
        />
      </Kit.LoadContainer>
    </div>
  );
};
