/* eslint @typescript-eslint/no-explicit-any: ["off"] */

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from 'axios';

import {
  RefreshAuthBody,
  RefreshAuthData,
  LoginUserBody,
  LoginUserData,
  RegisterUserBody,
  RegisterUserData,
  GetSessionsData,
  DeleteSessionParams,
  DeleteSessionData,
  GetUsersQuery,
  GetUsersData,
  GetUsersListQuery,
  GetUsersListData,
  GetUserByIdParams,
  GetUserByIdData,
  CreateUserBody,
  CreateUserData,
  UpdateUserParams,
  UpdateUserBody,
  UpdateUserData,
  DeleteUserParams,
  DeleteUserData,
  GetLocalesListData,
  GetProjectsQuery,
  GetProjectsData,
  GetProjectsListData,
  GetProjectByIdParams,
  GetProjectByIdData,
  GetProjectStatsByIdParams,
  GetProjectStatsByIdData,
  CreateProjectBody,
  CreateProjectData,
  UpdateProjectByIdParams,
  UpdateProjectByIdBody,
  UpdateProjectByIdData,
  DeleteProjectByIdParams,
  DeleteProjectByIdData,
  SelectMessagesParams,
  SelectMessagesQuery,
  SelectMessagesData,
  SearchMessagesQuery,
  SearchMessagesData,
  ExportMessagesParams,
  ExportMessagesQuery,
  ExportMessagesData,
  ImportMessagesParams,
  ImportMessagesBody,
  ImportMessagesData,
  GetProjectLocaleKeyDataParams,
  GetProjectLocaleKeyDataData,
  UpdateProjectLocaleKeyDataParams,
  UpdateProjectLocaleKeyDataBody,
  UpdateProjectLocaleKeyDataData,
  GetProjectKeysParams,
  GetProjectKeysData,
  GetUsingLocalesListData,
  GetActionsQuery,
  GetActionsData,
  GetActionByIdParams,
  GetActionByIdData,
  DeleteActionByIdParams,
  DeleteActionByIdData,
  GetActionNavigationsData,
  GetProfileData,
  UpdateProfileBody,
  UpdateProfileData,
  ChangePasswordBody,
  ChangePasswordData,
  LoadProfileImageBody,
  LoadProfileImageData,
  UploadImageBody,
  UploadImageData,
  GetProjectVersionsQuery,
  GetProjectVersionsData,
  GetProjectVersionsListQuery,
  GetProjectVersionsListData,
  GetProjectVersionByIdParams,
  GetProjectVersionByIdData,
  GetProjectVersionStatsByIdParams,
  GetProjectVersionStatsByIdData,
  CreateProjectVersionBody,
  CreateProjectVersionData,
  UpdateProjectVersionByIdParams,
  UpdateProjectVersionByIdBody,
  UpdateProjectVersionByIdData,
  DeleteProjectVersionByIdParams,
  DeleteProjectVersionByIdData,
  GetMonitoringKeysUpdatesQuery,
  GetMonitoringKeysUpdatesData,
  GetMonitoringMessagesUpdatesQuery,
  GetMonitoringMessagesUpdatesData,
  GetKeysUpdatesStatsQuery,
  GetKeysUpdatesStatsData,
  GetMessagesUpdatesStatsQuery,
  GetMessagesUpdatesStatsData,
  GetProjectVersionSyncsStatsQuery,
  GetProjectVersionSyncsStatsData,
  GetActionsStatsQuery,
  GetActionsStatsData,
  GetDashboardStatsData,
} from './serverApiTypes';

export type ServerApiConfig = AxiosRequestConfig;

export interface ServerApiError {
  code?: string;
  status?: number;
  statusText: string;
  data: any;
}

type RequestData<T> = AxiosResponse<T>['data'];

export class ServerApiService {
  private readonly axiosInstance: AxiosInstance;

  constructor(config: AxiosRequestConfig = {}) {
    this.axiosInstance = axios.create(config);
  }

  public axios(): AxiosInstance {
    return this.axiosInstance;
  }

  public request<T>(options: AxiosRequestConfig): Promise<RequestData<T>> {
    return this.axiosInstance.request(options).then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(this.formatError(error)),
    );
  }

  private formatError = (error: AxiosError): ServerApiError => {
    return {
      code: error?.code,
      status: error?.response?.status,
      statusText: error?.response?.statusText || 'Unknown error',
      data: error?.response?.data || {},
    };
  };

  // Auth

  public refreshAuth = (
    body: RefreshAuthBody,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<RefreshAuthData>> =>
    this.request<RefreshAuthData>({
      method: 'post',
      url: `/api/auth/refresh`,
      data: body,
      ...config,
    });

  // Auth

  public loginUser = (
    body: LoginUserBody,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<LoginUserData>> =>
    this.request<LoginUserData>({
      method: 'post',
      url: `/api/auth/login`,
      data: body,
      ...config,
    });

  // Auth

  public registerUser = (
    body: RegisterUserBody,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<RegisterUserData>> =>
    this.request<RegisterUserData>({
      method: 'post',
      url: `/api/auth/register`,
      data: body,
      ...config,
    });

  // Account

  public getSessions = (
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetSessionsData>> =>
    this.request<GetSessionsData>({
      method: 'get',
      url: `/api/account/getSessions`,
      ...config,
    });

  // Account

  public deleteSession = (
    params: DeleteSessionParams,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<DeleteSessionData>> =>
    this.request<DeleteSessionData>({
      method: 'delete',
      url: `/api/account/deleteSession/${params.key}`,
      ...config,
    });

  // Users

  public getUsers = (
    query: GetUsersQuery,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetUsersData>> =>
    this.request<GetUsersData>({
      method: 'get',
      url: `/api/admin/users/get`,
      params: query,
      ...config,
    });

  // Users

  public getUsersList = (
    query: GetUsersListQuery,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetUsersListData>> =>
    this.request<GetUsersListData>({
      method: 'get',
      url: `/api/admin/users/getList`,
      params: query,
      ...config,
    });

  // Users

  public getUserById = (
    params: GetUserByIdParams,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetUserByIdData>> =>
    this.request<GetUserByIdData>({
      method: 'get',
      url: `/api/admin/users/get/${params.id}`,
      ...config,
    });

  // Users

  public createUser = (
    body: CreateUserBody,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<CreateUserData>> =>
    this.request<CreateUserData>({
      method: 'post',
      url: `/api/admin/users/create`,
      data: body,
      ...config,
    });

  // Users

  public updateUser = (
    params: UpdateUserParams,
    body: UpdateUserBody,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<UpdateUserData>> =>
    this.request<UpdateUserData>({
      method: 'put',
      url: `/api/admin/users/update/${params.id}`,
      data: body,
      ...config,
    });

  // Users

  public deleteUser = (
    params: DeleteUserParams,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<DeleteUserData>> =>
    this.request<DeleteUserData>({
      method: 'delete',
      url: `/api/admin/users/delete/${params.id}`,
      ...config,
    });

  // Locales

  public getLocalesList = (
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetLocalesListData>> =>
    this.request<GetLocalesListData>({
      method: 'get',
      url: `/api/admin/locales/getList`,
      ...config,
    });

  // Projects

  public getProjects = (
    query: GetProjectsQuery,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetProjectsData>> =>
    this.request<GetProjectsData>({
      method: 'get',
      url: `/api/admin/projects/get`,
      params: query,
      ...config,
    });

  // Projects

  public getProjectsList = (
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetProjectsListData>> =>
    this.request<GetProjectsListData>({
      method: 'get',
      url: `/api/admin/projects/getList`,
      ...config,
    });

  // Projects

  public getProjectById = (
    params: GetProjectByIdParams,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetProjectByIdData>> =>
    this.request<GetProjectByIdData>({
      method: 'get',
      url: `/api/admin/projects/get/${params.id}`,
      ...config,
    });

  // Projects

  public getProjectStatsById = (
    params: GetProjectStatsByIdParams,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetProjectStatsByIdData>> =>
    this.request<GetProjectStatsByIdData>({
      method: 'get',
      url: `/api/admin/projects/getStats/${params.id}`,
      ...config,
    });

  // Projects

  public createProject = (
    body: CreateProjectBody,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<CreateProjectData>> =>
    this.request<CreateProjectData>({
      method: 'post',
      url: `/api/admin/projects/create`,
      data: body,
      ...config,
    });

  // Projects

  public updateProjectById = (
    params: UpdateProjectByIdParams,
    body: UpdateProjectByIdBody,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<UpdateProjectByIdData>> =>
    this.request<UpdateProjectByIdData>({
      method: 'put',
      url: `/api/admin/projects/update/${params.id}`,
      data: body,
      ...config,
    });

  // Projects

  public deleteProjectById = (
    params: DeleteProjectByIdParams,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<DeleteProjectByIdData>> =>
    this.request<DeleteProjectByIdData>({
      method: 'delete',
      url: `/api/admin/projects/delete/${params.id}`,
      ...config,
    });

  // Messages

  public selectMessages = (
    params: SelectMessagesParams,
    query: SelectMessagesQuery,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<SelectMessagesData>> =>
    this.request<SelectMessagesData>({
      method: 'get',
      url: `/api/admin/messages/select/${params.projectId}/${params.ico}`,
      params: query,
      ...config,
    });

  // Messages

  public searchMessages = (
    query: SearchMessagesQuery,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<SearchMessagesData>> =>
    this.request<SearchMessagesData>({
      method: 'get',
      url: `/api/admin/messages/search`,
      params: query,
      ...config,
    });

  // Messages

  public exportMessages = (
    params: ExportMessagesParams,
    query: ExportMessagesQuery,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<ExportMessagesData>> =>
    this.request<ExportMessagesData>({
      method: 'get',
      url: `/api/admin/messages/export/${params.projectId}/${params.ico}`,
      params: query,
      ...config,
    });

  // Messages

  public importMessages = (
    params: ImportMessagesParams,
    body: ImportMessagesBody,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<ImportMessagesData>> =>
    this.request<ImportMessagesData>({
      method: 'post',
      url: `/api/admin/messages/import/${params.projectId}/${params.ico}`,
      data: body,
      ...config,
    });

  // Messages

  public getProjectLocaleKeyData = (
    params: GetProjectLocaleKeyDataParams,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetProjectLocaleKeyDataData>> =>
    this.request<GetProjectLocaleKeyDataData>({
      method: 'get',
      url: `/api/admin/messages/getProjectLocaleKeyData/${params.projectId}/${params.ico}/${params.key}`,
      ...config,
    });

  // Messages

  public updateProjectLocaleKeyData = (
    params: UpdateProjectLocaleKeyDataParams,
    body: UpdateProjectLocaleKeyDataBody,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<UpdateProjectLocaleKeyDataData>> =>
    this.request<UpdateProjectLocaleKeyDataData>({
      method: 'put',
      url: `/api/admin/messages/update/${params.projectId}/${params.ico}/${params.key}`,
      data: body,
      ...config,
    });

  // Messages

  public getProjectKeys = (
    params: GetProjectKeysParams,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetProjectKeysData>> =>
    this.request<GetProjectKeysData>({
      method: 'get',
      url: `/api/admin/messages/getProjectKeys/${params.projectId}`,
      ...config,
    });

  // Messages

  public getUsingLocalesList = (
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetUsingLocalesListData>> =>
    this.request<GetUsingLocalesListData>({
      method: 'get',
      url: `/api/admin/messages/getUsingLocalesList`,
      ...config,
    });

  // Actions

  public getActions = (
    query: GetActionsQuery,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetActionsData>> =>
    this.request<GetActionsData>({
      method: 'get',
      url: `/api/admin/actions/get`,
      params: query,
      ...config,
    });

  // Actions

  public getActionById = (
    params: GetActionByIdParams,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetActionByIdData>> =>
    this.request<GetActionByIdData>({
      method: 'get',
      url: `/api/admin/actions/get/${params.id}`,
      ...config,
    });

  // Actions

  public deleteActionById = (
    params: DeleteActionByIdParams,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<DeleteActionByIdData>> =>
    this.request<DeleteActionByIdData>({
      method: 'delete',
      url: `/api/admin/actions/delete/${params.id}`,
      ...config,
    });

  // Actions

  public getActionNavigations = (
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetActionNavigationsData>> =>
    this.request<GetActionNavigationsData>({
      method: 'get',
      url: `/api/admin/actions/getNavigations`,
      ...config,
    });

  // Profile

  public getProfile = (
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetProfileData>> =>
    this.request<GetProfileData>({
      method: 'get',
      url: `/api/profile/get`,
      ...config,
    });

  // Profile

  public updateProfile = (
    body: UpdateProfileBody,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<UpdateProfileData>> =>
    this.request<UpdateProfileData>({
      method: 'put',
      url: `/api/profile/update`,
      data: body,
      ...config,
    });

  // Profile

  public changePassword = (
    body: ChangePasswordBody,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<ChangePasswordData>> =>
    this.request<ChangePasswordData>({
      method: 'put',
      url: `/api/profile/changePassword`,
      data: body,
      ...config,
    });

  // Profile

  public loadProfileImage = (
    body: LoadProfileImageBody,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<LoadProfileImageData>> =>
    this.request<LoadProfileImageData>({
      method: 'post',
      url: `/api/profile/loadProfileImage`,
      data: body,
      ...config,
    });

  // Upload

  public uploadImage = (
    body: UploadImageBody,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<UploadImageData>> =>
    this.request<UploadImageData>({
      method: 'post',
      url: `/api/admin/upload/image`,
      data: body,
      ...config,
    });

  // ProjectVersions

  public getProjectVersions = (
    query: GetProjectVersionsQuery,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetProjectVersionsData>> =>
    this.request<GetProjectVersionsData>({
      method: 'get',
      url: `/api/admin/projectVersions/get`,
      params: query,
      ...config,
    });

  // ProjectVersions

  public getProjectVersionsList = (
    query: GetProjectVersionsListQuery,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetProjectVersionsListData>> =>
    this.request<GetProjectVersionsListData>({
      method: 'get',
      url: `/api/admin/projectVersions/getList`,
      params: query,
      ...config,
    });

  // ProjectVersions

  public getProjectVersionById = (
    params: GetProjectVersionByIdParams,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetProjectVersionByIdData>> =>
    this.request<GetProjectVersionByIdData>({
      method: 'get',
      url: `/api/admin/projectVersions/get/${params.id}`,
      ...config,
    });

  // ProjectVersions

  public getProjectVersionStatsById = (
    params: GetProjectVersionStatsByIdParams,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetProjectVersionStatsByIdData>> =>
    this.request<GetProjectVersionStatsByIdData>({
      method: 'get',
      url: `/api/admin/projectVersions/getStats/${params.id}`,
      ...config,
    });

  // ProjectVersions

  public createProjectVersion = (
    body: CreateProjectVersionBody,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<CreateProjectVersionData>> =>
    this.request<CreateProjectVersionData>({
      method: 'post',
      url: `/api/admin/projectVersions/create`,
      data: body,
      ...config,
    });

  // ProjectVersions

  public updateProjectVersionById = (
    params: UpdateProjectVersionByIdParams,
    body: UpdateProjectVersionByIdBody,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<UpdateProjectVersionByIdData>> =>
    this.request<UpdateProjectVersionByIdData>({
      method: 'put',
      url: `/api/admin/projectVersions/update/${params.id}`,
      data: body,
      ...config,
    });

  // ProjectVersions

  public deleteProjectVersionById = (
    params: DeleteProjectVersionByIdParams,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<DeleteProjectVersionByIdData>> =>
    this.request<DeleteProjectVersionByIdData>({
      method: 'delete',
      url: `/api/admin/projectVersions/delete/${params.id}`,
      ...config,
    });

  // MonitoringUpdates

  public getMonitoringKeysUpdates = (
    query: GetMonitoringKeysUpdatesQuery,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetMonitoringKeysUpdatesData>> =>
    this.request<GetMonitoringKeysUpdatesData>({
      method: 'get',
      url: `/api/admin/monitoring/getKeysUpdates`,
      params: query,
      ...config,
    });

  // MonitoringUpdates

  public getMonitoringMessagesUpdates = (
    query: GetMonitoringMessagesUpdatesQuery,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetMonitoringMessagesUpdatesData>> =>
    this.request<GetMonitoringMessagesUpdatesData>({
      method: 'get',
      url: `/api/admin/monitoring/getMessageUpdates`,
      params: query,
      ...config,
    });

  // Stats

  public getKeysUpdatesStats = (
    query: GetKeysUpdatesStatsQuery,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetKeysUpdatesStatsData>> =>
    this.request<GetKeysUpdatesStatsData>({
      method: 'get',
      url: `/api/admin/stats/getKeysUpdates`,
      params: query,
      ...config,
    });

  // Stats

  public getMessagesUpdatesStats = (
    query: GetMessagesUpdatesStatsQuery,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetMessagesUpdatesStatsData>> =>
    this.request<GetMessagesUpdatesStatsData>({
      method: 'get',
      url: `/api/admin/stats/getMessageUpdates`,
      params: query,
      ...config,
    });

  // Stats

  public getProjectVersionSyncsStats = (
    query: GetProjectVersionSyncsStatsQuery,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetProjectVersionSyncsStatsData>> =>
    this.request<GetProjectVersionSyncsStatsData>({
      method: 'get',
      url: `/api/admin/stats/getProjectVersionSyncs`,
      params: query,
      ...config,
    });

  // Stats

  public getActionsStats = (
    query: GetActionsStatsQuery,
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetActionsStatsData>> =>
    this.request<GetActionsStatsData>({
      method: 'get',
      url: `/api/admin/stats/getActions`,
      params: query,
      ...config,
    });

  // Stats

  public getDashboardStats = (
    config?: AxiosRequestConfig,
  ): Promise<RequestData<GetDashboardStatsData>> =>
    this.request<GetDashboardStatsData>({
      method: 'get',
      url: `/api/admin/stats/getDashboard`,
      ...config,
    });
}
