import { get } from 'lodash-es';

type Detail = { param: string; value: string };

export const formErrorUtil = (error: any, fields?: string[]): string | null => {
  const details: Detail[] = get(error, 'data.details', []);
  const message: string | null = get(error, 'data.message', null);

  if (!fields) {
    return message;
  }

  if (
    message &&
    (details.length === 0 ||
      details.some((detail) => !fields.includes(detail.param)))
  ) {
    return message;
  }

  return null;
};
