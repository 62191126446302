import React from 'react';

import { faAnchor } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import './ProjectLink.scss';

const cx = createCn('ProjectLink');

interface Props {
  className?: string;
  to: string;
}

export const ProjectLink: React.FC<Props> = ({ className, to }) => {
  return (
    <NavLink to={to} className={cn(className, cx())}>
      <FontAwesomeIcon icon={faAnchor} />
    </NavLink>
  );
};
