import { PermissionDto, UserRoleEnum } from 'api/server';
import { useSelector } from 'react-redux';
import { app } from 'store';
import { roleAccess as roleAccessUtil } from 'utils';

export const usePermissions = () => {
  const authState = useSelector(app.auth.selector.state);
  const rolePermissionsState = useSelector(app.rolePermissions.selector.state);

  const access = (requiredRole: UserRoleEnum): boolean => {
    return roleAccessUtil(requiredRole, authState.data?.user.role);
  };

  const isSuperAdmin = (): boolean => {
    return authState.isLogged && authState.data?.user.id === 1;
  };

  const has = (permission: PermissionDto['permission']): boolean => {
    return (
      (rolePermissionsState.data &&
        roleAccessUtil(
          rolePermissionsState.data[permission],
          authState.data?.user.role,
        )) ||
      false
    );
  };

  return { access, isSuperAdmin, has };
};
