import React, { useState } from 'react';

import { Form, Input, Button, Select, Checkbox } from 'antd';
import {
  GetProjectsListDataDto,
  UserRoleEnum,
  UserStateEnum,
} from 'api/server';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { useFormError } from 'hooks';
import { get, keys } from 'lodash-es';
import { server } from 'store';
import { AnyValues } from 'types';
import { Kit } from 'ui/Kit';
import { getAccessibleRoles, roleAccess } from 'utils';

import './UserForm.scss';

const cx = createCn('UserForm');

type InitialValues =
  | AnyValues<Parameters<typeof server.createUser.thunk.request>[0]['body']>
  | AnyValues<Parameters<typeof server.updateUser.thunk.request>[0]['body']>;

interface Props {
  disabled?: boolean;
  onFinish: (values: any) => void;
  formError: ReturnType<typeof useFormError>;
  initialValues: InitialValues;
  action: 'add' | 'edit';
  projectsList: GetProjectsListDataDto['list'];
  role?: UserRoleEnum;
}

export const UserForm: React.FC<Props> = ({
  disabled,
  onFinish,
  formError,
  initialValues,
  action,
  projectsList,
  role,
}) => {
  const aggDisabled = disabled;
  const [changePassword, setChangePassword] = useState<boolean>(
    action === 'add',
  );
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<InitialValues>(initialValues);
  const accessibleRoles = getAccessibleRoles(role);

  const userRoleOptions = [
    {
      label: '',
      value: '',
    },
    ...keys(UserRoleEnum)
      .filter((userRole) =>
        accessibleRoles.includes(
          UserRoleEnum[userRole as keyof typeof UserRoleEnum],
        ),
      )
      .map((userRole) => ({
        label: userRole,
        value: UserRoleEnum[userRole as keyof typeof UserRoleEnum],
      })),
  ];

  const userStateOptions = [
    {
      label: '',
      value: '',
    },
    ...keys(UserStateEnum).map((userState) => ({
      label: userState,
      value: UserStateEnum[userState as keyof typeof UserStateEnum],
    })),
  ];

  const projectOptions = [
    ...projectsList.map((item) => ({
      label: `#${item.id.toString().padEnd(4, ' ')} ${item.name}`,
      value: item.id,
    })),
  ];

  return (
    <div className={cn(cx())}>
      <Kit.ErrorContainer error={null}>
        <Kit.FormContainer>
          <Form
            form={form}
            name="form"
            className={cn(cx('form'))}
            initialValues={initialValues}
            onFinish={onFinish}
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
            onValuesChange={(values) =>
              setFormValues({ ...formValues, ...values })
            }
          >
            <Form.Item
              name="firstName"
              validateStatus={formError.status('firstName')}
              help={formError.note('firstName')}
              label="First name"
              labelAlign={'left'}
            >
              <Input size={'middle'} disabled={aggDisabled} />
            </Form.Item>

            <Form.Item
              name="lastName"
              validateStatus={formError.status('lastName')}
              help={formError.note('lastName')}
              label="Last name"
              labelAlign={'left'}
            >
              <Input size={'middle'} disabled={aggDisabled} />
            </Form.Item>

            <Form.Item
              name="role"
              label="Role"
              validateStatus={formError.status('role')}
              help={formError.note('role')}
              labelAlign={'left'}
            >
              <Select options={userRoleOptions} />
            </Form.Item>

            <Form.Item
              name="state"
              label="State"
              validateStatus={formError.status('state')}
              help={formError.note('state')}
              labelAlign={'left'}
            >
              <Select options={userStateOptions} />
            </Form.Item>

            {action === 'add' && (
              <Form.Item
                name="email"
                validateStatus={formError.status('email')}
                help={formError.note('email')}
                label="E-mail"
                labelAlign={'left'}
              >
                <Input size={'middle'} disabled={aggDisabled} />
              </Form.Item>
            )}

            {action === 'edit' && (
              <Form.Item label="E-mail" labelAlign={'left'}>
                <Input
                  size={'middle'}
                  disabled={aggDisabled}
                  value={get(initialValues, 'email')}
                  readOnly
                />
              </Form.Item>
            )}

            {action === 'edit' && (
              <Form.Item
                label="Change password"
                validateStatus={formError.status('changePassword')}
                help={formError.note('changePassword')}
                labelAlign={'left'}
              >
                <Form.Item
                  name="changePassword"
                  label=""
                  labelAlign={'left'}
                  valuePropName="checked"
                  noStyle
                >
                  <Checkbox
                    onChange={(event) => {
                      setChangePassword(event.target.checked);

                      if (!event.target.checked) {
                        form.setFieldsValue({ password: '' });
                      }
                    }}
                  ></Checkbox>
                </Form.Item>
              </Form.Item>
            )}

            <Form.Item
              name="password"
              validateStatus={formError.status('password')}
              help={formError.note('password')}
              label="Password"
              labelAlign={'left'}
            >
              <Input
                size={'middle'}
                disabled={aggDisabled || !changePassword}
              />
            </Form.Item>

            {!roleAccess(UserRoleEnum.Admin, formValues.role) && (
              <Form.Item
                name={'projectIds'}
                label="Projects access"
                labelAlign={'left'}
              >
                <Select
                  mode="multiple"
                  optionFilterProp="title"
                  className={'ant-select-block-options'}
                  options={projectOptions}
                ></Select>
              </Form.Item>
            )}

            <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size={'large'}
                disabled={aggDisabled}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </Kit.FormContainer>
      </Kit.ErrorContainer>
    </div>
  );
};
