import React from 'react';

import {
  faFileCode,
  faBars,
  faSignOutAlt,
  faUser,
  faBinoculars,
  faUsers,
  faProjectDiagram,
  faTachometerAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { usePermissions } from 'hooks';
import { compact } from 'lodash-es';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useLocalStorage } from 'react-use';
import { app } from 'store';
import { anonymizeThunk, deleteAuthSessionThunk } from 'thunks';

import logo from './logo.png';

import './MainLayout.scss';

interface Props {
  children: React.Component | React.ReactNode;
}

const cx = createCn('MainLayout');

export const MainLayout: React.FC<Props> = ({ children }) => {
  const [menuOpened = true, setMenuOpened] = useLocalStorage<boolean>(
    `ui-main-menu-open`,
    true,
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const toogleMenuOpened = () => setMenuOpened(!menuOpened);
  const authState = useSelector(app.auth.selector.state);

  const logout = async () => {
    try {
      await dispatch(
        deleteAuthSessionThunk({
          key: authState.data?.session.key ?? '',
          title: 'Выход из системы',
        }),
      );

      await dispatch(anonymizeThunk());
    } catch {}
  };

  const permissions = usePermissions();

  if (!authState.data) {
    return null;
  }

  const { user } = authState.data;

  return (
    <CSSTransition in={menuOpened} timeout={150}>
      <div className={cn(cx({ menuOpened, menuClosed: !menuOpened }))}>
        <div className={cn(cx('fadeLine', { top: true }))}></div>
        <div className={cn(cx('fadeLine', { bottom: true }))}></div>

        <div className={cn(cx('sidebar'))}>
          <div className={cn(cx('topPanel'))}>
            <div className={cn(cx('menuCol'))}>
              <button
                type="button"
                onClick={toogleMenuOpened}
                className={cn(cx('menuBtn'))}
              >
                <FontAwesomeIcon icon={faBars} />
              </button>
            </div>

            <div className={cn(cx('logoCol'))}>
              <Link to="/" className={cx('logo')}>
                <img src={logo} alt="Localization Service" />

                <div className={cx('logoText')}>
                  <div>Localization</div>
                  <div>Service</div>
                </div>
              </Link>
            </div>
          </div>

          <div className={cn(cx('profilePanel'))}>
            <button
              type="button"
              onClick={() => history.push('/profile/data')}
              className={cn(cx('profileBtn'))}
            >
              <div className={cn(cx('userIconCol'))}>
                <FontAwesomeIcon icon={faUser} className={cn(cx('userIcon'))} />
              </div>
              <div className={cn(cx('userDataCol'))}>
                <div>
                  <div className={cn(cx('userRole'))}>
                    <div className={cn(cx('userRoleLabel'))}>{user.role}</div>
                  </div>
                  <div className={cn(cx('userName'))}>
                    {user?.firstName} {user?.lastName}
                  </div>
                </div>
              </div>
            </button>
            <button
              type="button"
              onClick={logout}
              className={cn(cx('logoutBtn'))}
            >
              <Tooltip placement="right" title={'Logout'}>
                <FontAwesomeIcon icon={faSignOutAlt} />
              </Tooltip>
            </button>
          </div>

          <ul className={cn(cx('menu'))}>
            {compact([
              permissions.has('DASHBOARD_MODULE') && {
                name: 'Dashboard',
                icon: faTachometerAlt,
                link: '/dashboard',
              },
              permissions.has('MONITORING_MODULE') && {
                name: 'Monitoring updates',
                icon: faBinoculars,
                link: '/monitoring/keys',
              },
              permissions.has('PROJECTS_MODULE') && {
                name: 'Projects',
                icon: faFileCode,
                link: '/projects/list',
              },
              permissions.has('USERS_MODULE') && {
                name: 'Users',
                icon: faUsers,
                link: '/users/list',
              },
              permissions.has('ACTIONS_MODULE') && {
                name: 'Actions',
                icon: faProjectDiagram,
                link: '/actions/list',
              },
            ]).map((item) => (
              <li className={cn(cx('menuLi'))} key={item.name}>
                <NavLink
                  className={cn(cx('menuLiButton'))}
                  activeClassName={cx('menuLiButton', { active: true })}
                  to={item.link}
                >
                  <div className={cn(cx('menuLiIconCol'))}>
                    <FontAwesomeIcon
                      icon={item.icon}
                      className={cn(cx('menuLiIcon'))}
                    />
                  </div>
                  <div className={cn(cx('menuLiTextCol'))}>{item.name}</div>
                  <div className={cn(cx('menuLiExtraCol'))}></div>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <div className={cn(cx('scene'))}>{children}</div>
      </div>
    </CSSTransition>
  );
};
