import React, { useCallback, useEffect } from 'react';

import { Progress, Spin } from 'antd';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunk, server } from 'store';
import { roundPercent } from 'utils';

import './DashboardStats.scss';

const cx = createCn('DashboardStats');

interface Props {
  className?: string;
}

export const DashboardStats: React.FC<Props> = ({ className }) => {
  const dispatch = useDispatch();

  const getDashboardStatsThunk = useCallback(
    (): AppThunk => async (dispatch, getState) => {
      await dispatch(server.getDashboardStats.thunk.request());
    },
    [],
  );

  const { isFetching, isFetched, data } = useSelector(
    server.getDashboardStats.selector.state,
  );

  useEffect(() => {
    dispatch(getDashboardStatsThunk());
  }, [dispatch, getDashboardStatsThunk]);

  return (
    <div className={cn(className, cx())}>
      {isFetching && !isFetched && (
        <div className={cx('spin')}>
          <Spin size="large" />
        </div>
      )}

      {data && (
        <div className={cx('container')}>
          <div className={cx('statsCol')}>
            <div className={cx('header')}>
              <h3>Stats</h3>
            </div>

            <div className={cx('grid')}>
              <div className={cx('row')}>
                <div className={cx('param')}>Projects</div>
                <div className={cx('values')}>
                  <div className={cx('indicator', { projects: true })}>
                    {data.projectsTotal}
                  </div>
                </div>
              </div>

              <div className={cx('row')}>
                <div className={cx('param')}>Project versions</div>
                <div className={cx('values')}>
                  <div className={cx('indicator', { versions: true })}>
                    {data.versionsTotal}
                  </div>
                </div>
              </div>

              <div className={cx('row')}>
                <div className={cx('param')}>Locales</div>
                <div className={cx('values')}>
                  <div className={cx('indicator', { uniqLocales: true })}>
                    {data.uniqLocalesAmount}
                  </div>
                </div>
              </div>
            </div>

            <div className={cx('grid')}>
              <div className={cx('row')}>
                <div className={cx('param')}>Total keys</div>
                <div className={cx('values')}>
                  <div className={cx('indicator', { keys: true })}>
                    {data.uniqKeysAmount}
                  </div>
                </div>
              </div>

              <div className={cx('row')}>
                <div className={cx('param')}>Total messages</div>
                <div className={cx('values')}>
                  <div className={cx('indicator', { messages: true })}>
                    {data.messagesTotal}
                  </div>
                </div>
              </div>

              <div className={cx('row')}>
                <div className={cx('param')}>Filled messages</div>
                <div className={cx('values')}>
                  <div className={cx('indicator', { filledMessages: true })}>
                    {data.filledMessagesAmount}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={cx('progressCol')}>
            <div className={cx('header')}>
              <h3>Translations progress</h3>
            </div>

            <div className={cx('progress')}>
              <Progress
                type="circle"
                strokeColor={{
                  '0%': '#108ee9',
                  '100%': '#87d068',
                }}
                percent={roundPercent(
                  data.filledMessagesAmount,
                  data.messagesTotal,
                )}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
