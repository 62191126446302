import React, { useEffect } from 'react';

import { toNumber } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { server } from 'store';
import { Kit } from 'ui/Kit';

import { UserInfo } from '../../shared/UserInfo/UserInfo';

export const UserInfoPage = () => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const id = toNumber(params.id);
  const getUserByIdState = useSelector(server.getUserById.selector.state);

  useEffect(() => {
    dispatch(server.getUserById.thunk.request({ params: { id } }));
  }, [dispatch, id]);

  return (
    <Kit.LoadContainer
      error={getUserByIdState.error}
      isFetched={getUserByIdState.isFetched}
      isFetching={getUserByIdState.isFetching}
    >
      <Kit.Title>User #{id} / info</Kit.Title>
      {getUserByIdState.data && <UserInfo user={getUserByIdState.data.user} />}
    </Kit.LoadContainer>
  );
};
