import React, { useEffect } from 'react';

import { Progress, Table } from 'antd';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { core } from 'core';
import { toNumber } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { ServerState, server } from 'store';
import { Kit } from 'ui/Kit';
import { MessageCountBadge } from 'ui/MessageCountBadge';
import { ProjectLocale } from 'ui/ProjectLocale';
import { ProjectVersionColor } from 'ui/ProjectVersionColor';
import { roundPercent } from 'utils';

import { ProjectInfo } from '../../shared/ProjectInfo/ProjectInfo';
import { ProjectsMenu } from '../../shared/ProjectsMenu';

import './ProjectInfoPage.scss';

const cx = createCn('ProjectInfoPage');

type Row = NonNullable<
  ServerState['getProjectStatsById']['data']
>['list'][number];

export const ProjectInfoPage = () => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  const id = toNumber(params.id);
  const getProjectByIdState = useSelector(server.getProjectById.selector.state);
  const getProjectStatsByIdState = useSelector(
    server.getProjectStatsById.selector.state,
  );

  useEffect(() => {
    dispatch(server.getProjectById.thunk.request({ params: { id } }));
    dispatch(server.getProjectStatsById.thunk.request({ params: { id } }));

    return () => {
      dispatch(server.getProjectById.action.reset());
      dispatch(server.getProjectStatsById.action.reset());
    };
  }, [dispatch, id]);

  return (
    <div className={cn(cx())}>
      <Kit.LoadContainer
        error={getProjectStatsByIdState.error}
        isFetched={getProjectStatsByIdState.isFetched}
        isFetching={getProjectStatsByIdState.isFetching}
      >
        <ProjectsMenu />

        <Kit.Title>Project #{id} / info</Kit.Title>

        <ProjectInfo getProjectByIdState={getProjectByIdState} />

        <Table
          dataSource={getProjectStatsByIdState.data?.list ?? []}
          rowKey={(row) => row.projectVersion.id}
          columns={[
            {
              key: 'param',
              title: 'Version',
              render: (row: Row) => (
                <>
                  <div className={cx('versionParam')}>
                    <NavLink
                      exact={true}
                      activeClassName="active"
                      to={core.toPage(
                        `/manage/${id}/versions/info/${row.projectVersion.id}`,
                      )}
                      key={row.projectVersion.id}
                    >
                      <ProjectVersionColor
                        key={row.projectVersion.param}
                        param={row.projectVersion.param}
                      />
                    </NavLink>
                  </div>
                  <div className={cx('versionName')}>
                    {row.projectVersion.name}
                  </div>
                </>
              ),
              width: 140,
            },

            {
              title: 'Languages',
              key: 'languages',
              width: 280,
              render: (row: Row) => (
                <>
                  {row.locales.map((locale) => (
                    <NavLink
                      exact={true}
                      activeClassName="active"
                      to={core.toPage(
                        `/manage/${row.projectVersion.projectId}/messages/${locale.ico}`,
                      )}
                      key={locale.id}
                    >
                      <ProjectLocale
                        ico={locale.ico}
                        name={locale.enName}
                        small
                      />
                    </NavLink>
                  ))}
                </>
              ),
            },

            {
              title: 'Keys',
              key: 'uniqKeys',
              width: 80,
              render: (row: Row) => (
                <div>
                  <MessageCountBadge type="keys" count={row.uniqKeys} />
                </div>
              ),
              sorter: false,
            },

            {
              title: 'Total messages',
              key: 'messagesAmount',
              render: (row: Row) => (
                <div>
                  <MessageCountBadge type="amount" count={row.messagesAmount} />
                </div>
              ),
              width: 100,
            },

            {
              title: 'Filled messages',
              key: 'filledMessages',
              render: (row: Row) => (
                <div>
                  <MessageCountBadge
                    type="filled"
                    count={row.filledMessagesAmount}
                  />
                </div>
              ),
              width: 100,
            },

            {
              title: 'Progress',
              key: 'progress',
              width: 360,
              render: (row: Row) => (
                <div className={cn(cx('progress'))}>
                  {row.messagesAmount > 0 && (
                    <Progress
                      strokeColor={{
                        '0%': '#ffd068',
                        '50%': '#87d068',
                        '100%': '#108ee9',
                      }}
                      percent={roundPercent(
                        row.filledMessagesAmount,
                        row.messagesAmount,
                      )}
                    />
                  )}
                </div>
              ),
            },
          ]}
          pagination={{
            hideOnSinglePage: true,
          }}
          loading={getProjectByIdState.isFetching}
        />
      </Kit.LoadContainer>
    </div>
  );
};
