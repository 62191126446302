import React from 'react';

import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'antd';
import { createCn } from 'bem-react-classname';

import './ProjectUsersBadge.scss';

const cx = createCn('ProjectUsersBadge');

interface Props {
  count?: number;
}

export const ProjectUsersBadge: React.FC<Props> = ({ count }) => {
  return (
    <div className={cx()}>
      <div className={cx('colIcon')}>
        <FontAwesomeIcon icon={faUserFriends} className={cx('icon')} />
      </div>
      <div className={cx('count')}>
        <Badge
          count={count}
          showZero
          overflowCount={99}
          className="badge-count-1"
        />
      </div>
    </div>
  );
};
