import React, { useCallback } from 'react';

import { ReloadOutlined, ClearOutlined } from '@ant-design/icons';
import { Table, Button, Input, Progress } from 'antd';
import {
  GetProjectsFieldEnum,
  GetProjectsQuery,
  OrderByEnum,
} from 'api/server';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { core } from 'core';
import {
  useActive,
  useFilter,
  useNav,
  useUiMemory,
  useStateDebounce,
  useSort,
  usePermissions,
} from 'hooks';
import { map } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useLifecycles } from 'react-use';
import { AppThunk, server, ServerState } from 'store';
import { Kit } from 'ui/Kit';
import { MessageCountBadge } from 'ui/MessageCountBadge';
import { ProjectLocale } from 'ui/ProjectLocale';
import { ProjectUsersBadge } from 'ui/ProjectUsersBadge';
import { ProjectVersionColor } from 'ui/ProjectVersionColor';
import { toasts } from 'ui/toasts';
import { roundPercent, tablePaginationConfig } from 'utils';

import { ProjectsMenu } from '../../shared/ProjectsMenu';

import './ProjectsListPage.scss';

const cx = createCn('ProjectsListPage');

type Row = NonNullable<ServerState['getProjects']['data']>['list'][number];

export const ProjectsListPage = () => {
  const dispatch = useDispatch();
  const [setDeleting, checkDeleting] = useActive<number>();

  const filter = useFilter<GetProjectsQuery>({
    search: '',
  });

  const permissions = usePermissions();

  const [getProjectsDebounce, getProjectsClear] = useStateDebounce();

  const getProjects = useCallback(
    async (query: GetProjectsQuery, delay?: number) => {
      getProjectsDebounce(() => {
        dispatch(
          server.getProjects.thunk.request({
            query,
          }),
        );
      }, delay);
    },
    [dispatch, getProjectsDebounce],
  );

  const getProjectsState = useSelector(server.getProjects.selector.state);

  const [memoryLimit, setMemoryLimit] = useUiMemory(
    'table.projects_list.nav.limit',
    tablePaginationConfig.defaultPageSize,
  );

  const nav = useNav(getProjectsState.data?.nav, {
    limit: memoryLimit,
  });

  const sorting = useSort<GetProjectsFieldEnum>(getProjectsState.data?.sort, {
    field: GetProjectsFieldEnum.Id,
    order: OrderByEnum.Asc,
  });

  const onChangeSearch = useCallback(
    (event: any) => {
      const search = event.target.value.substr(0, 64);

      getProjects(
        {
          ...filter.asset({ search }),
          ...nav.initialValues,
          ...sorting.initialValues,
        },
        800,
      );
    },
    [filter, getProjects, nav.initialValues, sorting.initialValues],
  );

  const deleteProjectById = useCallback(
    async (id: number) => {
      dispatch(
        ((): AppThunk => async (dispatch, getState) => {
          try {
            setDeleting(id);

            if (!window.confirm('Are you sure?')) {
              throw new Error('cancel');
            }

            await dispatch(
              server.deleteProjectById.thunk.request({
                params: { id },
              }),
            );

            const { error } = server.deleteProjectById.selector.state(
              getState(),
            );

            toasts.result({
              title: 'Delete project',
              error,
            });

            if (!error) {
              await getProjects({
                ...filter.values,
                ...sorting.values,
                ...nav.toShiftedReq(-1),
              });
            } else {
              await getProjects({
                ...filter.values,
                ...sorting.values,
                ...nav.values,
              });
            }
          } catch {
          } finally {
            setDeleting(null);
          }
        })(),
      );
    },
    [dispatch, filter.values, getProjects, nav, setDeleting, sorting.values],
  );

  useLifecycles(
    () => {
      getProjects({ ...nav.initialValues, ...sorting.initialValues });
    },
    () => {
      getProjectsClear();
      dispatch(server.getProjects.action.reset());
      dispatch(server.deleteProjectById.action.reset());
    },
  );

  const deleteProjectByIdState = useSelector(
    server.deleteProjectById.selector.state,
  );

  const aggDisabled = deleteProjectByIdState.isFetching;

  const columns = [
    {
      title: 'ID',
      key: GetProjectsFieldEnum.Id,
      width: 60,
      render: (row: Row) => <>{row.project.id}</>,
      sorter: true,
      sortOrder: sorting.toAntOrder(GetProjectsFieldEnum.Id),
    },

    {
      title: 'Name / Slug',
      key: GetProjectsFieldEnum.Name,
      width: 200,
      render: (row: Row) => (
        <>
          <div className={cx('name')}>
            <NavLink
              exact={true}
              activeClassName="active"
              to={core.toPage(`/info/${row.project.id}`)}
              key={row.project.id}
            >
              {row.project.name}
            </NavLink>
          </div>
          <div className={cx('slug')}>{row.project.slug}</div>
        </>
      ),
      sorter: true,
      sortOrder: sorting.toAntOrder(GetProjectsFieldEnum.Name),
    },

    {
      title: 'Languages',
      key: 'languages',
      width: 220,
      render: (row: Row) => (
        <>
          {map(row.locales, (locale) => (
            <NavLink
              exact={true}
              activeClassName="active"
              to={core.toPage(
                `/manage/${row.project.id}/messages/${locale.ico}`,
              )}
              key={locale.id}
            >
              <ProjectLocale ico={locale.ico} name={locale.enName} small />
            </NavLink>
          ))}
        </>
      ),
    },
    {
      title: 'Versions',
      key: 'versions',
      width: 280,
      render: (row: Row) => (
        <>
          {map(row.versions, (version) => (
            <NavLink
              exact={true}
              activeClassName="active"
              to={core.toPage(
                `/manage/${row.project.id}/versions/info/${version.id}`,
              )}
              key={version.id}
            >
              <ProjectVersionColor
                key={version.param}
                param={version.param}
                title={version.param}
              />
            </NavLink>
          ))}
        </>
      ),
    },

    {
      title: 'Keys',
      key: 'uniqKeys',
      width: 70,
      render: (row: Row) => (
        <div>
          <MessageCountBadge type="keys" count={row.uniqKeys} />
        </div>
      ),
      sorter: false,
    },

    {
      title: 'Progress',
      key: 'progress',
      width: 180,
      render: (row: Row) => (
        <div className={cn(cx('progress'))}>
          {row.messagesAmount > 0 && (
            <Progress
              strokeColor={{
                '0%': '#ffd068',
                '50%': '#87d068',
                '100%': '#108ee9',
              }}
              percent={roundPercent(
                row.filledMessagesAmount,
                row.messagesAmount,
              )}
            />
          )}
        </div>
      ),
    },

    {
      title: 'Users',
      key: 'relatedUsers',
      width: 70,
      render: (row: Row) => (
        <div>
          <ProjectUsersBadge count={row.relatedUsers} />
        </div>
      ),
    },

    {
      title: 'Actions',
      key: 'actions',
      width: 78,
      render: (row: Row) => (
        <>
          <Kit.MenuActions>
            <Kit.ItemAction
              type={'info'}
              text={'Project info'}
              disabled={aggDisabled}
              to={core.toPage(`/info/${row.project.id}`)}
            />

            <Kit.ItemAction
              text={'Manage versions'}
              type={'versions'}
              disabled={aggDisabled}
              to={core.toPage(`/manage/${row.project.id}/versions`)}
            />

            <Kit.ItemAction
              text={'Edit project'}
              type={'edit'}
              disabled={aggDisabled || !permissions.has('PROJECTS_UPDATE')}
              to={core.toPage(`/edit/${row.project.id}`)}
            />

            <Kit.ItemAction
              text={'Delete project'}
              type={'delete'}
              disabled={aggDisabled || !row.canDelete}
              onClick={() => deleteProjectById(row.project.id)}
            />
          </Kit.MenuActions>
        </>
      ),
      align: 'right' as 'right',
      fixed: 'right' as 'right',
    },
  ];

  return (
    <div className={cn(cx())}>
      <ProjectsMenu />

      <Kit.TitleActions title={`Projects`}>
        <Kit.Action
          type={'create'}
          text={'Create project'}
          disabled={!permissions.has('PROJECTS_CREATE')}
          to={core.toPage('/add')}
        />
      </Kit.TitleActions>

      <Kit.FilterGrid>
        <Kit.FilterCol>
          <Input
            placeholder="Search query"
            allowClear
            onChange={onChangeSearch}
            readOnly={getProjectsState.isFetching}
            value={filter.values.search}
          />
        </Kit.FilterCol>

        <Kit.FilterCol>
          <Button
            type="default"
            onClick={() => {
              getProjects({
                ...filter.reset(),
                ...nav.initialValues,
                ...sorting.initialValues,
              });
            }}
            className={cn('ant-reset-button')}
            icon={<ClearOutlined />}
            disabled={getProjectsState.isFetching || filter.isInitial}
          >
            <span>Reset</span>
          </Button>{' '}
          <Button
            icon={<ReloadOutlined />}
            onClick={() =>
              getProjects({
                ...filter.values,
                ...nav.values,
                ...sorting.values,
              })
            }
          >
            Refresh
          </Button>
        </Kit.FilterCol>
      </Kit.FilterGrid>

      <Kit.ErrorContainer error={getProjectsState.error}>
        <Table
          dataSource={getProjectsState.data?.list ?? []}
          columns={columns}
          rowKey={(row) => row.project.id}
          rowClassName={(row) =>
            cx('row', { deleting: checkDeleting(row.project.id) })
          }
          onChange={(pagination, filters, sorter) => {
            setMemoryLimit(pagination.pageSize as number);

            getProjects({
              ...filter.values,
              ...(sorting.isAntChanged(sorter)
                ? nav.initialValues
                : nav.fromAntReq(pagination)),
              ...sorting.fromAntReq(sorter),
            });
          }}
          pagination={{
            ...tablePaginationConfig,
            ...nav.toAntPagination(),
          }}
          loading={getProjectsState.isFetching}
          scroll={{ x: '100%' }}
        />
      </Kit.ErrorContainer>
    </div>
  );
};
