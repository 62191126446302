import React from 'react';

import {
  faTachometerAlt,
  faChartLine,
} from '@fortawesome/free-solid-svg-icons';
import { core } from 'core';
import { Kit } from 'ui/Kit';

interface Props {}

export const DashboardMenu: React.FC<Props> = () => {
  return (
    <Kit.NavMenu>
      <Kit.NavItem
        to={core.toPage('')}
        icon={faTachometerAlt}
        text={'Overview'}
        exact
      />
      <Kit.NavItem
        to={core.toPage('/keysUpdates')}
        icon={faChartLine}
        text={'Message keys'}
        exact
      />
      <Kit.NavItem
        to={core.toPage('/translations')}
        icon={faChartLine}
        text={'Translations'}
        exact
      />
      <Kit.NavItem
        to={core.toPage('/synchronizations')}
        icon={faChartLine}
        text={'Synchronizations'}
        exact
      />
      <Kit.NavItem
        to={core.toPage('/userActions')}
        icon={faChartLine}
        text={'User actions'}
        exact
      />
    </Kit.NavMenu>
  );
};
