import React from 'react';

import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import moment from 'moment';
import { Kit } from 'ui/Kit';

import { DashboardMenu } from '../../components/DashboardMenu';
import { ProjectVersionSyncsStatsChart } from '../../components/ProjectVersionSyncsStatsChart';

import './ProjectVersionSyncsStatsPage.scss';

interface Props {}

const cx = createCn('ProjectVersionSyncsStatsPage');

export const ProjectVersionSyncsStatsPage: React.FC = () => {
  return (
    <div className={cn(cx())}>
      <DashboardMenu />

      <Kit.Title>Development synchronizations</Kit.Title>

      <div className={cx('graph')}>
        <ProjectVersionSyncsStatsChart
          fromDate={moment().subtract(12, 'month').format()}
        />
      </div>
    </div>
  );
};
