import React, { useCallback } from 'react';

import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { core } from 'core';
import { useFormError } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLifecycles } from 'react-use';
import { server, AppThunk, app } from 'store';
import { Kit } from 'ui/Kit';
import { toasts } from 'ui/toasts';

import { UserForm } from '../../shared/UserForm';
import { UsersMenu } from '../../shared/UsersMenu';

import './AddUserPage.scss';

const cx = createCn('AddUserPage');

interface Props {}

export const AddUserPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const title = 'Create user';
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    role: '',
    state: undefined,
    projectIds: [],
  };

  useLifecycles(
    () => {
      dispatch(server.getProjectsList.thunk.request({}));
    },
    () => {
      dispatch(server.getProjectsList.action.reset());
    },
  );

  const authState = useSelector(app.auth.selector.state);

  const getProjectsListState = useSelector(
    server.getProjectsList.selector.state,
  );

  const createUserState = useSelector(server.createUser.selector.state);
  const formError = useFormError(createUserState.error);

  const onFinish = useCallback(
    (values) => {
      dispatch(
        ((): AppThunk => async (dispatch, getState) => {
          await dispatch(
            server.createUser.thunk.request({
              body: values,
            }),
          );
          const { error, data } = server.createUser.selector.state(getState());

          toasts.result({
            title,
            error,
            // exceptParams: keys(initialValues),
          });

          if (!error) {
            history.push(core.toPage(`/edit/${data?.id}`));
          }
        })(),
      );
    },
    [dispatch, history],
  );

  return (
    <div className={cn(cx())}>
      <Kit.LoadContainer
        error={getProjectsListState.error}
        isFetched={getProjectsListState.isFetched}
        isFetching={getProjectsListState.isFetching}
      >
        <UsersMenu />
        <Kit.Title>{title}</Kit.Title>

        <UserForm
          action="add"
          onFinish={onFinish}
          formError={formError}
          disabled={createUserState.isFetching}
          initialValues={initialValues}
          projectsList={getProjectsListState.data?.list || []}
          role={authState.data?.user?.role}
        />
      </Kit.LoadContainer>
    </div>
  );
};
