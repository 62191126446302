import { AppThunk, server } from 'store';

import { commonActionThunk } from './commonActionThunk';

export const deleteAuthSessionThunk = ({
  key,
  title,
}: {
  key: string;
  title: string;
}): AppThunk => async (dispatch, getState) => {
  await dispatch(
    commonActionThunk({
      action: server.deleteSession.thunk.request({ params: { key } }),
      errorSelector: server.deleteSession.selector.error,
      title,
      confirmMessage: `Are you sure?`,
      confirm: true,
    }),
  );
};
