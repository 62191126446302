import React, { useCallback, useState } from 'react';

import { ReloadOutlined } from '@ant-design/icons';
import { Table, Button } from 'antd';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { core } from 'core';
import { useActive, usePermissions } from 'hooks';
import { toInteger } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { useLifecycles } from 'react-use';
import { AppThunk, server, ServerState } from 'store';
import { Kit } from 'ui/Kit';
import { ProjectLocale } from 'ui/ProjectLocale';
import { toasts } from 'ui/toasts';
import { timestampToFormat } from 'utils';

import { ProjectInfo } from '../../shared/ProjectInfo';
import { ProjectsMenu } from '../../shared/ProjectsMenu';

import { AddProjectVersionFormModal } from './AddProjectVersionFormModal';
import { EditProjectVersionFormModal } from './EditProjectVersionFormModal';

import './ProjectVersionsListPage.scss';

const cx = createCn('ProjectVersionsListPage');

type Row = NonNullable<
  ServerState['getProjectVersions']['data']
>['list'][number];

export const ProjectVersionsListPage = () => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const projectId = toInteger(params.id);

  const [setDeleting, checkDeleting] = useActive<number>();

  const [projectVersionId, setProjectVersionId] = useState<number>(0);
  const [crudMode, setCrudMode] = useState<'create' | 'edit' | null>(null);
  const permissions = usePermissions();

  const refresh = useCallback(async () => {
    dispatch(
      server.getProjectVersions.thunk.request({
        query: { projectId, limit: 100 },
      }),
    );

    dispatch(
      server.getProjectById.thunk.request({
        params: { id: projectId },
      }),
    );
  }, [dispatch, projectId]);

  const deleteProjectVersionById = useCallback(
    async (id: number) => {
      await dispatch(
        ((): AppThunk => async (dispatch, getState) => {
          try {
            setDeleting(id);

            if (!window.confirm(`Are you sure?`)) {
              throw new Error('cancel');
            }
            await dispatch(
              server.deleteProjectVersionById.thunk.request({ params: { id } }),
            );

            const { error } = server.deleteProjectVersionById.selector.state(
              getState(),
            );

            toasts.result({
              title: 'Delete project version',
              error,
            });
          } catch {
          } finally {
            refresh();
            setDeleting(null);
          }
        })(),
      );
    },
    [dispatch, refresh, setDeleting],
  );

  useLifecycles(
    () => {
      refresh();
    },
    () => {
      dispatch(server.getProjectById.action.reset());
      dispatch(server.getProjectVersions.action.reset());
      dispatch(server.deleteProjectVersionById.action.reset());
    },
  );

  const getProjectByIdState = useSelector(server.getProjectById.selector.state);
  const getProjectVersionsState = useSelector(
    server.getProjectVersions.selector.state,
  );
  const deleteProjectVersionByIdState = useSelector(
    server.deleteProjectVersionById.selector.state,
  );

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: (row: Row) => <>{row.version.name}</>,
      width: 150,
    },

    {
      title: 'Param',
      key: 'param',
      render: (row: Row) => <>{row.version.param}</>,
      width: 150,
    },

    {
      title: 'Version languages',
      key: 'languages',
      width: 320,
      render: (row: Row) => (
        <>
          {row.locales.map((locale) => (
            <NavLink
              exact={true}
              activeClassName="active"
              to={core.toPage(`/manage/${projectId}/messages/${locale.ico}`)}
              key={locale.id}
            >
              <ProjectLocale ico={locale.ico} name={locale.enName} small />
            </NavLink>
          ))}
        </>
      ),
    },
    {
      title: 'Created',
      key: 'createdAt',
      render: (row: Row) => (
        <div>
          {timestampToFormat(row.version.createdAt, 'YYYY.MM.DD HH:mm:ss')}
        </div>
      ),
      width: 120,
    },

    {
      title: 'Actions',
      key: 'actions',
      width: 120,
      render: (row: Row) => (
        <Kit.Actions>
          <Kit.Action
            type={'edit'}
            disabled={deleteProjectVersionByIdState.isFetching || !row.canEdit}
            onClick={() => {
              setProjectVersionId(row.version.id);
              setCrudMode('edit');
            }}
            title={`Edit project version #${row.version.id}`}
          />

          <Kit.Action
            type={'delete'}
            disabled={
              deleteProjectVersionByIdState.isFetching || !row.canDelete
            }
            onClick={() => deleteProjectVersionById(row.version.id)}
            title={`Delete project version #${row.version.id}`}
          />
        </Kit.Actions>
      ),
      align: 'right' as 'right',
    },
  ];

  return (
    <div className={cn(cx())}>
      <ProjectsMenu />

      <Kit.TitleActions title={`Project #${projectId} / versions`}>
        {/* <Button
          icon={<PlusCircleOutlined />}
          onClick={() => {
            setCrudMode('create');
          }}
          disabled={!permissions.has('PROJECTS_VERSION_CREATE')}
        >
          Create version
        </Button> */}

        <Kit.Action
          type={'create'}
          text={'Create version'}
          onClick={() => {
            setCrudMode('create');
          }}
          disabled={!permissions.has('PROJECTS_VERSION_CREATE')}
        />
      </Kit.TitleActions>

      <Kit.LoadContainer
        error={getProjectByIdState.error}
        isFetched={getProjectByIdState.isFetched}
        isFetching={getProjectByIdState.isFetching}
      >
        <ProjectInfo getProjectByIdState={getProjectByIdState} />

        <Kit.FilterGrid>
          <Kit.FilterCol></Kit.FilterCol>
          <Kit.FilterCol handler>
            <Button icon={<ReloadOutlined />} onClick={refresh}>
              refresh
            </Button>
          </Kit.FilterCol>
        </Kit.FilterGrid>

        <Kit.ErrorContainer error={getProjectVersionsState.error}>
          <Table
            dataSource={getProjectVersionsState.data?.list ?? []}
            columns={columns}
            rowKey={(row) => row.version.id}
            rowClassName={(row) =>
              cx('row', { deleting: checkDeleting(row.version.id) })
            }
            pagination={{
              hideOnSinglePage: true,
            }}
            loading={getProjectVersionsState.isFetching}
          />
        </Kit.ErrorContainer>
      </Kit.LoadContainer>

      {crudMode === 'edit' && (
        <EditProjectVersionFormModal
          projectVersionId={projectVersionId}
          projectId={projectId}
          close={() => {
            setProjectVersionId(0);
            setCrudMode(null);
            refresh();
          }}
        />
      )}

      {crudMode === 'create' && (
        <AddProjectVersionFormModal
          projectId={projectId}
          close={() => {
            setCrudMode(null);
            refresh();
          }}
          localeIds={
            getProjectByIdState.data?.locales.map((locale) => locale.id) || []
          }
        />
      )}
    </div>
  );
};
