import React from 'react';

import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import moment from 'moment';
import { Kit } from 'ui/Kit';

import { ActionsStatsChart } from '../../components/ActionsStatsChart';
import { DashboardMenu } from '../../components/DashboardMenu';
import { KeysUpdatesStatsChart } from '../../components/KeysUpdatesStatsChart';
import { MessagesUpdatesStatsChart } from '../../components/MessagesUpdatesStatsChart';
import { ProjectVersionSyncsStatsChart } from '../../components/ProjectVersionSyncsStatsChart';

import { DashboardStats } from './DashboardStats';

import './HomeDashboardPage.scss';

interface Props {}

const cx = createCn('HomeDashboardPage');

export const HomeDashboardPage: React.FC = () => {
  return (
    <div className={cn(cx())}>
      <DashboardMenu />

      <Kit.Title>Dashboard overview</Kit.Title>

      <div className={cx('stats')}>
        <DashboardStats />
      </div>

      <div className={cx('graphs')}>
        <div className={cx('graph')}>
          <KeysUpdatesStatsChart
            header={'New message keys'}
            fromDate={moment().subtract(3, 'month').format()}
          />
        </div>

        <div className={cx('graph')}>
          <MessagesUpdatesStatsChart
            header={'Editing translations'}
            fromDate={moment().subtract(3, 'month').format()}
          />
        </div>

        <div className={cx('graph')}>
          <ProjectVersionSyncsStatsChart
            header={'Development synchronizations'}
            fromDate={moment().subtract(3, 'month').format()}
          />
        </div>

        <div className={cx('graph')}>
          <ActionsStatsChart
            header={'System user actions'}
            fromDate={moment().subtract(3, 'month').format()}
          />
        </div>
      </div>
    </div>
  );
};
