import React, { useCallback, useEffect, useRef } from 'react';

import { Spin } from 'antd';
import { GetProjectVersionSyncsStatsQuery } from 'api/server';
import { createCn } from 'bem-react-classname';
import Chart from 'chart.js';
import cn from 'classnames';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunk, server } from 'store';

import { getDashboardTimeChartConfig } from '../../utils/chartOptionsUtil';

import './ProjectVersionSyncsStatsChart.scss';

const cx = createCn('ProjectVersionSyncsStatsChart');

interface Props {
  className?: string;
  header?: string;
  fromDate: string;
}

export const ProjectVersionSyncsStatsChart: React.FC<Props> = ({
  className,
  header,
  fromDate,
}) => {
  const dispatch = useDispatch();
  const chartRef = useRef<HTMLCanvasElement>(null);

  const getProjectVersionSyncsStatsThunk = useCallback(
    (query: GetProjectVersionSyncsStatsQuery): AppThunk => async (
      dispatch,
      getState,
    ) => {
      await dispatch(
        server.getProjectVersionSyncsStats.thunk.request({
          query,
        }),
      );

      const { data } = server.getProjectVersionSyncsStats.selector.state(
        getState(),
      );

      if (data && chartRef.current) {
        new Chart(
          chartRef.current,
          getDashboardTimeChartConfig({
            borderColor: '#c7ff18',
            yLabelString: 'Synchronizations',
            data: data.list.map((item) => ({
              x: item.date,
              y: item.amount,
            })),
          }),
        );
      }
    },
    [chartRef],
  );

  const { isFetching, isFetched } = useSelector(
    server.getProjectVersionSyncsStats.selector.state,
  );

  useEffect(() => {
    dispatch(
      getProjectVersionSyncsStatsThunk({
        timezone: moment.tz.guess(true),
        fromDate: moment(fromDate).startOf('day').format(),
      }),
    );
  }, [dispatch, fromDate, getProjectVersionSyncsStatsThunk]);

  return (
    <div className={cn(className, cx())}>
      <div className={cx('container')}>
        <div className={cx('header')}>
          <h3>{header}</h3>
        </div>

        <div className={cx('graphArea')}>
          {isFetching && !isFetched && (
            <div className={cx('spin')}>
              <Spin size="large" />
            </div>
          )}
          <canvas ref={chartRef} />
        </div>
      </div>
    </div>
  );
};
