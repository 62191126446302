import React from 'react';

import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { store } from 'store';

import { AppModules } from './AppModules';

function App() {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <AppModules />
      </Provider>
    </HelmetProvider>
  );
}

export default App;
