import React from 'react';

import { core } from 'core';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ProfileAuthSessionsPage } from './pages/ProfileAuthSessionsPage';
import { ProfileChangePasswordPage } from './pages/ProfileChangePasswordPage';
import { ProfilePersonalDataPage } from './pages/ProfilePersonalDataPage';

export const ProfileModule = () => {
  return (
    <Switch>
      <Route path={core.toPage('/data')} exact>
        <ProfilePersonalDataPage />
      </Route>
      <Route path={core.toPage('/password')} exact>
        <ProfileChangePasswordPage />
      </Route>
      <Route path={core.toPage('/sessions')} exact>
        <ProfileAuthSessionsPage />
      </Route>
      <Redirect to="/404" />
    </Switch>
  );
};
