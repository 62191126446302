import React from 'react';

import { faUser, faPassport, faKey } from '@fortawesome/free-solid-svg-icons';
import { core } from 'core';
import { Kit } from 'ui/Kit';

interface Props {}

export const ProfileMenu: React.FC<Props> = () => {
  return (
    <Kit.NavMenu>
      <Kit.NavItem
        to={core.toPage('/data')}
        icon={faUser}
        text={'Personal data'}
      />
      <Kit.NavItem
        to={core.toPage('/password')}
        icon={faPassport}
        text={'Change password'}
      />
      <Kit.NavItem
        to={core.toPage('/sessions')}
        icon={faKey}
        text={'Auth sessions'}
      />
    </Kit.NavMenu>
  );
};
