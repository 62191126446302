import React, { useCallback } from 'react';

import { Form, Input, Button } from 'antd';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { useFormError } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useLifecycles } from 'react-use';
import { server, AppThunk } from 'store';
import { refreshAuthThunk } from 'thunks';
import { Kit } from 'ui/Kit';
import { toasts } from 'ui/toasts';

import { ProfileMenu } from '../../shared/ProfileMenu';

import './ProfileChangePasswordPage.scss';

const cx = createCn('ProfileChangePasswordPage');

interface Props {}

export const ProfileChangePasswordPage: React.FC = () => {
  const dispatch = useDispatch();

  useLifecycles(
    () => {
      dispatch(server.getProfile.thunk.request());
    },
    () => {
      dispatch(server.changePassword.action.reset());
      dispatch(server.getProfile.action.reset());
    },
  );

  const changePasswordState = useSelector(server.changePassword.selector.state);
  const formError = useFormError(changePasswordState.error);

  const disabled = changePasswordState.isFetching;

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const title = 'Change password';

  const onFinish = useCallback(
    (values) => {
      dispatch(
        ((): AppThunk => async (dispatch, getState) => {
          await dispatch(
            server.changePassword.thunk.request({
              body: values,
            }),
          );
          const { error } = server.changePassword.selector.state(getState());

          toasts.result({
            title,
            error,
            // exceptParams: keys(initialValues),
          });

          if (!error) {
            await dispatch(server.getProfile.thunk.request());
            await dispatch(refreshAuthThunk());
          }
        })(),
      );
    },
    [dispatch],
  );

  return (
    <div className={cn(cx())}>
      <ProfileMenu />
      <Kit.Title>{title}</Kit.Title>
      <Kit.FormContainer>
        <Form
          name="form"
          className={cn(cx('form'))}
          initialValues={initialValues}
          onFinish={onFinish}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
        >
          <Form.Item
            name="currentPassword"
            validateStatus={formError.status('currentPassword')}
            help={formError.note('currentPassword')}
            label="Current password"
            labelAlign={'left'}
          >
            <Input size={'middle'} disabled={disabled} />
          </Form.Item>

          <Form.Item
            name="newPassword"
            validateStatus={formError.status('newPassword')}
            help={formError.note('newPassword')}
            label="New password"
            labelAlign={'left'}
          >
            <Input size={'middle'} disabled={disabled} />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            validateStatus={formError.status('confirmPassword')}
            help={formError.note('confirmPassword')}
            label="Confirm password"
            labelAlign={'left'}
          >
            <Input size={'middle'} disabled={disabled} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              size={'large'}
              disabled={disabled}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Kit.FormContainer>
    </div>
  );
};
