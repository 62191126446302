import React from 'react';

import { faComment, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'antd';
import { createCn } from 'bem-react-classname';
import './MessageCountBadge.scss';

const cx = createCn('MessageCountBadge');

interface Props {
  count?: number | null;
  type: 'filled' | 'amount' | 'keys';
}

const typeIcons = {
  filled: faComment,
  amount: faComment,
  keys: faCommentDots,
};

export const MessageCountBadge: React.FC<Props> = ({ count, type }) => {
  return (
    <div className={cx()}>
      <div className={cx('colIcon')}>
        <FontAwesomeIcon icon={typeIcons[type]} className={cx('icon')} />
        <i className="far fa-comment-check"></i>
      </div>
      <div className={cx('count')}>
        <Badge
          count={count}
          showZero
          overflowCount={10000}
          className="badge-count-1"
        />
      </div>
    </div>
  );
};
