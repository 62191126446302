import React from 'react';

import { createCn } from 'bem-react-classname';
import cn from 'classnames';

import './KitActions.scss';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const cx = createCn('KitActions');

export const KitActions: React.FC<Props> = ({ children, className }) => {
  return <div className={cn(className, cx())}>{children}</div>;
};
