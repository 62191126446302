import React from 'react';

import { Layout } from 'antd';
import { createCn } from 'bem-react-classname';

import './OutsideLayout.scss';

const cx = createCn('OutsideLayout');

export const OutsideLayout: React.FC = ({ children }) => {
  return (
    <Layout className={cx()}>
      <Layout.Content className={cx('content')}>{children}</Layout.Content>
    </Layout>
  );
};
