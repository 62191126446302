import React from 'react';

import { Tooltip } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toasts } from 'ui/toasts';

interface Props {
  title: string;
  text: string;
  children: React.Component | React.ReactNode;
}

export const ClickToCopy: React.FC<Props> = ({ children, title, text }) => {
  return (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        toasts.success({
          title: 'Copied!',
          message: '',
        });
      }}
    >
      <Tooltip placement="top" title={title}>
        {children}
      </Tooltip>
    </CopyToClipboard>
  );
};
