import { combineReducers } from '@reduxjs/toolkit';

import { auth, rolePermissions } from './slices';

export const appReducer = combineReducers({
  auth: auth.reducer,
  rolePermissions: rolePermissions.reducer,
});

export const app = {
  auth,
  rolePermissions,
};
