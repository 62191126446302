import React from 'react';

import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createCn } from 'bem-react-classname';

import './UserBadge.scss';

const cx = createCn('UserBadge');

interface Props {
  user: any;
}

export const UserBadge: React.FC<Props> = ({ user }) => {
  return (
    <div className={cx()}>
      <div className={cx('colIcon')}>
        <FontAwesomeIcon icon={faUser} />
      </div>
      <div className={cx('userId')}>#{user.id}</div>
      <div className={cx('userName')}>
        {user.firstName} {user.lastName}
      </div>
    </div>
  );
};
