import React from 'react';

import { Form, Input, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { useFormError } from 'hooks';
import { map } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { useLifecycles } from 'react-use';
import { server } from 'store';
import { AnyValues } from 'types';

import './ProjectVersionForm.scss';

const cx = createCn('ProjectVersionForm');

interface Props {
  disabled?: boolean;
  onFinish: (values: any) => void;
  formError: ReturnType<typeof useFormError>;
  initialValues:
    | AnyValues<
        Parameters<typeof server.createProjectVersion.thunk.request>[0]['body']
      >
    | AnyValues<
        Parameters<
          typeof server.updateProjectVersionById.thunk.request
        >[0]['body']
      >;
  form: FormInstance;
  options?: {
    canChangeParam?: boolean;
  };
  id?: number;
}

export const ProjectVersionForm: React.FC<Props> = ({
  disabled,
  onFinish,
  formError,
  initialValues,
  form,
  options,
  id,
}) => {
  const dispatch = useDispatch();

  const aggDisabled = disabled;
  const defOptions = { canChangeParam: true, ...options };

  useLifecycles(
    () => {
      dispatch(server.getLocalesList.thunk.request());
    },
    () => {
      dispatch(server.getLocalesList.action.reset());
      dispatch(server.createProjectVersion.action.reset());
      dispatch(server.updateProjectVersionById.action.reset());
    },
  );

  const getLocalesListState = useSelector(server.getLocalesList.selector.state);

  return (
    <div className={cn(cx())}>
      <Form
        form={form}
        name="form"
        className={cn(cx('form'))}
        initialValues={initialValues}
        onFinish={onFinish}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
      >
        <Form.Item
          name="name"
          validateStatus={formError.status('name')}
          help={formError.note('name')}
          label="Name"
          labelAlign={'left'}
        >
          <Input size={'middle'} disabled={aggDisabled} />
        </Form.Item>

        <Form.Item
          name="param"
          validateStatus={formError.status('param')}
          label="Param"
          labelAlign={'left'}
        >
          <Input
            size={'middle'}
            disabled={aggDisabled}
            readOnly={!defOptions.canChangeParam}
          />
        </Form.Item>

        <Form.Item name={'localeIds'} label="Languages" labelAlign={'left'}>
          <Select
            mode="multiple"
            optionFilterProp="title"
            className={"ant-select-block-options'"}
          >
            {map(getLocalesListState.data?.list, (locale) => (
              <Select.Option
                key={locale.id}
                value={locale.id}
                title={locale.name}
              >
                {locale.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          validateStatus={formError.status()}
          help={formError.note() && formError.emptyDetails()}
          wrapperCol={{ offset: 12, span: 12 }}
        ></Form.Item>

        <Form.Item name="projectId" noStyle>
          <Input type="hidden" value={initialValues.projectId} />
        </Form.Item>
      </Form>
    </div>
  );
};
