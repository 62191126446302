import { get, isNull } from 'lodash-es';

type Errors = { [key: string]: string };

export const useFormError = (error: any) => {
  const details = get(error, 'data.details', []);

  const errors: Errors = details.reduce(
    (obj: Errors, row: { param: string; note: string }) => {
      obj[row.param] = row.note;
      return obj;
    },
    {},
  );

  const message: null | string = get(error, 'data.message', null);

  const is = (param?: string): boolean => {
    return param ? errors.hasOwnProperty(param) : !isNull(message);
  };

  const status = (param?: string) => {
    return is(param) ? 'error' : undefined;
  };

  const note = (param?: string) => {
    if (param && errors.hasOwnProperty(param)) {
      return errors[param];
    } else if (!param && !isNull(message)) {
      return message;
    }

    return undefined;
  };

  const emptyDetails = (): boolean => {
    return details.length === 0;
  };

  return { is, status, note, emptyDetails };
};
