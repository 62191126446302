import { isNull } from 'lodash-es';
import moment from 'moment';

export const unixToFormat = (
  unix: number | null,
  format: string,
  def: string = '',
): string => (isNull(unix) ? def : moment(unix * 1000).format(format));

export const timestampToFormat = (
  timestamp: string | null,
  format: string,
  def: string = '',
): string => (isNull(timestamp) ? def : moment(timestamp).format(format));
