import React, { useCallback } from 'react';

import { ReloadOutlined, ClearOutlined } from '@ant-design/icons';
import {
  faUserSlash,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Button, Input, Tooltip, Select } from 'antd';
import {
  AuthStatusEnum,
  FormActionDataDto,
  GetActionsFieldEnum,
  GetActionsQuery,
  OrderByEnum,
} from 'api/server';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import {
  useActive,
  useFilter,
  useNav,
  useStateDebounce,
  useUiMemory,
  useSort,
} from 'hooks';
import { keys } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { useLifecycles } from 'react-use';
import { AppThunk, server } from 'store';
import { ClickToCopy } from 'ui/ClickToCopy';
import { Kit } from 'ui/Kit';
import { UserBadge } from 'ui/UserBadge';
import { toasts } from 'ui/toasts';
import { tablePaginationConfig, timestampToFormat } from 'utils';

import { ActionsMenu } from '../../shared/ActionsMenu';
import { UsersAutoComplete } from '../../shared/UsersAutoComplete';

import './ActionsListPage.scss';

const cx = createCn('ActionsListPage');

export const ActionsListPage = () => {
  const dispatch = useDispatch();

  const [setDeleting, checkDeleting] = useActive<number>();

  const filter = useFilter<GetActionsQuery>({
    search: '',
    authStatus: undefined,
    userId: undefined,
    section: undefined,
    event: undefined,
  });

  const onChangeUser = (userId?: number | string) => {
    getActions({
      ...filter.asset({ userId: userId ? Number(userId) : undefined }),
      ...nav.initialValues,
      ...sorting.initialValues,
    });
  };

  const [getActionsDebounce, getActionsClear] = useStateDebounce();

  const getActions = useCallback(
    async (
      query: Parameters<typeof server.getActions.thunk.request>[0]['query'],
      delay?: number,
    ) => {
      getActionsDebounce(() => {
        dispatch(
          server.getActions.thunk.request({
            query,
          }),
        );
      }, delay);
    },
    [dispatch, getActionsDebounce],
  );

  const getActionsState = useSelector(server.getActions.selector.state);

  const [memoryLimit, setMemoryLimit] = useUiMemory(
    'table.actions_list.nav.limit',
    tablePaginationConfig.defaultPageSize,
  );

  const nav = useNav(getActionsState.data?.nav, {
    limit: memoryLimit,
  });

  const sorting = useSort<GetActionsFieldEnum>(getActionsState.data?.sort, {
    field: GetActionsFieldEnum.CreatedAt,
    order: OrderByEnum.Desc,
  });

  const onChangeSearch = useCallback(
    (event: any) => {
      const search = event.target.value.substr(0, 64);

      getActions(
        {
          ...filter.asset({ search }),
          ...nav.initialValues,
          ...sorting.initialValues,
        },
        800,
      );
    },
    [filter, getActions, nav.initialValues, sorting.initialValues],
  );

  const onChangeAuthStatus = useCallback(
    (value: AuthStatusEnum | '') => {
      const authStatus = value || undefined;
      let userId = filter.values.userId;

      if (authStatus === AuthStatusEnum.Unauthorized) {
        userId = undefined;
      }

      getActions({
        ...filter.asset({ authStatus, userId }),
        ...nav.initialValues,
        ...sorting.initialValues,
      });
    },
    [filter, getActions, nav.initialValues, sorting.initialValues],
  );

  const onChangeSection = useCallback(
    (value?: string) => {
      const section = value || undefined;

      getActions({
        ...filter.asset({
          section,
          event:
            section === filter.values.section ? filter.values.event : undefined,
        }),
        ...nav.initialValues,
        ...sorting.initialValues,
      });
    },
    [filter, getActions, nav.initialValues, sorting.initialValues],
  );

  const onChangeEvent = useCallback(
    (value?: string) => {
      const event = value || undefined;

      getActions({
        ...filter.asset({
          event,
        }),
        ...nav.initialValues,
        ...sorting.initialValues,
      });
    },
    [filter, getActions, nav.initialValues, sorting.initialValues],
  );

  const deleteActionById = useCallback(
    async (id: number) => {
      dispatch(
        ((): AppThunk => async (dispatch, getState) => {
          try {
            setDeleting(id);

            if (!window.confirm('Are you sure?')) {
              throw new Error('cancel');
            }

            await dispatch(
              server.deleteActionById.thunk.request({
                params: { id },
              }),
            );

            const { error } = server.deleteActionById.selector.state(
              getState(),
            );

            toasts.result({
              title: 'Delete action',
              error,
            });

            if (error) {
              getActions({
                ...filter.values,
                ...nav.values,
                ...sorting.values,
              });
            } else {
              getActions({
                ...filter.values,
                ...nav.toShiftedReq(-1),
              });
            }
          } catch {
          } finally {
            setDeleting(null);
          }
        })(),
      );
    },
    [dispatch, setDeleting, getActions, filter.values, nav, sorting.values],
  );

  useLifecycles(
    () => {
      getActions({
        ...nav.initialValues,
        ...sorting.initialValues,
      });

      dispatch(server.getActionNavigations.thunk.request());
    },
    () => {
      getActionsClear();
      dispatch(server.getActions.action.reset());
      dispatch(server.deleteActionById.action.reset());
    },
  );

  const deleteActionByIdState = useSelector(
    server.deleteActionById.selector.state,
  );

  const getActionNavigationsState = useSelector(
    server.getActionNavigations.selector.state,
  );

  const sectionOptions = [
    { value: '', label: '' },
    ...(getActionNavigationsState.data?.list ?? []).map((item) => ({
      label: item.name,
      value: item.param,
    })),
  ];

  const eventOptions = [
    { value: '', label: '' },
    ...((getActionNavigationsState.data?.list || [])
      .find((item) => item.param === filter.values.section)
      ?.events?.map((item) => ({
        label: item.name,
        value: item.param,
      })) || []),
  ];

  const authStatusOptions = [
    { value: '', label: '' },
    ...keys(AuthStatusEnum).map((authStatus) => ({
      label: authStatus,
      value: AuthStatusEnum[authStatus as keyof typeof AuthStatusEnum],
    })),
  ];

  const aggDisabled = deleteActionByIdState.isFetching;

  const columns = [
    {
      key: 'createdAt',
      width: 90,
      title: 'Created',
      render: (row: FormActionDataDto) => (
        <div>
          <div>{timestampToFormat(row.createdAt, 'YYYY.MM.DD')}</div>
          <div>{timestampToFormat(row.createdAt, 'HH:mm:ss')}</div>
        </div>
      ),
      sorter: true,
      sortOrder: sorting.toAntOrder(GetActionsFieldEnum.CreatedAt),
    },

    {
      title: 'Title / Description',
      key: 'title',
      width: 320,
      render: (row: FormActionDataDto) => (
        <div>
          <div>{row.title}</div>
          <div className={cx('description')}>{row.description}</div>
        </div>
      ),
      sorter: false,
    },

    {
      title: 'Section / Event / Id',
      key: 'navigation',
      width: 160,
      render: (row: FormActionDataDto) => (
        <div className={cx('navigation')}>
          {row.section && (
            <div className={cx('navigationItem', { section: true })}>
              <ClickToCopy
                title={'Click to copy the section'}
                text={row.section}
              >
                {row.section}
              </ClickToCopy>
            </div>
          )}

          {row.event && (
            <div className={cx('navigationItem', { event: true })}>
              <ClickToCopy title={'Click to copy the event'} text={row.section}>
                {row.event}
              </ClickToCopy>
            </div>
          )}

          {row.relateId && (
            <div className={cx('navigationItem', { relateId: true })}>
              {row.relateId}
            </div>
          )}
        </div>
      ),
      sorter: false,
    },

    {
      title: 'Author (User)',
      key: 'user',
      width: 160,
      render: (row: FormActionDataDto) => (
        <div className={cx('userCol')}>
          <div>
            {!row.userId && (
              <div className={cx('unauthorizedUser')}>
                <Tooltip placement="top" title={'The visitor is unauthorized'}>
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </Tooltip>
              </div>
            )}
          </div>
          {row.userId && (
            <div>
              {row.user ? (
                <UserBadge user={row.user} />
              ) : (
                <div className={cx('userDeleted')}>
                  <Tooltip placement="top" title={'The user is deleted'}>
                    <FontAwesomeIcon icon={faUserSlash} />
                  </Tooltip>
                </div>
              )}
            </div>
          )}
        </div>
      ),
      sorter: false,
    },

    {
      title: 'ip',
      key: 'ip',
      width: 160,
      render: (row: FormActionDataDto) => <div>{row.ip}</div>,
      sorter: false,
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 38 * 3,
      render: (row: FormActionDataDto) => (
        <>
          <Kit.Actions className={cx('rowActions')}>
            {/* <Kit.Action
              type={'info'}
              title={'Action info'}
              disabled={aggDisabled && permissions.isSuperAdmin()}
              to={core.toPage(`/info/${row.id}`)}
            /> */}
            <Kit.Action
              title={'Delete action'}
              type={'delete'}
              disabled={aggDisabled || !row.canDelete}
              onClick={() => deleteActionById(row.id)}
            />
          </Kit.Actions>
        </>
      ),
      align: 'right' as 'right',
      fixed: 'right' as 'right',
    },
  ];

  return (
    <div className={cn(cx())}>
      <ActionsMenu />

      <Kit.Title>Actions</Kit.Title>

      <Kit.FilterGrid>
        <Kit.FilterCol>
          <Input
            placeholder="Search query"
            allowClear
            onChange={onChangeSearch}
            readOnly={getActionsState.isFetching}
            value={filter.values.search}
          />
        </Kit.FilterCol>

        <Kit.FilterCol>
          <Select
            placeholder="Section"
            onChange={onChangeSection}
            value={filter.values.section}
            options={sectionOptions}
          />
        </Kit.FilterCol>

        <Kit.FilterCol>
          <Select
            placeholder="Event"
            onChange={onChangeEvent}
            value={filter.values.event}
            options={eventOptions}
            disabled={!filter.values.section}
          />
        </Kit.FilterCol>

        <Kit.FilterCol>
          <Select
            placeholder="Auth status"
            onChange={onChangeAuthStatus}
            value={filter.values.authStatus}
            options={authStatusOptions}
          />
        </Kit.FilterCol>

        <Kit.FilterCol>
          <UsersAutoComplete
            placeholder={'Select user'}
            allowClear
            onChange={onChangeUser}
            value={filter.values.userId}
            disabled={filter.values.authStatus === AuthStatusEnum.Unauthorized}
          />
        </Kit.FilterCol>

        <Kit.FilterCol handler>
          <Button
            type="default"
            onClick={() =>
              getActions({
                ...filter.reset(),
                ...nav.initialValues,
                ...sorting.initialValues,
              })
            }
            className={cn('ant-reset-button')}
            icon={<ClearOutlined />}
            disabled={getActionsState.isFetching || filter.isInitial}
          >
            <span>Reset</span>
          </Button>{' '}
          <Button
            icon={<ReloadOutlined />}
            onClick={() =>
              getActions({
                ...filter.values,
                ...nav.values,
                ...sorting.values,
              })
            }
          >
            Refresh
          </Button>
        </Kit.FilterCol>
      </Kit.FilterGrid>

      <Kit.ErrorContainer error={getActionsState.error}>
        <Table
          dataSource={getActionsState.data?.list ?? []}
          columns={columns}
          rowKey={(row) => row.id}
          rowClassName={(row) => cx('row', { deleting: checkDeleting(row.id) })}
          onChange={(pagination, filters, sorter) => {
            setMemoryLimit(pagination.pageSize as number);

            getActions({
              ...filter.values,
              ...(sorting.isAntChanged(sorter)
                ? nav.initialValues
                : nav.fromAntReq(pagination)),
              ...sorting.fromAntReq(sorter),
            });
          }}
          pagination={{
            ...tablePaginationConfig,
            ...nav.toAntPagination(),
          }}
          loading={getActionsState.isFetching}
          scroll={{ x: '100%' }}
        />
      </Kit.ErrorContainer>
    </div>
  );
};
