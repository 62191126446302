import { combineReducers } from '@reduxjs/toolkit';

import {
  refreshAuth,
  loginUser,
  registerUser,
  getSessions,
  deleteSession,
  getUsers,
  getUsersList,
  getUserById,
  createUser,
  updateUser,
  deleteUser,
  getLocalesList,
  getProjects,
  getProjectsList,
  getProjectById,
  getProjectStatsById,
  createProject,
  updateProjectById,
  deleteProjectById,
  selectMessages,
  searchMessages,
  exportMessages,
  importMessages,
  getProjectLocaleKeyData,
  updateProjectLocaleKeyData,
  getProjectKeys,
  getUsingLocalesList,
  getActions,
  getActionById,
  deleteActionById,
  getActionNavigations,
  getProfile,
  updateProfile,
  changePassword,
  loadProfileImage,
  uploadImage,
  getProjectVersions,
  getProjectVersionsList,
  getProjectVersionById,
  getProjectVersionStatsById,
  createProjectVersion,
  updateProjectVersionById,
  deleteProjectVersionById,
  getMonitoringKeysUpdates,
  getMonitoringMessagesUpdates,
  getKeysUpdatesStats,
  getMessagesUpdatesStats,
  getProjectVersionSyncsStats,
  getActionsStats,
  getDashboardStats,
} from './slices';

export const serverReducer = combineReducers({
  refreshAuth: refreshAuth.reducer,
  loginUser: loginUser.reducer,
  registerUser: registerUser.reducer,
  getSessions: getSessions.reducer,
  deleteSession: deleteSession.reducer,
  getUsers: getUsers.reducer,
  getUsersList: getUsersList.reducer,
  getUserById: getUserById.reducer,
  createUser: createUser.reducer,
  updateUser: updateUser.reducer,
  deleteUser: deleteUser.reducer,
  getLocalesList: getLocalesList.reducer,
  getProjects: getProjects.reducer,
  getProjectsList: getProjectsList.reducer,
  getProjectById: getProjectById.reducer,
  getProjectStatsById: getProjectStatsById.reducer,
  createProject: createProject.reducer,
  updateProjectById: updateProjectById.reducer,
  deleteProjectById: deleteProjectById.reducer,
  selectMessages: selectMessages.reducer,
  searchMessages: searchMessages.reducer,
  exportMessages: exportMessages.reducer,
  importMessages: importMessages.reducer,
  getProjectLocaleKeyData: getProjectLocaleKeyData.reducer,
  updateProjectLocaleKeyData: updateProjectLocaleKeyData.reducer,
  getProjectKeys: getProjectKeys.reducer,
  getUsingLocalesList: getUsingLocalesList.reducer,
  getActions: getActions.reducer,
  getActionById: getActionById.reducer,
  deleteActionById: deleteActionById.reducer,
  getActionNavigations: getActionNavigations.reducer,
  getProfile: getProfile.reducer,
  updateProfile: updateProfile.reducer,
  changePassword: changePassword.reducer,
  loadProfileImage: loadProfileImage.reducer,
  uploadImage: uploadImage.reducer,
  getProjectVersions: getProjectVersions.reducer,
  getProjectVersionsList: getProjectVersionsList.reducer,
  getProjectVersionById: getProjectVersionById.reducer,
  getProjectVersionStatsById: getProjectVersionStatsById.reducer,
  createProjectVersion: createProjectVersion.reducer,
  updateProjectVersionById: updateProjectVersionById.reducer,
  deleteProjectVersionById: deleteProjectVersionById.reducer,
  getMonitoringKeysUpdates: getMonitoringKeysUpdates.reducer,
  getMonitoringMessagesUpdates: getMonitoringMessagesUpdates.reducer,
  getKeysUpdatesStats: getKeysUpdatesStats.reducer,
  getMessagesUpdatesStats: getMessagesUpdatesStats.reducer,
  getProjectVersionSyncsStats: getProjectVersionSyncsStats.reducer,
  getActionsStats: getActionsStats.reducer,
  getDashboardStats: getDashboardStats.reducer,
});

export const server = {
  refreshAuth,
  loginUser,
  registerUser,
  getSessions,
  deleteSession,
  getUsers,
  getUsersList,
  getUserById,
  createUser,
  updateUser,
  deleteUser,
  getLocalesList,
  getProjects,
  getProjectsList,
  getProjectById,
  getProjectStatsById,
  createProject,
  updateProjectById,
  deleteProjectById,
  selectMessages,
  searchMessages,
  exportMessages,
  importMessages,
  getProjectLocaleKeyData,
  updateProjectLocaleKeyData,
  getProjectKeys,
  getUsingLocalesList,
  getActions,
  getActionById,
  deleteActionById,
  getActionNavigations,
  getProfile,
  updateProfile,
  changePassword,
  loadProfileImage,
  uploadImage,
  getProjectVersions,
  getProjectVersionsList,
  getProjectVersionById,
  getProjectVersionStatsById,
  createProjectVersion,
  updateProjectVersionById,
  deleteProjectVersionById,
  getMonitoringKeysUpdates,
  getMonitoringMessagesUpdates,
  getKeysUpdatesStats,
  getMessagesUpdatesStats,
  getProjectVersionSyncsStats,
  getActionsStats,
  getDashboardStats,
};
