import React, { useEffect } from 'react';

import { Progress, Table } from 'antd';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { core } from 'core';
import { toNumber } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { ServerState, server } from 'store';
import { Kit } from 'ui/Kit';
import { MessageCountBadge } from 'ui/MessageCountBadge';
import { ProjectLocale } from 'ui/ProjectLocale';
import { roundPercent } from 'utils';

import { ProjectInfo } from '../../shared/ProjectInfo/ProjectInfo';
import { ProjectVersionInfo } from '../../shared/ProjectVersionInfo';
import { ProjectsMenu } from '../../shared/ProjectsMenu';

const cx = createCn('ProjectVersionInfoPage');

type Row = NonNullable<
  ServerState['getProjectVersionStatsById']['data']
>['list'][number];

export const ProjectVersionInfoPage = () => {
  const dispatch = useDispatch();
  const params = useParams<{ projectId: string; id: string }>();
  const projectId = toNumber(params.projectId);
  const id = toNumber(params.id);

  const getProjectByIdState = useSelector(server.getProjectById.selector.state);
  const getProjectVersionByIdState = useSelector(
    server.getProjectVersionById.selector.state,
  );

  const getProjectVersionStatsByIdState = useSelector(
    server.getProjectVersionStatsById.selector.state,
  );

  useEffect(() => {
    dispatch(
      server.getProjectById.thunk.request({ params: { id: projectId } }),
    );
    dispatch(
      server.getProjectVersionStatsById.thunk.request({ params: { id } }),
    );
    dispatch(server.getProjectVersionById.thunk.request({ params: { id } }));

    return () => {
      dispatch(server.getProjectById.action.reset());
      dispatch(server.getProjectVersionStatsById.action.reset());
      dispatch(server.getProjectVersionById.action.reset());
    };
  }, [dispatch, id, projectId]);

  return (
    <div className={cn(cx())}>
      <Kit.LoadContainer
        error={getProjectVersionStatsByIdState.error}
        isFetched={getProjectVersionStatsByIdState.isFetched}
        isFetching={getProjectVersionStatsByIdState.isFetching}
      >
        <ProjectsMenu />

        <Kit.Title>
          Project #{projectId} / version #{id} / info
        </Kit.Title>

        <ProjectInfo getProjectByIdState={getProjectByIdState} />

        <ProjectVersionInfo
          getProjectVersionByIdState={getProjectVersionByIdState}
        />

        <h2>Stats</h2>

        <Table
          dataSource={getProjectVersionStatsByIdState.data?.list}
          rowKey={(item) => item.locale.id}
          columns={[
            {
              key: 'locale',
              title: 'Locale',
              render: (row: Row) => (
                <>
                  <NavLink
                    exact={true}
                    activeClassName="active"
                    to={core.toPage(
                      `/manage/${projectId}/messages/${row.locale.ico}`,
                    )}
                  >
                    <ProjectLocale
                      ico={row.locale.ico}
                      name={row.locale.enName}
                    />
                  </NavLink>
                </>
              ),
              width: 120,
            },

            {
              title: 'Total messages',
              key: 'messagesAmount',
              render: (row: Row) => (
                <div>
                  <MessageCountBadge type="amount" count={row.messagesAmount} />
                </div>
              ),
              width: 100,
            },

            {
              title: 'Filled messages',
              key: 'filledMessages',
              render: (row: Row) => (
                <div>
                  <MessageCountBadge
                    type="filled"
                    count={row.filledMessagesAmount}
                  />
                </div>
              ),
              width: 100,
            },

            {
              title: 'Progress',
              key: 'progress',
              width: 360,
              render: (row: Row) => (
                <div className={cn(cx('progress'))}>
                  {row.messagesAmount > 0 && (
                    <Progress
                      strokeColor={{
                        '0%': '#ffd068',
                        '50%': '#87d068',
                        '100%': '#108ee9',
                      }}
                      percent={roundPercent(
                        row.filledMessagesAmount,
                        row.messagesAmount,
                      )}
                    />
                  )}
                </div>
              ),
            },
          ]}
          pagination={{
            hideOnSinglePage: true,
          }}
          loading={getProjectByIdState.isFetching}
        />
      </Kit.LoadContainer>
    </div>
  );
};
