import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetMonitoringKeysUpdatesQuery,
  GetMonitoringKeysUpdatesData,
  ServerApiError,
  serverApi,
  ServerApiConfig,
} from 'api/server';
import { ReduxState } from 'store';

import {
  MergeRequestState,
  getInitialRequestState,
  getFetchedRequestState,
  getFetchingRequestState,
} from '../serverUtils';

export interface GetMonitoringKeysUpdatesState
  extends MergeRequestState<GetMonitoringKeysUpdatesData, ServerApiError> {}

const selector = {
  state: (state: ReduxState) => state.server.getMonitoringKeysUpdates,
  isFetching: (state: ReduxState) =>
    state.server.getMonitoringKeysUpdates.isFetching,
  data: (state: ReduxState) => state.server.getMonitoringKeysUpdates.data,
  error: (state: ReduxState) => state.server.getMonitoringKeysUpdates.error,
};

const initialState: GetMonitoringKeysUpdatesState = {
  ...getInitialRequestState(),
  error: null,
  data: null,
};

const SLICE_NAME = '@server/getMonitoringKeysUpdates';

const requestThunk = createAsyncThunk(
  `${SLICE_NAME}/request`,
  (
    {
      query,
      config,
    }: { query: GetMonitoringKeysUpdatesQuery; config?: ServerApiConfig },
    { rejectWithValue },
  ) => serverApi.getMonitoringKeysUpdates(query, config).catch(rejectWithValue),
  {
    condition: (payload, { getState }) =>
      !selector.isFetching(getState() as ReduxState),
  },
);

const { actions, reducer } = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(requestThunk.pending, (state) => {
      Object.assign(state, getFetchingRequestState());
    });
    builder.addCase(requestThunk.fulfilled, (state, action) => {
      Object.assign(state, getFetchedRequestState());
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(requestThunk.rejected, (state, action) => {
      Object.assign(state, initialState);
      state.error = action.payload as ServerApiError;
    });
  },
});

interface ServerGetMonitoringKeysUpdates {
  action: typeof actions;
  thunk: {
    request: typeof requestThunk;
  };
  reducer: typeof reducer;
  selector: typeof selector;
}

export const getMonitoringKeysUpdates: ServerGetMonitoringKeysUpdates = {
  action: actions,
  thunk: {
    request: requestThunk,
  },
  reducer,
  selector,
};
