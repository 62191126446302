import React from 'react';

import { core } from 'core';
import { Switch, Route } from 'react-router-dom';

import { HomePage } from './pages/HomePage';
import { IndexPage } from './pages/IndexPage';

export const ContentModule = () => {
  return (
    <Switch>
      <Route path={core.toPage('home')} exact>
        <HomePage />
      </Route>

      <Route path="/" exact>
        <IndexPage />
      </Route>
    </Switch>
  );
};
