import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetActionsQuery,
  GetActionsData,
  ServerApiError,
  serverApi,
  ServerApiConfig,
} from 'api/server';
import { ReduxState } from 'store';

import {
  MergeRequestState,
  getInitialRequestState,
  getFetchedRequestState,
  getFetchingRequestState,
} from '../serverUtils';

export interface GetActionsState
  extends MergeRequestState<GetActionsData, ServerApiError> {}

const selector = {
  state: (state: ReduxState) => state.server.getActions,
  isFetching: (state: ReduxState) => state.server.getActions.isFetching,
  data: (state: ReduxState) => state.server.getActions.data,
  error: (state: ReduxState) => state.server.getActions.error,
};

const initialState: GetActionsState = {
  ...getInitialRequestState(),
  error: null,
  data: null,
};

const SLICE_NAME = '@server/getActions';

const requestThunk = createAsyncThunk(
  `${SLICE_NAME}/request`,
  (
    { query, config }: { query: GetActionsQuery; config?: ServerApiConfig },
    { rejectWithValue },
  ) => serverApi.getActions(query, config).catch(rejectWithValue),
  {
    condition: (payload, { getState }) =>
      !selector.isFetching(getState() as ReduxState),
  },
);

const { actions, reducer } = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(requestThunk.pending, (state) => {
      Object.assign(state, getFetchingRequestState());
    });
    builder.addCase(requestThunk.fulfilled, (state, action) => {
      Object.assign(state, getFetchedRequestState());
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(requestThunk.rejected, (state, action) => {
      Object.assign(state, initialState);
      state.error = action.payload as ServerApiError;
    });
  },
});

interface ServerGetActions {
  action: typeof actions;
  thunk: {
    request: typeof requestThunk;
  };
  reducer: typeof reducer;
  selector: typeof selector;
}

export const getActions: ServerGetActions = {
  action: actions,
  thunk: {
    request: requestThunk,
  },
  reducer,
  selector,
};
