import { useLocalStorage } from 'react-use';

export const useUiMemory = <T>(
  element: string,
  defaultValue: T,
): [T, (value: T) => void] => {
  const [uiElements, setUiElements] = useLocalStorage<{
    [element: string]: T;
  }>(`ui`, {});

  const value: T = (uiElements && uiElements[element]) ?? defaultValue;

  const setValue = (value: T): void => {
    setUiElements({
      ...uiElements,
      [element]: value,
    });
  };

  return [value, setValue];
};
