import { useCallback, useState } from 'react';

type Await<T> = T extends {
  then(onfulfilled?: (value: infer U) => unknown): unknown;
}
  ? U
  : T;

export interface useRequestState<Data> {
  isFetching: boolean;
  isFetched: boolean;
  fetchedTime: null | number;
  data: null | Data;
  error: null | any;
}

const initialValues = {
  isFetching: false,
  isFetched: false,
  fetchedTime: null,
  error: null,
  data: null,
};

export const useRequest = <
  ArgumentsType extends (...args: any[]) => Promise<any>
>(
  functionToCall: ArgumentsType,
): {
  request: (
    ...args: Parameters<ArgumentsType>
  ) => Promise<useRequestState<Await<ReturnType<ArgumentsType>>>['data']>;
  reset: () => Promise<void>;
  state: useRequestState<Await<ReturnType<ArgumentsType>>>;
} => {
  const [state, setState] = useState<
    useRequestState<Await<ReturnType<ArgumentsType>>>
  >(initialValues);
  const [isReset, setIsReset] = useState<boolean>();

  const request = useCallback(
    async (...args: Parameters<ArgumentsType>) => {
      setState({
        ...state,
        isFetching: true,
      });
      setIsReset(false);

      let data: useRequestState<
        Await<ReturnType<ArgumentsType>>
      >['data'] = null;

      try {
        data = await functionToCall(...args);
        if (!isReset) {
          setState({
            isFetching: false,
            isFetched: true,
            fetchedTime: Date.now(),
            data,
            error: null,
          });
        }
      } catch (error) {
        if (!isReset) {
          setState({
            ...initialValues,
            error: {
              code: error?.code,
              status: error?.response?.status,
              statusText: error?.response?.statusText || 'Unknown error',
              data: error?.response?.data || {},
            },
          });
        }
      }

      return data;
    },
    [functionToCall, isReset, state],
  );
  const reset = useCallback(async () => {
    setState(initialValues);
    setIsReset(true);
  }, []);
  return { request, state, reset };
};
