import React from 'react';

import { faCodeBranch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import Color from 'color';
import { mean } from 'lodash-es';
import stc from 'string-to-color';

import './ProjectVersionColor.scss';

const cx = createCn('ProjectVersionColor');

interface Props {
  className?: string;
  param: string;
  isDeprecated?: boolean;
  title?: string;
  small?: boolean;
}

export const ProjectVersionColor: React.FC<Props> = ({
  className,
  param,
  isDeprecated = false,
  title,
  small = false,
}) => {
  const isFeature = param === 'feature';

  let colorInst = Color(stc(param));

  if (isFeature) {
    colorInst = Color('#1dc583');
  } else {
    let { r, g, b } = colorInst.object();

    const meanColors = mean([r, g, b]);

    if (meanColors > 192) {
      r = Math.max(0, r - 64);
      g = Math.max(0, g - 64);
      b = Math.max(0, b - 64);
    }

    if (meanColors < 64) {
      r = Math.min(255, r + 64);
      g = Math.min(255, g + 64);
      b = Math.min(255, b + 64);
    }

    if (Math.max(r, g, b) - Math.min(r, g, b) < 64) {
      r = Math.max(0, r - 32);
      g = Math.min(255, g + 32);
      b = Math.min(255, b + 32);
    }

    if (r - 64 > (g + b) / 2) {
      r = Math.max(0, r - 92);
    }

    if (g - 64 > (r + b) / 2) {
      g = Math.max(0, g - 64);
    }

    colorInst = Color({ r, g, b }).saturate(0.5);
  }

  const backgroundColor = colorInst
    .mix(Color('#cccccc'), 0.72)
    .saturate(0.72)
    .lighten(0.72)
    .hex();

  const color = colorInst
    .mix(Color('#555'), 0.25)
    .darken(0.25)
    .saturate(0.5)
    .hex();

  const borderColor = colorInst
    .mix(Color('#eeeeee'), 0.45)
    .saturate(0.25)
    .darken(0.25)
    .hex();

  return (
    <Tooltip placement="top" title={title}>
      <div
        className={cn(className, cx({ isDeprecated, small: small }))}
        style={{
          color,
          border: `1px solid ${borderColor}`,
          backgroundColor,
        }}
      >
        <div
          className={cx('iconCol')}
          style={
            small
              ? { color: borderColor }
              : {
                  backgroundColor: borderColor,
                }
          }
        >
          <FontAwesomeIcon icon={faCodeBranch} />
        </div>
        <div className={cx('nameCol')}>
          <div className={cx('param')}>{param}</div>
        </div>
      </div>
    </Tooltip>
  );
};
