import { ErrorTypeEnum, serverApi, PermissionDto } from 'api/server';
import * as jwt from 'jsonwebtoken';
import * as ls from 'local-storage';
import { get, isObject } from 'lodash-es';
import { AppThunk, server, app } from 'store';
import { JwtAuthTokenData, JwtAuth } from 'types';
import { toasts } from 'ui/toasts';
import { getFingerprint, rolePermissionFromArray } from 'utils';

const ACCESS_TOKEN_PARAM = 'auth:accessToken';
const REFRESH_TOKEN_PARAM = 'auth:refreshToken';
const ROLE_PERMISSIONS_LIST_PARAM = 'auth:rolePermissionsList';
const REMEMBER = 'auth:remember';

const decodeAuthToken = (accessToken: string): null | JwtAuthTokenData => {
  try {
    return jwt.decode(accessToken) as JwtAuthTokenData | null;
  } catch {
    return null;
  }
};

const validateAuthAccess = (authData: JwtAuthTokenData | null): boolean => {
  return isObject(authData) && authData.exp > Date.now() / 1000;
};

export const initAuthThunk = (): AppThunk => async (dispatch, getState) => {
  const accessToken = ls.get<string | null>(ACCESS_TOKEN_PARAM);
  const refreshToken = ls.get<string | null>(REFRESH_TOKEN_PARAM);
  const rolePermissionsList = ls.get<PermissionDto[] | null>(
    ROLE_PERMISSIONS_LIST_PARAM,
  );
  const remember = ls.get<boolean | null>(REMEMBER) || false;

  let isValidAccessToken: boolean = false;
  let authData: JwtAuthTokenData | null = null;

  if (accessToken && refreshToken) {
    authData = decodeAuthToken(accessToken);
    isValidAccessToken = validateAuthAccess(authData);
  }

  if (!isValidAccessToken && refreshToken && remember) {
    await dispatch(refreshTokenThunk(refreshToken));
  } else if (
    isValidAccessToken &&
    refreshToken &&
    accessToken &&
    rolePermissionsList
  ) {
    await dispatch(
      identifyThunk(authData as JwtAuthTokenData, {
        accessToken,
        refreshToken,
        rolePermissionsList,
      }),
    );
  } else {
    await dispatch(anonymizeThunk());
  }
};

export const checkAuthThunk = (): AppThunk => async (dispatch, getState) => {
  const authState = app.auth.selector.state(getState());

  const leftExp = (authState?.data?.exp || 0) - Date.now() / 1000;

  if (authState.isLogged && leftExp < 60 * 5) {
    const refreshToken = ls.get<string | null>(REFRESH_TOKEN_PARAM);

    if (refreshToken) {
      await dispatch(refreshTokenThunk(refreshToken));
    } else {
      await dispatch(anonymizeThunk());

      toasts.failure({
        title: `Authorization error`,
        message: `Refresh token is missing`,
      });
    }
  }
};

export const loginThunk = ({
  email,
  password,
  remember = false,
}: {
  email: string;
  password: string;
  remember: boolean;
}): AppThunk => async (dispatch, getState) => {
  const fingerprint = await getFingerprint();

  await dispatch(
    server.loginUser.thunk.request({
      body: {
        fingerprint,
        email,
        password,
      },
    }),
  );

  const loginUserState = server.loginUser.selector.state(getState());

  if (loginUserState.error) {
    toasts.failure({
      message: loginUserState.error.data.message,
    });
    dispatch(anonymizeThunk());
  } else {
    if (loginUserState.data) {
      ls.set(REMEMBER, remember);
      await dispatch(handleAuthThunk(loginUserState.data));
    }
  }
};

export const refreshTokenThunk = (refreshToken: string): AppThunk => async (
  dispatch,
  getState,
) => {
  const fingerprint = await getFingerprint();

  await dispatch(
    server.refreshAuth.thunk.request({
      body: {
        refreshToken,
        fingerprint,
      },
    }),
  );

  const refreshAuthState = server.refreshAuth.selector.state(getState());

  if (refreshAuthState.error) {
    toasts.failure({
      message: refreshAuthState.error.data.message,
    });
    dispatch(anonymizeThunk());
  } else {
    if (refreshAuthState.data) {
      await dispatch(handleAuthThunk(refreshAuthState.data));
    }
  }
};

export const refreshAuthThunk = (): AppThunk => async (dispatch, getState) => {
  const refreshToken = ls.get<string | null>(REFRESH_TOKEN_PARAM);

  if (!refreshToken) {
    await dispatch(anonymizeThunk());
    return null;
  }

  await dispatch(refreshTokenThunk(refreshToken));
};

type IdentificationData = JwtAuth & {
  rolePermissionsList: PermissionDto[];
};

export const handleAuthThunk = ({
  accessToken,
  refreshToken,
  rolePermissionsList,
}: IdentificationData): AppThunk => async (dispatch, getState) => {
  const authData = decodeAuthToken(accessToken);
  const isValidAccessToken = validateAuthAccess(authData);

  if (isValidAccessToken) {
    dispatch(
      identifyThunk(authData as JwtAuthTokenData, {
        accessToken,
        refreshToken,
        rolePermissionsList,
      }),
    );
  } else {
    toasts.failure({
      title: `Authorization error`,
      message: 'Access token expires is wrong',
    });

    dispatch(anonymizeThunk());
  }
};

let serverApiInterceptor: number;

export const anonymizeThunk = (): AppThunk => async (dispatch, getState) => {
  ls.remove(ACCESS_TOKEN_PARAM);
  ls.remove(REFRESH_TOKEN_PARAM);
  ls.remove(ROLE_PERMISSIONS_LIST_PARAM);
  ls.remove(REMEMBER);

  dispatch(app.auth.action.anonymize());
  delete serverApi.axios().defaults.headers['Authorization'];

  if (serverApiInterceptor) {
    serverApi.axios().interceptors.response.eject(serverApiInterceptor);
  }
};

export const identifyThunk = (
  authData: JwtAuthTokenData,
  { accessToken, refreshToken, rolePermissionsList }: IdentificationData,
): AppThunk => async (dispatch, getState) => {
  try {
    ls.set(ACCESS_TOKEN_PARAM, accessToken);
    ls.set(REFRESH_TOKEN_PARAM, refreshToken);
    ls.set(ROLE_PERMISSIONS_LIST_PARAM, rolePermissionsList);

    serverApi.axios().defaults.headers[
      'Authorization'
    ] = `Bearer ${accessToken}`;

    serverApiInterceptor = serverApi.axios().interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const errorType = get(error, 'response.data.type');

        if (
          [ErrorTypeEnum.InvalidToken, ErrorTypeEnum.InvalidSession].includes(
            errorType,
          )
        ) {
          dispatch(anonymizeThunk());
        }
        return Promise.reject(error);
      },
    );

    await dispatch(app.auth.action.identify(authData));
    await dispatch(
      app.rolePermissions.action.set(
        rolePermissionFromArray(rolePermissionsList),
      ),
    );
  } catch {
    dispatch(anonymizeThunk());
  }
};
