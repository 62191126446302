import React from 'react';

import { Badge, Checkbox, Form } from 'antd';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { xor } from 'lodash-es';

import './KitFormCheckList.scss';

interface Props {
  className?: string;
  disabled?: boolean;
  name: string;
  initialValues?: number[];
  list: {
    value: number;
    label: any;
  }[];
  checkAll?: (values: number[]) => void;
}

const cx = createCn('KitFormCheckList');

export const KitFormCheckList: React.FC<Props> = ({
  className,
  name,
  list,
  checkAll,
  initialValues = [],
}) => {
  const allValues = list.map((item) => item.value);

  const [allValuesInded, setAllValuesInded] = React.useState<
    boolean | undefined
  >(initialValues.length > 0 && initialValues.length !== allValues.length);
  const [allValuesChecked, setAllValuesChecked] = React.useState<boolean>(
    initialValues.length > 0 && initialValues.length === allValues.length,
  );
  const [values, setValues] = React.useState<number[]>(initialValues);

  const onAllValuesChecked = (event: any) => {
    if (allValuesInded || !allValuesChecked) {
      setAllValuesInded(false);
      setAllValuesChecked(true);
      setValues(allValues);
      checkAll && checkAll(allValues);
    } else {
      setAllValuesInded(false);
      setAllValuesChecked(false);
      setValues([]);
      checkAll && checkAll([]);
    }
  };

  const onCheckValues = (event: any) => {
    const nextValues = xor(values, [event.target.value]);
    setValues(nextValues);

    if (nextValues.length === 0) {
      setAllValuesInded(false);
      setAllValuesChecked(false);
    } else if (nextValues.length === allValues.length) {
      setAllValuesInded(false);
      setAllValuesChecked(true);
    } else {
      setAllValuesInded(true);
      setAllValuesChecked(true);
    }
  };

  return (
    <div className={cn(className, cx())}>
      <div className={cx('checkAll')}>
        <Checkbox
          indeterminate={allValuesInded}
          onChange={onAllValuesChecked}
          checked={allValuesChecked}
        >
          <div className={cx('checkAllLabel')}>
            <div className={cx('checkAllLabelTitle')}>Check all</div>
            <div>
              <Badge count={values.length} showZero className="badge-count-1" />
            </div>
          </div>
        </Checkbox>
      </div>

      <ul className={cx('list')}>
        <Form.Item name={name} className={cx('formItem')}>
          <Checkbox.Group style={{ width: '100%' }} value={values}>
            {list.map((item) => (
              <li className={cx('item')} key={item.value}>
                <Checkbox value={item.value} onChange={onCheckValues}>
                  {item.label}
                </Checkbox>
              </li>
            ))}
          </Checkbox.Group>
        </Form.Item>
      </ul>
    </div>
  );
};
