import React, { useEffect } from 'react';

import { Empty } from 'antd';
import { OutsideLayout } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { app } from 'store';
import { initAuthThunk, checkAuthThunk } from 'thunks';

export type JwtAuth = {
  accessToken: string;
  refreshToken: string;
};

interface Props {
  children: React.ReactNode;
}

export const Auth: React.FC<Props> = ({ children }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const authState = useSelector(app.auth.selector.state);
  const rolePermissionsState = useSelector(app.rolePermissions.selector.state);

  useEffect(() => {
    dispatch(initAuthThunk());

    const interval = setInterval(() => {
      dispatch(checkAuthThunk());
    }, 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  const isOut = ['/auth/login', '/auth/register'].includes(pathname);

  if (
    // prevIsLogged === true &&
    authState.isLogged === false &&
    authState.isChecked &&
    !isOut
  ) {
    return <Redirect to="/auth/login" />;
  }

  if (
    !authState.isChecked ||
    (authState.isLogged && !rolePermissionsState.isInit)
  ) {
    return (
      <OutsideLayout>
        <Empty description="Loading..." />
      </OutsideLayout>
    );
  }

  return <>{children}</>;
};
