import React from 'react';

import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { core } from 'core';
import { Kit } from 'ui/Kit';

interface Props {}

export const MonitoringMenu: React.FC<Props> = () => {
  return (
    <Kit.NavMenu>
      <Kit.NavItem
        to={core.toPage('/keys')}
        icon={faNewspaper}
        text={'Keys updates'}
      />
      <Kit.NavItem
        to={core.toPage('/messages')}
        icon={faNewspaper}
        text={'Messages updates'}
      />
    </Kit.NavMenu>
  );
};
