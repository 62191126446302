import { KitAction } from './KitAction';
import { KitActions } from './KitActions';
import { KitAutoComplete } from './KitAutoComplete';
import { KitErrorContainer } from './KitErrorContainer';
import { KitFilterCol } from './KitFilterCol';
import { KitFilterGrid } from './KitFilterGrid';
import { KitFormContainer } from './KitFormContainer';
import { KitItemAction } from './KitItemAction';
import { KitLoadContainer } from './KitLoadContainer';
import { KitMenuActions } from './KitMenuActions';
import { KitNavItem } from './KitNavItem';
import { KitNavMenu } from './KitNavMenu';
import { KitTitle } from './KitTitle';
import { KitTitleActions } from './KitTitleActions';

export const Kit = {
  Title: KitTitle,
  TitleActions: KitTitleActions,
  LoadContainer: KitLoadContainer,
  FormContainer: KitFormContainer,
  Actions: KitActions,
  Action: KitAction,
  ErrorContainer: KitErrorContainer,
  FilterGrid: KitFilterGrid,
  FilterCol: KitFilterCol,
  NavMenu: KitNavMenu,
  NavItem: KitNavItem,
  AutoComplete: KitAutoComplete,
  ItemAction: KitItemAction,
  MenuActions: KitMenuActions,
};
