import React, { useCallback } from 'react';

import { Table } from 'antd';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { useActive } from 'hooks';
import { get } from 'lodash-es';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useLifecycles } from 'react-use';
import { server, app, ServerState } from 'store';
import { deleteAuthSessionThunk } from 'thunks';
import { Kit } from 'ui/Kit';
import { tablePaginationConfig } from 'utils';

import { ProfileMenu } from '../../shared/ProfileMenu';

import './ProfileAuthSessionsPage.scss';

const cx = createCn('ProfileAuthSessionsPage');

type Row = NonNullable<ServerState['getSessions']['data']>['list'][number];

interface Props {}

export const ProfileAuthSessionsPage: React.FC<Props> = () => {
  const dispatch = useDispatch();

  useLifecycles(
    () => {
      dispatch(server.getSessions.thunk.request());
    },
    () => {
      dispatch(server.deleteSession.action.reset());
      dispatch(server.getSessions.action.reset());
    },
  );

  const getSessionsState = useSelector(server.getSessions.selector.state);
  const deleteSessionState = useSelector(server.deleteSession.selector.state);
  const authState = useSelector(app.auth.selector.state);
  const currentSessionKey = get(authState, 'data.session.key');

  const [setDeleting, checkDeleting] = useActive<string>();

  const deleteAuthSession = useCallback(
    async (key: string) => {
      setDeleting(key);
      try {
        await dispatch(
          deleteAuthSessionThunk({ key, title: 'Delete session' }),
        );
        await dispatch(server.getSessions.thunk.request());
      } catch {
      } finally {
        setDeleting(null);
      }
    },
    [dispatch, setDeleting],
  );

  const columns = [
    {
      title: 'IP address',
      key: 'ip',
      render: (row: Row) => <div>{row.ip}</div>,
      width: 120,
    },
    {
      title: 'User Agent',
      key: 'userAgent',
      render: (row: Row) => <div>{row.userAgent}</div>,
      width: 240,
    },
    {
      title: 'Logged',
      key: 'loggedAt',
      render: (row: Row) => (
        <div style={{ minWidth: 100 }}>
          <div>{moment(row.loggedAt).format('YYYY-MM-DD')}</div>
          <div>{moment(row.loggedAt).format('HH:mm:ss')}</div>
        </div>
      ),
      width: 120,
    },
    {
      title: 'Refreshed',
      key: 'refreshedAt',
      render: (row: Row) => (
        <div style={{ minWidth: 100 }}>
          <div>{moment(row.refreshedAt).format('YYYY-MM-DD')}</div>{' '}
          <div>{moment(row.refreshedAt).format('HH:mm:ss')}</div>{' '}
        </div>
      ),
      width: 120,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (row: Row) => (
        <Kit.Action
          type={'delete'}
          disabled={
            deleteSessionState.isFetching || row.key === currentSessionKey
          }
          onClick={() => deleteAuthSession(row.key)}
          title={`Delete session`}
        />
      ),
      align: 'right' as 'right',
      width: 50,
    },
  ];

  return (
    <div className={cn(cx())}>
      <ProfileMenu />

      <Kit.Title>Auth sessions</Kit.Title>
      <Kit.ErrorContainer error={getSessionsState.error}>
        <Table
          dataSource={getSessionsState.data?.list ?? []}
          columns={columns}
          rowKey={(row) => row.key}
          rowClassName={(row) =>
            cx('row', { deleting: checkDeleting(row.key) })
          }
          pagination={{
            ...tablePaginationConfig,
            hideOnSinglePage: false,
          }}
          loading={getSessionsState.isFetching}
        />
      </Kit.ErrorContainer>
    </div>
  );
};
