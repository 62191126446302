import React from 'react';

import { core } from 'core';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AddProjectPage } from './pages/AddProjectPage';
import { AddProjectVersionPage } from './pages/AddProjectVersionPage';
import { EditProjectPage } from './pages/EditProjectPage';
import { EditProjectVersionPage } from './pages/EditProjectVersionPage';
import { ManageMessagesPage } from './pages/ManageMessagesPage';
import { ProjectInfoPage } from './pages/ProjectInfoPage';
import { ProjectMessagesPage } from './pages/ProjectMessagesPage';
import { ProjectVersionInfoPage } from './pages/ProjectVersionInfoPage';
import { ProjectVersionsListPage } from './pages/ProjectVersionsListPage';
import { ProjectsListPage } from './pages/ProjectsListPage';

export const ProjectsModule = () => {
  return (
    <Switch>
      <Route path={core.toPage('/list')} exact>
        <ProjectsListPage />
      </Route>
      <Route path={core.toPage('/add')} exact>
        <AddProjectPage />
      </Route>
      <Route path={core.toPage('/edit/:id(\\d+)')} exact>
        <EditProjectPage />
      </Route>
      <Route path={core.toPage('/info/:id(\\d+)')} exact>
        <ProjectInfoPage />
      </Route>
      <Route path={core.toPage('/manage/messages')} exact>
        <ProjectMessagesPage />
      </Route>
      <Route path={core.toPage('/manage/:id(\\d+)/messages/:ico')} exact>
        <ManageMessagesPage />
      </Route>
      <Route path={core.toPage('/manage/:id(\\d+)/versions')} exact>
        <ProjectVersionsListPage />
      </Route>
      <Route path={core.toPage('/manage/:projectId(\\d+)/versions/add')} exact>
        <AddProjectVersionPage />
      </Route>
      <Route
        path={core.toPage('/manage/:projectId(\\d+)/versions/edit/:id(\\d+)')}
        exact
      >
        <EditProjectVersionPage />
      </Route>
      <Route
        path={core.toPage('/manage/:projectId(\\d+)/versions/info/:id(\\d+)')}
        exact
      >
        <ProjectVersionInfoPage />
      </Route>

      <Redirect to="/404" />
    </Switch>
  );
};
