import { PermissionDto, UserRoleEnum } from 'api/server';

const roles = [UserRoleEnum.User, UserRoleEnum.Moderator, UserRoleEnum.Admin];

export const roleAccess = (
  requiredRole: UserRoleEnum,
  role?: UserRoleEnum,
): boolean => {
  const roleIndex = role ? roles.indexOf(role) : -1;
  const requiredRoleIndex = roles.indexOf(requiredRole);
  return roleIndex >= requiredRoleIndex;
};

export const getAccessibleRoles = (role?: UserRoleEnum): UserRoleEnum[] => {
  if (role === UserRoleEnum.Admin) {
    return roles;
  }
  const roleIndex = role ? roles.indexOf(role) : -1;
  return roles.filter((role, index) => index < roleIndex);
};

export const hasAccessibleRoles = (
  requiredRole: UserRoleEnum,
  role?: UserRoleEnum,
): boolean => {
  return getAccessibleRoles(role).includes(requiredRole);
};

export type RolePermissions = {
  [permission in PermissionDto['permission']]: UserRoleEnum;
};

export const rolePermissionFromArray = (
  rolePermissionsList: PermissionDto[],
): RolePermissions =>
  rolePermissionsList.reduce((obj, rolePermission) => {
    obj[rolePermission.permission] = rolePermission.role;
    return obj;
  }, {} as RolePermissions);
