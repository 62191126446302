import { notification } from 'antd';
import { formErrorUtil } from 'utils';

interface CommonToast {
  title?: string | React.ReactNode;
  message?: string | React.ReactNode;
}

class Toasts {
  public failure({ title = '', message = 'Failure' }: CommonToast): void {
    notification['error']({
      message: title,
      description: message,
    });
  }

  public success({ title = '', message = 'Succesfully' }: CommonToast): void {
    notification['success']({
      message: title,
      description: message,
    });
  }

  public info({ title = '', message = '' }: CommonToast): void {
    notification['info']({
      message: title,
      description: message,
    });
  }

  public result({
    title = '',
    error = null,
    successMessage,
    exceptParams,
  }: {
    title: CommonToast['title'];
    successMessage?: string;
    error: any;
    exceptParams?: string[];
  }): void {
    if (error) {
      const message = formErrorUtil(error, exceptParams);

      if (message) {
        this.failure({
          title,
          message,
        });
      }
    } else {
      this.success({
        title,
        message: successMessage,
      });
    }
  }
}

export const toasts = new Toasts();
