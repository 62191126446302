import React from 'react';

import { createCn } from 'bem-react-classname';
import moment from 'moment';

import './VersionMessageCreatedAt.scss';

const cx = createCn('VersionMessageCreatedAt');

interface Props {
  createdAt: string | null;
}

export const VersionMessageCreatedAt: React.FC<Props> = ({ createdAt }) => {
  return (
    <div className={cx()}>
      {createdAt && (
        <>
          <div className={cx('monthDay')}>
            {moment(createdAt).format('MMM DD')}
          </div>

          <div className={cx('year')}>{moment(createdAt).format('YYYY')}</div>
        </>
      )}
    </div>
  );
};
