import React from 'react';

import { core } from 'core';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ActionsStatsPage } from './pages/ActionsStatsPage';
import { HomeDashboardPage } from './pages/HomeDashboardPage';
import { KeysUpdatesStatsPage } from './pages/KeysUpdatesStatsPage';
import { MessagesUpdatesStatsPage } from './pages/MessagesUpdatesStatsPage';
import { ProjectVersionSyncsStatsPage } from './pages/ProjectVersionSyncsStatsPage';

export const DashboardModule = () => {
  return (
    <Switch>
      <Route path={core.toPage('/')} exact>
        <HomeDashboardPage />
      </Route>

      <Route path={core.toPage('/keysUpdates')} exact>
        <KeysUpdatesStatsPage />
      </Route>

      <Route path={core.toPage('/translations')} exact>
        <MessagesUpdatesStatsPage />
      </Route>

      <Route path={core.toPage('/synchronizations')} exact>
        <ProjectVersionSyncsStatsPage />
      </Route>

      <Route path={core.toPage('/userActions')} exact>
        <ActionsStatsPage />
      </Route>

      <Redirect to="/404" />
    </Switch>
  );
};
