import { useState } from 'react';

export const useStateDebounce = (): [
  (fx: () => any, delay?: number) => void,
  () => void,
] => {
  const [timeoutTimer, setTimeoutTimer] = useState<number | undefined>();

  const stateDebounce = (
    fx: () => any | Promise<any>,
    delay?: number,
  ): void => {
    clearTimeout(timeoutTimer);

    setTimeoutTimer(
      setTimeout(() => {
        fx();
      }, delay),
    );
  };

  const clearStateDebounce = (): void => {
    clearTimeout(timeoutTimer);
  };

  return [stateDebounce, clearStateDebounce];
};
