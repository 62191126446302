import React from 'react';

import { core } from 'core';
import { Switch, Route, Redirect } from 'react-router-dom';

import { LoginPage } from './pages/LoginPage';
import { RegisterPage } from './pages/RegisterPage/RegisterPage';

export const AuthModule = () => {
  return (
    <Switch>
      <Route path={core.toPage('/login')} exact>
        <LoginPage />
      </Route>

      <Route path={core.toPage('/register')} exact>
        <RegisterPage />
      </Route>

      <Redirect to="/404" />
    </Switch>
  );
};
