import React from 'react';

import { faList } from '@fortawesome/free-solid-svg-icons';
import { core } from 'core';
import { Kit } from 'ui/Kit';

interface Props {}

export const ActionsMenu: React.FC<Props> = () => {
  return (
    <Kit.NavMenu>
      <Kit.NavItem
        to={core.toPage('/list')}
        icon={faList}
        text={'Actions list'}
      />
    </Kit.NavMenu>
  );
};
