import React from 'react';

import { createCn } from 'bem-react-classname';
import cn from 'classnames';

import './KitNavMenu.scss';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

const cx = createCn('KitNavMenu');

export const KitNavMenu: React.FC<Props> = ({ children, className }) => {
  return (
    <div className={cn(className, cx())}>
      <ul className={cx('list')}>
        <>{children}</>
      </ul>
    </div>
  );
};
