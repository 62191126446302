import React, { useCallback } from 'react';

import { Form, Input, Button } from 'antd';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { useFormError } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useLifecycles } from 'react-use';
import { server, AppThunk } from 'store';
import { refreshAuthThunk } from 'thunks';
import { Kit } from 'ui/Kit';
import { toasts } from 'ui/toasts';

import { ProfileMenu } from '../../shared/ProfileMenu';

import './ProfilePersonalDataPage.scss';

const cx = createCn('ProfilePersonalDataPage');

interface Props {}

export const ProfilePersonalDataPage: React.FC = () => {
  const dispatch = useDispatch();

  useLifecycles(
    () => {
      dispatch(server.getProfile.thunk.request());
    },
    () => {
      dispatch(server.updateProfile.action.reset());
      dispatch(server.getProfile.action.reset());
    },
  );

  const getProfileState = useSelector(server.getProfile.selector.state);
  const updateProfileState = useSelector(server.updateProfile.selector.state);
  const formError = useFormError(updateProfileState.error);

  const disabled = getProfileState.isFetching || updateProfileState.isFetching;

  const initialValues = {
    firstName: getProfileState.data?.firstName || '',
    lastName: getProfileState.data?.lastName || '',
  };

  const onFinish = useCallback(
    (values) => {
      dispatch(
        ((): AppThunk => async (dispatch, getState) => {
          await dispatch(
            server.updateProfile.thunk.request({
              body: values,
            }),
          );
          const { error } = server.updateProfile.selector.state(getState());

          toasts.result({
            title: 'Profile updating',
            error,
          });

          if (!error) {
            await dispatch(server.getProfile.thunk.request());
            await dispatch(refreshAuthThunk());
          }
        })(),
      );
    },
    [dispatch],
  );

  return (
    <div className={cn(cx())}>
      <ProfileMenu />
      <Kit.Title>Personal data</Kit.Title>
      <Kit.LoadContainer
        isFetching={getProfileState.isFetching}
        isFetched={getProfileState.isFetched}
        error={getProfileState.error}
      >
        <Kit.FormContainer>
          <Form
            name="form"
            className={cn(cx('form'))}
            initialValues={initialValues}
            onFinish={onFinish}
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
          >
            <Form.Item label="ID" labelAlign={'left'}>
              <div>{getProfileState.data?.id}</div>
            </Form.Item>

            <Form.Item label="Role" labelAlign={'left'}>
              <div>{getProfileState.data?.role}</div>
            </Form.Item>

            <Form.Item label="Email" labelAlign={'left'}>
              <div>{getProfileState.data?.email}</div>
            </Form.Item>

            <Form.Item
              name="firstName"
              validateStatus={formError.status('firstName')}
              help={formError.note('firstName')}
              label="First name"
              labelAlign={'left'}
            >
              <Input size={'middle'} disabled={disabled} />
            </Form.Item>

            <Form.Item
              name="lastName"
              validateStatus={formError.status('lastName')}
              label="Last name"
              labelAlign={'left'}
            >
              <Input size={'middle'} disabled={disabled} />
            </Form.Item>

            <Form.Item
              validateStatus={formError.status()}
              help={formError.note() && formError.emptyDetails()}
              wrapperCol={{ offset: 12, span: 12 }}
            >
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size={'large'}
                disabled={disabled}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </Kit.FormContainer>
      </Kit.LoadContainer>
    </div>
  );
};
