import React from 'react';

import { createCn } from 'bem-react-classname';
import cn from 'classnames';

import './KitFilterCol.scss';

interface Props {
  children?: React.ReactNode;
  className?: string;
  handler?: boolean;
  switcher?: boolean;
}

const cx = createCn('KitFilterCol');

export const KitFilterCol: React.FC<Props> = ({
  children,
  className,
  handler = false,
  switcher = false,
}) => {
  return (
    <div className={cn(className, cx({ handler, switcher }))}>
      <>{children}</>
    </div>
  );
};
