import React, { useCallback } from 'react';

import { Pagination } from 'antd';
import { GetMonitoringKeysUpdatesQuery } from 'api/server';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { useFilter, useNav, useUiMemory } from 'hooks';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useLifecycles } from 'react-use';
import { server } from 'store';
import { Kit } from 'ui/Kit';
import { ProjectLink } from 'ui/ProjectLink';
import { tablePaginationConfig } from 'utils';

import { MonitoringMenu } from '../../shared/MonitoringMenu';

import './MonitoringKeysUpdatesPage.scss';

interface Props {}

const cx = createCn('MonitoringKeysUpdatesPage');

export const MonitoringKeysUpdatesPage: React.FC = () => {
  const dispatch = useDispatch();

  const filter = useFilter<GetMonitoringKeysUpdatesQuery>({
    timezone: moment.tz.guess(true),
  });

  const getMonitoringKeysUpdates = useCallback(
    async (query: GetMonitoringKeysUpdatesQuery, delay?: number) => {
      dispatch(
        server.getMonitoringKeysUpdates.thunk.request({
          query,
        }),
      );
    },
    [dispatch],
  );

  const getMonitoringKeysUpdatesState = useSelector(
    server.getMonitoringKeysUpdates.selector.state,
  );

  const [memoryLimit, setMemoryLimit] = useUiMemory(
    'list.monitoring_updates.nav.limit',
    tablePaginationConfig.defaultPageSize,
  );

  const nav = useNav(getMonitoringKeysUpdatesState.data?.nav, {
    limit: memoryLimit,
  });

  useLifecycles(
    () => {
      getMonitoringKeysUpdates({
        ...nav.initialValues,
        ...filter.values,
      });
    },
    () => {
      dispatch(server.getMonitoringKeysUpdates.action.reset());
    },
  );

  return (
    <div className={cn(cx())}>
      <Kit.LoadContainer
        error={getMonitoringKeysUpdatesState.error}
        isFetched={getMonitoringKeysUpdatesState.isFetched}
        isFetching={getMonitoringKeysUpdatesState.isFetching}
      >
        <MonitoringMenu />
        <Kit.Title>Keys updates monitoring</Kit.Title>

        {getMonitoringKeysUpdatesState.data && (
          <div className={cx('list')}>
            {getMonitoringKeysUpdatesState.data.list.map((item) => (
              <div className={cx('item')} key={item.date}>
                <div className={cx('date')}>
                  {moment(item.date).format('DD MMM YYYY')}
                </div>

                <div>
                  {item.details.map((detail) => (
                    <div className={cx('detail')} key={detail.project.id}>
                      <div className={cx('keysAmount')}>
                        <div className={cx('keysAmountValue')}>
                          + {detail.keysAmount}
                        </div>
                      </div>

                      <div className={cx('project')}>
                        {detail.project.name}
                        <ProjectLink
                          to={`/projects/info/${detail.project.id}`}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}

        <div className={cx('pagination')}>
          <Pagination
            disabled={getMonitoringKeysUpdatesState.isFetching}
            {...{
              ...tablePaginationConfig,
              ...nav.toAntPagination(),
            }}
            onChange={(current, pageSize) => {
              setMemoryLimit(pageSize as number);

              getMonitoringKeysUpdates({
                ...filter.values,
                ...nav.fromAntReq({
                  current,
                  pageSize,
                }),
              });
            }}
          />
        </div>
      </Kit.LoadContainer>
    </div>
  );
};
