import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReduxState } from 'store';
import { RolePermissions } from 'utils';

export interface RolePermissionsState {
  data: null | RolePermissions;
  isInit: boolean;
}

const initialState: RolePermissionsState = {
  data: null,
  isInit: false,
};

const selector = {
  state: (state: ReduxState) => state.app.rolePermissions,
};

const SLICE_NAME = '@app/rolePermissions';

const { actions, reducer } = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset() {
      return initialState;
    },

    set(state, action: PayloadAction<RolePermissions>) {
      state.data = action.payload;
      state.isInit = true;
    },
  },
});

interface RolePermissionsSlice {
  action: typeof actions;
  reducer: typeof reducer;
  selector: typeof selector;
}

export const rolePermissions: RolePermissionsSlice = {
  action: actions,
  reducer,
  selector,
};
