import React from 'react';

import { Col, Row, Spin, Divider } from 'antd';
import { createCn } from 'bem-react-classname';
import { core } from 'core';
import { usePermissions } from 'hooks';
import { map } from 'lodash-es';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { ServerState } from 'store';
import { Kit } from 'ui/Kit';
import { MessageCountBadge } from 'ui/MessageCountBadge';
import { ProjectLocale } from 'ui/ProjectLocale';
import { ProjectUsersBadge } from 'ui/ProjectUsersBadge';
import { ProjectVersionColor } from 'ui/ProjectVersionColor';

import './ProjectInfo.scss';

const cx = createCn('ProjectInfo');

interface Props {
  getProjectByIdState: ServerState['getProjectById'];
  isInfo?: boolean;
}

export const ProjectInfo: React.FC<Props> = ({
  getProjectByIdState,
  isInfo = true,
}) => {
  const permissions = usePermissions();

  return (
    <div className={cx()}>
      {getProjectByIdState.isFetching && !getProjectByIdState.error && (
        <div className={cx('loader')}>
          <Spin spinning size="large" />
        </div>
      )}

      <Row style={{ alignItems: 'center' }}>
        <Col flex="1">
          <h3>Project: {getProjectByIdState.data?.project.name}</h3>
        </Col>

        <Col>
          <Col className={cx('infoActions')}>
            <Kit.Action
              className={cx('infoAction')}
              title={`Project info and stats`}
              type={'info'}
              to={core.toPage(`/info/${getProjectByIdState.data?.project.id}`)}
            />

            <Kit.Action
              className={cx('infoAction')}
              type={'edit'}
              to={core.toPage(`/edit/${getProjectByIdState.data?.project.id}`)}
              title={`Edit project #${getProjectByIdState.data?.project.id}`}
              disabled={!permissions.has('PROJECTS_UPDATE')}
            />
          </Col>
        </Col>
      </Row>

      <Divider className={cx('divider')} />

      {isInfo && (
        <>
          <Row className={cx('row')}>
            <div className={cx('detailRow')}>
              <div className={cx('detailColLabel')}>ID: </div>
              <div className={cx('detailColValue')}>
                {getProjectByIdState.data?.project.id}
              </div>
            </div>

            <div className={cx('detailRow')}>
              <div className={cx('detailColLabel')}>Slug: </div>
              <div className={cx('detailColValue')}>
                {getProjectByIdState.data?.project.slug}
              </div>
            </div>

            <div className={cx('detailRow')}>
              <div className={cx('detailColLabel')}>Created: </div>
              <div className={cx('detailColValue')}>
                {moment(getProjectByIdState.data?.project.createdAt).format(
                  'DD.MM.YYYY HH:mm:ss',
                )}
              </div>
            </div>

            <div className={cx('detailRow')}>
              <div className={cx('detailColLabel')}>Description: </div>
              <div className={cx('detailColValue')}>
                {getProjectByIdState.data?.project.description}
              </div>
            </div>

            <div className={cx('detailRow')}>
              <div className={cx('detailColLabel')}>Unique keys: </div>
              <div className={cx('detailColValue')}>
                <MessageCountBadge
                  type="keys"
                  count={getProjectByIdState.data?.uniqKeys}
                />
              </div>
            </div>

            <div className={cx('detailRow')}>
              <div className={cx('detailColLabel')}>Related users: </div>
              <div className={cx('detailColValue')}>
                <ProjectUsersBadge
                  count={getProjectByIdState.data?.relatedUsers}
                />
              </div>
            </div>
          </Row>
          <Divider className={cx('divider')} />{' '}
        </>
      )}

      <Row className={cx('row')}>
        <Col flex="1">
          <Row style={{ alignItems: 'center' }}>
            <div className={cx('paramLabel')}>Versions:</div>

            {map(getProjectByIdState.data?.versions, (version) => (
              <NavLink
                exact={true}
                activeClassName="active"
                to={core.toPage(
                  `/manage/${getProjectByIdState.data?.project.id}/versions/info/${version.id}`,
                )}
                key={version.id}
              >
                <ProjectVersionColor
                  key={version.param}
                  param={version.param}
                  title={version.name}
                />
              </NavLink>
            ))}
          </Row>
        </Col>

        <Col>
          <Kit.Action
            title={`Manage project versions #${getProjectByIdState.data?.project.id}`}
            type={'versions'}
            to={core.toPage(
              `/manage/${getProjectByIdState.data?.project.id}/versions`,
            )}
          />
        </Col>
      </Row>

      <Divider className={cx('divider')} />

      <Row className={cx('row')}>
        <Col flex="1" style={{ alignItems: 'center' }}>
          <Row style={{ alignItems: 'center' }}>
            <div className={cx('paramLabel')}>Languages:</div>
            {map(getProjectByIdState.data?.locales, (locale) => (
              <NavLink
                exact={true}
                activeClassName="active"
                to={core.toPage(
                  `/manage/${getProjectByIdState.data?.project.id}/messages/${locale.ico}`,
                )}
                key={locale.id}
              >
                <ProjectLocale ico={locale.ico} name={locale.enName} />
              </NavLink>
            ))}
          </Row>
        </Col>
        <Col></Col>
      </Row>
    </div>
  );
};
