import React from 'react';

import {
  MergeCellsOutlined,
  DownloadOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  faTrashAlt,
  faEdit,
  faInfo,
  faCodeBranch,
  faCopy,
  faPaste,
  faMinus,
  faBroom,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import './KitAction.scss';

const cx = createCn('KitAction');

const iconSet = {
  delete: <FontAwesomeIcon icon={faTrashAlt} />,
  edit: <FontAwesomeIcon icon={faEdit} />,
  info: <FontAwesomeIcon icon={faInfo} />,
  versions: <FontAwesomeIcon icon={faCodeBranch} />,
  copy: <FontAwesomeIcon icon={faCopy} />,
  stopCopy: <FontAwesomeIcon icon={faMinus} />,
  paste: <FontAwesomeIcon icon={faPaste} />,
  takeBack: <FontAwesomeIcon icon={faBroom} />,
  import: <MergeCellsOutlined />,
  export: <DownloadOutlined />,
  create: <PlusCircleOutlined />,
};

interface Props {
  className?: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<any, MouseEvent>) => void;
  type: keyof typeof iconSet;
  to?: string;
  title?: string;
  text?: string;
}

export const KitAction: React.FC<Props> = ({
  className,
  disabled,
  onClick,
  type,
  to,
  title,
  text,
}) => {
  const history = useHistory();

  const render = (
    <>
      <div className={cx('iconCol')}>{iconSet[type]}</div>
      {text && <div className={cx('text')}>{text}</div>}
    </>
  );

  return (
    <button
      type="button"
      disabled={disabled || history.location.pathname === to}
      className={cn(cx({ [type]: true, text: text !== undefined }), className)}
      onClick={
        onClick
          ? onClick
          : () => {
              history.push(to || '/');
            }
      }
    >
      {title ? (
        <Tooltip placement="top" title={title}>
          {render}
        </Tooltip>
      ) : (
        render
      )}
    </button>
  );
};

KitAction.defaultProps = {
  disabled: false,
};
