import React from 'react';

import { createCn } from 'bem-react-classname';
import { ClickToCopy } from 'ui/ClickToCopy';

import './MessageDefaultKey.scss';

interface Props {
  value: string;
}

const cx = createCn('MessageDefaultKey');

export const MessageDefaultKey: React.FC<Props> = ({ value }) => {
  return (
    <ClickToCopy title={'Click to copy the key'} text={value}>
      <div className={cx()}>
        {value?.split('.').map((part, index) => (
          <span className={cx('part')} key={index}>
            {part}
          </span>
        ))}
      </div>
    </ClickToCopy>
  );
};
