import React, { useCallback, useState } from 'react';

import { UserOutlined, MailOutlined, LockOutlined } from '@ant-design/icons';
import { Form, Input, Button, Alert } from 'antd';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { core } from 'core';
import { useFormError } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { app, AppThunk } from 'store';
import { server } from 'store';
import { AnyValues } from 'types';
import { Kit } from 'ui/Kit';
import { toasts } from 'ui/toasts';

import './RegisterPage.scss';

const cx = createCn('RegisterPage');
const title = 'Create an account';

export const RegisterPage: React.FC = () => {
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const initialValues: AnyValues<
    Parameters<typeof server.registerUser.thunk.request>[0]['body']
  > = { firstName: '', lastName: '', email: '', password: '' };

  const onFinish = useCallback(
    (values) => {
      dispatch(
        ((): AppThunk => async (dispatch, getState) => {
          await dispatch(
            server.registerUser.thunk.request({
              body: values,
            }),
          );

          const { error } = server.registerUser.selector.state(getState());

          toasts.result({
            title,
            error,
            // exceptParams: keys(initialValues),
          });

          if (!error) {
            setIsSuccess(true);
          }
        })(),
      );
    },
    [dispatch],
  );

  const authState = useSelector(app.auth.selector.state);

  const registerUserState = useSelector(server.registerUser.selector.state);
  const disabled = registerUserState.isFetching;
  const formError = useFormError(registerUserState.error);

  if (authState.isLogged) {
    return <Redirect to="/" />;
  }

  return (
    <div className={cn(cx())}>
      <div className={cx('container')}>
        <div className={cx('block')}>
          <div className={cx('header')}>
            <Kit.Title>{title}</Kit.Title>
          </div>

          <div className={cx('body')}>
            {isSuccess ? (
              <div>
                <Alert
                  message={`Your account needs to be approved by the administrator`}
                  type="success"
                />

                <div className={cx('links')}>
                  <Link to={core.toPage('/login')}>Back to login</Link>
                </div>
              </div>
            ) : (
              <div className={cx('formWrap')}>
                <Form
                  name="register"
                  className={cn(cx('form'))}
                  initialValues={initialValues}
                  onFinish={onFinish}
                >
                  <Form.Item
                    name="firstName"
                    validateStatus={formError.status('firstName')}
                    help={formError.note('firstName')}
                  >
                    <Input
                      prefix={<UserOutlined />}
                      placeholder="First name"
                      size={'large'}
                      disabled={disabled}
                    />
                  </Form.Item>

                  <Form.Item
                    name="lastName"
                    validateStatus={formError.status('lastName')}
                    help={formError.note('lastName')}
                  >
                    <Input
                      prefix={<UserOutlined />}
                      placeholder="Last name"
                      size={'large'}
                      disabled={disabled}
                    />
                  </Form.Item>

                  <Form.Item
                    name="email"
                    validateStatus={formError.status('email')}
                    help={formError.note('email')}
                  >
                    <Input
                      prefix={<MailOutlined />}
                      placeholder="Email"
                      size={'large'}
                      disabled={disabled}
                    />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    validateStatus={formError.status('password')}
                    help={formError.note('password')}
                  >
                    <Input
                      prefix={<LockOutlined />}
                      type="password"
                      placeholder="Password"
                      size={'large'}
                      disabled={disabled}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="register-form-button"
                      size={'large'}
                      disabled={disabled}
                    >
                      Register
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
