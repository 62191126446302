import React from 'react';

import {
  InfoCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { faCodeBranch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItemProps } from 'antd';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import './KitItemAction.scss';

const cx = createCn('KitItemAction');

const iconSet = {
  delete: <DeleteOutlined />,
  edit: <EditOutlined />,
  info: <InfoCircleOutlined />,
  versions: <FontAwesomeIcon icon={faCodeBranch} />,
} as const;

type Props = MenuItemProps & {
  className?: string;
  type: keyof typeof iconSet;
  text?: string;
  to?: string;
  onClick?: MenuItemProps['onClick'];
  disabled?: boolean;
};

export const KitItemAction: React.FC<Props> = ({
  className,
  text,
  type,
  onClick,
  to,
  disabled,
  ...other
}) => {
  const history = useHistory();

  return (
    <Menu.Item
      {...other}
      key={type}
      onClick={(...args) => {
        onClick && onClick(...args);
        to && history.push(to || '/');
      }}
      disabled={disabled}
      className={cn(className, cx({ [type]: true }))}
    >
      <div className={cx('item')}>
        <div className={cx('icon')}>{iconSet[type]}</div>
        <div className={cx('label')}>{text}</div>
      </div>
    </Menu.Item>
  );
};
