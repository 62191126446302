import React from 'react';

import { Descriptions, Col, Row } from 'antd';
import { createCn } from 'bem-react-classname';
import { core } from 'core';
import moment from 'moment';
import { ServerState } from 'store';
import { Kit } from 'ui/Kit';

import './UserInfo.scss';

const cx = createCn('UserInfo');

interface Props {
  user: NonNullable<ServerState['getUserById']['data']>['user'];
}

export const UserInfo: React.FC<Props> = ({ user }) => {
  return (
    <div className={cx()}>
      <Descriptions>
        <Descriptions.Item label="First Name">
          {user.firstName}
        </Descriptions.Item>
        <Descriptions.Item label="Last name">{user.lastName}</Descriptions.Item>
        <Descriptions.Item label="E-mail">{user.email}</Descriptions.Item>
        <Descriptions.Item label="Created">
          {moment(user.createdAt).format('DD.MM.YYYY HH:mm')}
        </Descriptions.Item>
      </Descriptions>

      <Row className={cx('locales')}>
        <Col>
          <Kit.Action
            type={'edit'}
            to={core.toPage(`/edit/${user.id}`)}
            title={`Edit user #${user.id}`}
          />
        </Col>
      </Row>
    </div>
  );
};
