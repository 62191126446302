/* eslint @typescript-eslint/no-explicit-any: ["off"] */

export enum ErrorTypeEnum {
  InvalidParam = 'InvalidParam',
  InvalidBody = 'InvalidBody',
  InvalidQuery = 'InvalidQuery',
  InvalidSession = 'InvalidSession',
  InvalidToken = 'InvalidToken',
  NotFound = 'NotFound',
  Forbidden = 'Forbidden',
  Rejection = 'Rejection',
  ServerError = 'ServerError',
}

export interface ApiCommonErrorDetailsDto {
  param: string;
  note: string;
}

export interface ApiCommonErrorDto {
  message: string;
  type: ErrorTypeEnum;
  details: Array<ApiCommonErrorDetailsDto>;
}

export interface RefreshAuthBodyDto {
  refreshToken: string;
  fingerprint: string;
}

export enum UserRoleEnum {
  User = 'user',
  Moderator = 'moderator',
  Admin = 'admin',
}

export interface PermissionDto {
  permission:
    | 'DASHBOARD_MODULE'
    | 'MONITORING_MODULE'
    | 'USERS_MODULE'
    | 'PROJECTS_MODULE'
    | 'PROJECTS_ALL_ROWS'
    | 'PROJECTS_CREATE'
    | 'PROJECTS_UPDATE'
    | 'PROJECTS_DELETE'
    | 'PROJECTS_VERSION_MODULE'
    | 'PROJECTS_VERSION_UPDATE'
    | 'PROJECTS_VERSION_CREATE'
    | 'PROJECTS_VERSION_DELETE'
    | 'MESSAGES_MODULE'
    | 'MESSAGES_EXPORT'
    | 'MESSAGES_IMPORT'
    | 'ACTIONS_MODULE'
    | 'LOCALES_MODULE'
    | 'PROFILE_MODULE'
    | 'UPLOAD_MODULE'
    | 'ACCOUNT_MODULE';
  role: UserRoleEnum;
  description: string;
}

export interface RefreshAuthDataDto {
  accessToken: string;
  refreshToken: string;
  rolePermissionsList: Array<PermissionDto>;
}

export interface LoginUserBodyDto {
  email: string;
  password: string;
  fingerprint: string;
}

export interface LoginUserDataDto {
  accessToken: string;
  refreshToken: string;
  rolePermissionsList: Array<PermissionDto>;
}

export interface RegisterUserBodyDto {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface RegisterUserDataDto {
  userId: number;
}

export interface GetSessionsItemDataDto {
  key: string;
  ip: string;
  userAgent: string;
  loggedAt: string;
  refreshedAt: string;
}

export interface GetSessionsDataDto {
  list: Array<GetSessionsItemDataDto>;
}

export interface DeleteSessionDataDto {
  key: string;
}

export enum UserStateEnum {
  Pending = 'pending',
  Active = 'active',
  Banned = 'banned',
}

export enum GetUsersFieldEnum {
  Id = 'id',
  Email = 'email',
  Role = 'role',
  State = 'state',
  FirstName = 'firstName',
  LastName = 'lastName',
  ProfileImage = 'profileImage',
  CreatedAt = 'createdAt',
}

export enum OrderByEnum {
  Asc = 'asc',
  Desc = 'desc',
}

export interface UserDataDto {
  id: number;
  email: string;
  role: UserRoleEnum;
  firstName: string;
  lastName: string;
  state: UserStateEnum;
  profileImageSrc: string;
  createdAt: string;
}

export interface GetUsersItemDto {
  user: UserDataDto;
  canDelete: boolean;
  canEdit: boolean;
}

export interface NavDto {
  limit: number;
  offset: number;
  total: number;
}

export interface GetUsersSortDataDto {
  field: GetUsersFieldEnum;
  order: OrderByEnum;
}

export interface GetUsersDataDto {
  list: Array<GetUsersItemDto>;
  nav: NavDto;
  sort: GetUsersSortDataDto;
}

export interface GetUsersListItemDto {
  id: number;
  name: string;
}

export interface GetUsersListDataDto {
  list: Array<GetUsersListItemDto>;
}

export interface GetUserDataDto {
  user: UserDataDto;
  projectIds: Array<number>;
  canDelete: boolean;
  canEdit: boolean;
}

export interface CreateUserBodyDto {
  firstName: string;
  lastName: string;
  state: UserStateEnum;
  projectIds: Array<number>;
  email: string;
  password: string;
  role: UserRoleEnum;
}

export interface CreateUserDataDto {
  id: number;
}

export interface UpdateUserBodyDto {
  firstName: string;
  lastName: string;
  state: UserStateEnum;
  projectIds: Array<number>;
  role: UserRoleEnum;
  changePassword: boolean;
  password?: string;
}

export interface UpdateUserDataDto {
  id: number;
}

export interface DeleteUserDataDto {
  id: number;
}

export interface GetLocalesItemDto {
  id: number;
  name: string;
  ico: string;
}

export interface GetLocalesListDataDto {
  list: Array<GetLocalesItemDto>;
}

export enum GetProjectsFieldEnum {
  Id = 'id',
  Name = 'name',
  Slug = 'slug',
  Description = 'description',
}

export interface ProjectDataDto {
  id: number;
  slug: string;
  name: string;
  description: string;
  createdAt: string;
}

export interface LocaleDataDto {
  id: number;
  name: string;
  enName: string;
  ico: string;
}

export interface ProjectVersionDataDto {
  id: number;
  projectId: number;
  param: string;
  name: string;
  createdAt: string;
}

export interface GetProjectByIdDataDto {
  project: ProjectDataDto;
  locales: Array<LocaleDataDto>;
  versions: Array<ProjectVersionDataDto>;
  uniqKeys: number;
  messagesAmount: number;
  filledMessagesAmount: number;
  canDelete: boolean;
  canChangeSlug: boolean;
  relatedUsers: number;
}

export interface GetProjectsSortDataDto {
  field: GetProjectsFieldEnum;
  order: OrderByEnum;
}

export interface GetProjectsDataDto {
  list: Array<GetProjectByIdDataDto>;
  sort: GetProjectsSortDataDto;
  nav: NavDto;
}

export interface GetProjectsItemDto {
  id: number;
  name: string;
}

export interface GetProjectsListDataDto {
  list: Array<GetProjectsItemDto>;
}

export interface GetProjectStatsByIdItemDto {
  projectVersion: ProjectVersionDataDto;
  locales: Array<LocaleDataDto>;
  filledMessagesAmount: number;
  messagesAmount: number;
  uniqKeys: number;
}

export interface GetProjectStatsByIdDataDto {
  list: Array<GetProjectStatsByIdItemDto>;
}

export interface CreateProjectBodyDto {
  name: string;
  slug: string;
  description: string;
  localeIds: Array<number>;
}

export interface RowIdDataDto {
  id: number;
}

export interface UpdateProjectByIdBodyDto {
  name: string;
  slug: string;
  description: string;
}

export enum SelectMessagesFieldEnum {
  Key = 'key',
  CreatedAt = 'createdAt',
}

export interface KeyCommentDataDto {
  id: number;
  value: string;
}

export interface DefaultMessageDataDto {
  id: number;
  key: string;
  value: string;
  isDeprecated: boolean;
  deprecatedAt: string;
  changedAt: string;
  createdAt: string;
}

export interface LocaleMessageDataDto {
  id: number;
  value: string;
  isChecked: boolean;
  changedAt: string;
  editedAt: string;
}

export interface SelectMessagesItemDetailDto {
  projectVersion: ProjectVersionDataDto;
  defaultMessage: DefaultMessageDataDto;
  localeMessage: LocaleMessageDataDto;
}

export interface SelectMessagesItemDto {
  defaultMessageKey: string;
  comment: KeyCommentDataDto;
  details: Array<SelectMessagesItemDetailDto>;
}

export interface SelectMessagesSortDataDto {
  field: SelectMessagesFieldEnum;
  order: OrderByEnum;
}

export interface SelectMessagesDataDto {
  list: Array<SelectMessagesItemDto>;
  sort: SelectMessagesSortDataDto;
  nav: NavDto;
}

export enum SearchMessagesFieldEnum {
  Key = 'key',
  CreatedAt = 'createdAt',
}

export interface SearchMessagesItemDetailDto {
  locale: LocaleDataDto;
  localeMessage: LocaleMessageDataDto;
}

export interface SearchMessagesItemDto {
  defaultMessage: DefaultMessageDataDto;
  projectVersion: ProjectVersionDataDto;
  project: ProjectDataDto;
  details: Array<SearchMessagesItemDetailDto>;
}

export interface SearchMessagesSortDataDto {
  field: SearchMessagesFieldEnum;
  order: OrderByEnum;
}

export interface SearchMessagesDataDto {
  list: Array<SearchMessagesItemDto>;
  sort: SearchMessagesSortDataDto;
  nav: NavDto;
}

export enum ExportMessagesResultEnum {
  Generated = 'generated',
  Empty = 'empty',
}

export interface ExportMessagesDataDto {
  result: ExportMessagesResultEnum;
  fileSrc: string;
}

export interface ImportMessagesBodyDto {
  file: {
    [key: string]: any;
  };
  projectVersionIds: Array<number>;
  rewriteValues: boolean;
  checkOriginal: boolean;
}

export interface ImportMessagesDataDto {
  result: string;
  updates: number;
}

export interface GetProjectLocaleKeyDataItemDto {
  projectVersion: ProjectVersionDataDto;
  defaultMessage: DefaultMessageDataDto;
  localeMessage: LocaleMessageDataDto;
}

export interface GetProjectLocaleKeyDataDataDto {
  list: Array<GetProjectLocaleKeyDataItemDto>;
  comment: KeyCommentDataDto;
  locale: LocaleDataDto;
  project: ProjectDataDto;
  historyMessages: Array<string>;
  relatedMessages: Array<string>;
}

export interface UpdateProjectLocaleKeyDataMessageDto {
  id: number;
  value: string;
  isChecked: boolean;
}

export interface UpdateProjectLocaleKeyDataBodyDto {
  messages: Array<UpdateProjectLocaleKeyDataMessageDto>;
  commentText?: string;
}

export interface GetProjectKeysDataDto {
  list: Array<string>;
}

export interface GetUsingLocalesListDataDto {
  list: Array<LocaleDataDto>;
}

export enum AuthStatusEnum {
  Authorized = 'authorized',
  Unauthorized = 'unauthorized',
}

export enum GetActionsFieldEnum {
  CreatedAt = 'createdAt',
}

export interface FormActionUserDataDto {
  id: number;
  role: UserRoleEnum;
  firstName: string;
  lastName: string;
}

export interface FormActionDataDto {
  id: number;
  section: string;
  event: string;
  relateId?: number;
  userId?: number;
  user?: FormActionUserDataDto;
  ip?: string;
  title: string;
  description: string;
  createdAt: string;
  canDelete: boolean;
}

export interface GetActionsSortDataDto {
  field: GetActionsFieldEnum;
  order: OrderByEnum;
}

export interface GetActionsDataDto {
  list: Array<FormActionDataDto>;
  sort: GetActionsSortDataDto;
  nav: NavDto;
}

export interface GetActionNavigationsSectionDto {
  param: string;
  name: string;
  events: Array<{
    name: string;
    param: string;
  }>;
}

export interface GetActionNavigationsDataDto {
  list: Array<GetActionNavigationsSectionDto>;
}

export interface GetProjectVersionLocalesDataDto {
  param: string;
  slug: string;
  locales: Array<LocaleDataDto>;
}

export interface GetProjectVersionLocaleMessagesDataDto {
  slug: string;
  param: string;
  ico: string;
  messages: Array<{
    key: string;
    value: string;
  }>;
}

export interface UpdateDefaultMessagesBodyDto {
  noDeprecated?: boolean;
  messages: Array<{
    key: string;
    value: string;
  }>;
}

export interface UpdateDefaultMessagesErrorDto {
  key: string;
  note: string;
}

export interface UpdateDefaultMessagesDataDto {
  slug: string;
  param: string;
  newKeys: number;
  deprecatedKeys: number;
  wrongKeys: Array<string>;
  errors: Array<UpdateDefaultMessagesErrorDto>;
}

export interface UpdateProjectVersionLocaleMessagesBodyDto {
  messages: Array<{
    key: string;
    value: string;
  }>;
}

export interface UpdateProjectVersionLocaleMessagesDataDto {
  slug: string;
  param: string;
  ico: string;
  updates: number;
}

export interface GetInfoDataDto {
  message: string;
  apiVersion: string;
  syncLocalesVersion: string;
}

export interface GetProjectVersionDefaultMessagesDataDto {
  slug: string;
  param: string;
  defaultMessages: Array<{
    key: string;
    value: string;
  }>;
}

export interface GetProfileDataDto {
  id: number;
  email: string;
  role: UserRoleEnum;
  firstName: string;
  lastName: string;
  profileImage: string;
  profileImageSrc: string;
  createdAt: string;
  updatedAt: string;
}

export interface UpdateProfileBodyDto {
  firstName: string;
  lastName: string;
  profileImage?: string;
}

export interface UpdateProfileDataDto {
  id: number;
}

export interface ChangePasswordBodyDto {
  currentPassword: string;
  confirmPassword: string;
  newPassword: string;
}

export interface ChangePasswordDataDto {
  id: number;
}

export interface LoadProfileImageBodyDto {
  file: {
    [key: string]: any;
  };
}

export interface LoadProfileImageDataDto {
  src: string;
}

export interface UploadImageBodyDto {
  file: string;
}

export interface UploadImageDataDto {
  filename: string;
  src: string;
}

export enum GetProjectVersionsFieldEnum {
  Id = 'id',
  Name = 'name',
  Param = 'param',
}

export interface ProjectVersionDetailDto {
  version: ProjectVersionDataDto;
  locales: Array<LocaleDataDto>;
  canDelete: boolean;
  canEdit: boolean;
  canChangeParam: boolean;
}

export interface GetProjectVersionsSortDataDto {
  field: GetProjectVersionsFieldEnum;
  order: OrderByEnum;
}

export interface GetProjectVersionsDataDto {
  list: Array<ProjectVersionDetailDto>;
  nav: NavDto;
  sort: GetProjectVersionsSortDataDto;
}

export interface GetProjectVersionsItemDto {
  id: number;
  name: string;
}

export interface GetProjectVersionsListDataDto {
  list: Array<GetProjectVersionsItemDto>;
}

export interface GetProjectVersionByIdDataDto {
  version: ProjectVersionDataDto;
  locales: Array<LocaleDataDto>;
  keys: number;
  canDelete: boolean;
  canChangeParam: boolean;
}

export interface GetProjectVersionStatsByIdItemDto {
  locale: LocaleDataDto;
  filledMessagesAmount: number;
  messagesAmount: number;
}

export interface GetProjectVersionStatsByIdDataDto {
  list: Array<GetProjectVersionStatsByIdItemDto>;
}

export interface CreateProjectVersionBodyDto {
  projectId: number;
  name: string;
  param: string;
  localeIds: Array<number>;
}

export interface UpdateProjectVersionByIdBodyDto {
  projectId: number;
  name: string;
  param: string;
  localeIds: Array<number>;
}

export interface GetMonitoringKeysUpdatesItemDetailDto {
  project: ProjectDataDto;
  keysAmount: number;
}

export interface GetMonitoringKeysUpdatesItemDto {
  date: string;
  details: Array<GetMonitoringKeysUpdatesItemDetailDto>;
  keysSum: number;
}

export interface GetMonitoringKeysUpdatesDataDto {
  list: Array<GetMonitoringKeysUpdatesItemDto>;
  nav: NavDto;
}

export interface GetMonitoringMessagesUpdatesItemDetailDto {
  project: ProjectDataDto;
  messagesAmount: number;
}

export interface GetMonitoringMessagesUpdatesItemDto {
  date: string;
  details: Array<GetMonitoringMessagesUpdatesItemDetailDto>;
  messagesSum: number;
}

export interface GetMonitoringMessagesUpdatesDataDto {
  list: Array<GetMonitoringMessagesUpdatesItemDto>;
  nav: NavDto;
}

export interface GetKeysUpdatesStatsItemDto {
  date: string;
  amount: number;
}

export interface GetKeysUpdatesStatsDataDto {
  list: Array<GetKeysUpdatesStatsItemDto>;
}

export interface GetMessagesUpdatesStatsItemDto {
  date: string;
  amount: number;
}

export interface GetMessagesUpdatesStatsDataDto {
  list: Array<GetMessagesUpdatesStatsItemDto>;
}

export interface GetProjectVersionSyncsStatsItemDto {
  date: string;
  amount: number;
}

export interface GetProjectVersionSyncsStatsDataDto {
  list: Array<GetProjectVersionSyncsStatsItemDto>;
}

export interface GetActionsStatsItemDto {
  date: string;
  amount: number;
}

export interface GetActionsStatsDataDto {
  list: Array<GetActionsStatsItemDto>;
}

export interface GetDashboardStatsDataDto {
  projectsTotal: number;
  versionsTotal: number;
  uniqLocalesAmount: number;
  uniqKeysAmount: number;
  messagesTotal: number;
  filledMessagesAmount: number;
}

export type RefreshAuthResponse<
  T1 extends 200 | 400 = 200 | 400,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? RefreshAuthDataDto
    : any
  : T1 extends 400
  ? T2 extends 'application/json'
    ? ApiCommonErrorDto
    : any
  : any;

export type RefreshAuthRequest<
  T1 extends 'application/json' = 'application/json'
> = T1 extends 'application/json' ? RefreshAuthBodyDto : any;

export type LoginUserResponse<
  T1 extends 200 | 400 = 200 | 400,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? LoginUserDataDto
    : any
  : T1 extends 400
  ? T2 extends 'application/json'
    ? ApiCommonErrorDto
    : any
  : any;

export type LoginUserRequest<
  T1 extends 'application/json' = 'application/json'
> = T1 extends 'application/json' ? LoginUserBodyDto : any;

export type RegisterUserResponse<
  T1 extends 200 | 400 = 200 | 400,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? RegisterUserDataDto
    : any
  : T1 extends 400
  ? T2 extends 'application/json'
    ? ApiCommonErrorDto
    : any
  : any;

export type RegisterUserRequest<
  T1 extends 'application/json' = 'application/json'
> = T1 extends 'application/json' ? RegisterUserBodyDto : any;

export type GetSessionsResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetSessionsDataDto
    : any
  : any;

export interface DeleteSessionParams {
  key: string;
}

export type DeleteSessionResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? DeleteSessionDataDto
    : any
  : any;

export interface GetUsersQuery {
  search?: string;
  role?: UserRoleEnum;
  state?: UserStateEnum;
  field?: GetUsersFieldEnum;
  order?: OrderByEnum;
  limit?: number;
  offset?: number;
}

export type GetUsersResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetUsersDataDto
    : any
  : any;

export interface GetUsersListQuery {
  search?: string;
  limit?: number;
}

export type GetUsersListResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetUsersListDataDto
    : any
  : any;

export interface GetUserByIdParams {
  id: number;
}

export type GetUserByIdResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetUserDataDto
    : any
  : any;

export type CreateUserResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? CreateUserDataDto
    : any
  : any;

export type CreateUserRequest<
  T1 extends 'application/json' = 'application/json'
> = T1 extends 'application/json' ? CreateUserBodyDto : any;

export interface UpdateUserParams {
  id: number;
}

export type UpdateUserResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? UpdateUserDataDto
    : any
  : any;

export type UpdateUserRequest<
  T1 extends 'application/json' = 'application/json'
> = T1 extends 'application/json' ? UpdateUserBodyDto : any;

export interface DeleteUserParams {
  id: number;
}

export type DeleteUserResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? DeleteUserDataDto
    : any
  : any;

export type GetLocalesListResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetLocalesListDataDto
    : any
  : any;

export interface GetProjectsQuery {
  search?: string;
  field?: GetProjectsFieldEnum;
  order?: OrderByEnum;
  limit?: number;
  offset?: number;
}

export type GetProjectsResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetProjectsDataDto
    : any
  : any;

export type GetProjectsListResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetProjectsListDataDto
    : any
  : any;

export interface GetProjectByIdParams {
  id: number;
}

export type GetProjectByIdResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetProjectByIdDataDto
    : any
  : any;

export interface GetProjectStatsByIdParams {
  id: number;
}

export type GetProjectStatsByIdResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetProjectStatsByIdDataDto
    : any
  : any;

export type CreateProjectResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200 ? (T2 extends 'application/json' ? RowIdDataDto : any) : any;

export type CreateProjectRequest<
  T1 extends 'application/json' = 'application/json'
> = T1 extends 'application/json' ? CreateProjectBodyDto : any;

export interface UpdateProjectByIdParams {
  id: number;
}

export type UpdateProjectByIdResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200 ? (T2 extends 'application/json' ? RowIdDataDto : any) : any;

export type UpdateProjectByIdRequest<
  T1 extends 'application/json' = 'application/json'
> = T1 extends 'application/json' ? UpdateProjectByIdBodyDto : any;

export interface DeleteProjectByIdParams {
  id: number;
}

export type DeleteProjectByIdResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200 ? (T2 extends 'application/json' ? RowIdDataDto : any) : any;

export interface SelectMessagesParams {
  projectId: number;
  ico: string;
}

export interface SelectMessagesQuery {
  search?: string;
  tags?: Array<string>;
  selectEmpty?: boolean;
  selectActive?: boolean;
  field?: SelectMessagesFieldEnum;
  order?: OrderByEnum;
  limit?: number;
  offset?: number;
}

export type SelectMessagesResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? SelectMessagesDataDto
    : any
  : any;

export interface SearchMessagesQuery {
  search?: string;
  localeId?: number;
  selectActive?: boolean;
  field?: SearchMessagesFieldEnum;
  order?: OrderByEnum;
  limit?: number;
  offset?: number;
}

export type SearchMessagesResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? SearchMessagesDataDto
    : any
  : any;

export interface ExportMessagesParams {
  projectId: number;
  ico: string;
}

export interface ExportMessagesQuery {
  projectVersionIds: Array<number>;
  selectEmpty?: boolean;
  selectActive?: boolean;
  groupVersions?: boolean;
  search?: string;
  tags?: Array<string>;
}

export type ExportMessagesResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? ExportMessagesDataDto
    : any
  : any;

export interface ImportMessagesParams {
  projectId: number;
  ico: string;
}

export type ImportMessagesResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? ImportMessagesDataDto
    : any
  : any;

export type ImportMessagesRequest<
  T1 extends 'multipart/form-data' = 'multipart/form-data'
> = T1 extends 'multipart/form-data' ? ImportMessagesBodyDto : any;

export interface GetProjectLocaleKeyDataParams {
  projectId: number;
  ico: string;
  key: string;
}

export type GetProjectLocaleKeyDataResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetProjectLocaleKeyDataDataDto
    : any
  : any;

export interface UpdateProjectLocaleKeyDataParams {
  projectId: number;
  key: string;
  ico: string;
}

export type UpdateProjectLocaleKeyDataResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? UpdateProjectLocaleKeyDataBodyDto
    : any
  : any;

export type UpdateProjectLocaleKeyDataRequest<
  T1 extends 'application/json' = 'application/json'
> = T1 extends 'application/json' ? UpdateProjectLocaleKeyDataBodyDto : any;

export interface GetProjectKeysParams {
  projectId: number;
}

export type GetProjectKeysResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetProjectKeysDataDto
    : any
  : any;

export type GetUsingLocalesListResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetUsingLocalesListDataDto
    : any
  : any;

export interface GetActionsQuery {
  search?: string;
  userId?: number;
  authStatus?: AuthStatusEnum;
  section?: string;
  event?: string;
  field?: GetActionsFieldEnum;
  order?: OrderByEnum;
  limit?: number;
  offset?: number;
}

export type GetActionsResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetActionsDataDto
    : any
  : any;

export interface GetActionByIdParams {
  id: number;
}

export type GetActionByIdResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? FormActionDataDto
    : any
  : any;

export interface DeleteActionByIdParams {
  id: number;
}

export type DeleteActionByIdResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200 ? (T2 extends 'application/json' ? RowIdDataDto : any) : any;

export type GetActionNavigationsResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetActionNavigationsDataDto
    : any
  : any;

export interface GetProjectVersionLocalesParams {
  slug: string;
  param: string;
}

export type GetProjectVersionLocalesResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetProjectVersionLocalesDataDto
    : any
  : any;

export interface GetProjectVersionLocaleMessagesParams {
  slug: string;
  param: string;
  ico: string;
}

export type GetProjectVersionLocaleMessagesResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetProjectVersionLocaleMessagesDataDto
    : any
  : any;

export interface UpdateDefaultMessagesParams {
  slug: string;
  param: string;
}

export type UpdateDefaultMessagesResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? UpdateDefaultMessagesDataDto
    : any
  : any;

export type UpdateDefaultMessagesRequest<
  T1 extends 'application/json' = 'application/json'
> = T1 extends 'application/json' ? UpdateDefaultMessagesBodyDto : any;

export interface UpdateLocaleMessagesParams {
  slug: string;
  param: string;
  ico: string;
}

export type UpdateLocaleMessagesResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? UpdateProjectVersionLocaleMessagesDataDto
    : any
  : any;

export type UpdateLocaleMessagesRequest<
  T1 extends 'application/json' = 'application/json'
> = T1 extends 'application/json'
  ? UpdateProjectVersionLocaleMessagesBodyDto
  : any;

export type GetInfoResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetInfoDataDto
    : any
  : any;

export interface GetProjectVersionDefaultKeysParams {
  slug: string;
  param: string;
}

export type GetProjectVersionDefaultKeysResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetProjectVersionDefaultMessagesDataDto
    : any
  : any;

export type GetProfileResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetProfileDataDto
    : any
  : any;

export type UpdateProfileResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? UpdateProfileDataDto
    : any
  : any;

export type UpdateProfileRequest<
  T1 extends 'application/json' = 'application/json'
> = T1 extends 'application/json' ? UpdateProfileBodyDto : any;

export type ChangePasswordResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? ChangePasswordDataDto
    : any
  : any;

export type ChangePasswordRequest<
  T1 extends 'application/json' = 'application/json'
> = T1 extends 'application/json' ? ChangePasswordBodyDto : any;

export type LoadProfileImageResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? LoadProfileImageDataDto
    : any
  : any;

export type LoadProfileImageRequest<
  T1 extends 'multipart/form-data' = 'multipart/form-data'
> = T1 extends 'multipart/form-data' ? LoadProfileImageBodyDto : any;

export type UploadImageResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? UploadImageDataDto
    : any
  : any;

export type UploadImageRequest<
  T1 extends 'multipart/form-data' = 'multipart/form-data'
> = T1 extends 'multipart/form-data' ? UploadImageBodyDto : any;

export interface GetProjectVersionsQuery {
  projectId?: number;
  field?: GetProjectVersionsFieldEnum;
  order?: OrderByEnum;
  limit?: number;
  offset?: number;
}

export type GetProjectVersionsResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetProjectVersionsDataDto
    : any
  : any;

export interface GetProjectVersionsListQuery {
  projectId: number;
}

export type GetProjectVersionsListResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetProjectVersionsListDataDto
    : any
  : any;

export interface GetProjectVersionByIdParams {
  id: number;
}

export type GetProjectVersionByIdResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetProjectVersionByIdDataDto
    : any
  : any;

export interface GetProjectVersionStatsByIdParams {
  id: number;
}

export type GetProjectVersionStatsByIdResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetProjectVersionStatsByIdDataDto
    : any
  : any;

export type CreateProjectVersionResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200 ? (T2 extends 'application/json' ? RowIdDataDto : any) : any;

export type CreateProjectVersionRequest<
  T1 extends 'application/json' = 'application/json'
> = T1 extends 'application/json' ? CreateProjectVersionBodyDto : any;

export interface UpdateProjectVersionByIdParams {
  id: number;
}

export type UpdateProjectVersionByIdResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200 ? (T2 extends 'application/json' ? RowIdDataDto : any) : any;

export type UpdateProjectVersionByIdRequest<
  T1 extends 'application/json' = 'application/json'
> = T1 extends 'application/json' ? UpdateProjectVersionByIdBodyDto : any;

export interface DeleteProjectVersionByIdParams {
  id: number;
}

export type DeleteProjectVersionByIdResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200 ? (T2 extends 'application/json' ? RowIdDataDto : any) : any;

export interface GetMonitoringKeysUpdatesQuery {
  timezone: string;
  limit?: number;
  offset?: number;
}

export type GetMonitoringKeysUpdatesResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetMonitoringKeysUpdatesDataDto
    : any
  : any;

export interface GetMonitoringMessagesUpdatesQuery {
  timezone: string;
  limit?: number;
  offset?: number;
}

export type GetMonitoringMessagesUpdatesResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetMonitoringMessagesUpdatesDataDto
    : any
  : any;

export interface GetKeysUpdatesStatsQuery {
  timezone: string;
  fromDate?: string;
  toDate?: string;
}

export type GetKeysUpdatesStatsResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetKeysUpdatesStatsDataDto
    : any
  : any;

export interface GetMessagesUpdatesStatsQuery {
  timezone: string;
  fromDate?: string;
  toDate?: string;
}

export type GetMessagesUpdatesStatsResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetMessagesUpdatesStatsDataDto
    : any
  : any;

export interface GetProjectVersionSyncsStatsQuery {
  timezone: string;
  fromDate?: string;
  toDate?: string;
}

export type GetProjectVersionSyncsStatsResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetProjectVersionSyncsStatsDataDto
    : any
  : any;

export interface GetActionsStatsQuery {
  timezone: string;
  fromDate?: string;
  toDate?: string;
}

export type GetActionsStatsResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetActionsStatsDataDto
    : any
  : any;

export type GetDashboardStatsResponse<
  T1 extends 200 = 200,
  T2 extends 'application/json' = 'application/json'
> = T1 extends 200
  ? T2 extends 'application/json'
    ? GetDashboardStatsDataDto
    : any
  : any;

export type RefreshAuthBody = RefreshAuthRequest<'application/json'>;
export type RefreshAuthData = RefreshAuthResponse<200, 'application/json'>;
export type RefreshAuthError = RefreshAuthResponse<400, 'application/json'>;

export type LoginUserBody = LoginUserRequest<'application/json'>;
export type LoginUserData = LoginUserResponse<200, 'application/json'>;
export type LoginUserError = LoginUserResponse<400, 'application/json'>;

export type RegisterUserBody = RegisterUserRequest<'application/json'>;
export type RegisterUserData = RegisterUserResponse<200, 'application/json'>;
export type RegisterUserError = RegisterUserResponse<400, 'application/json'>;

export type GetSessionsData = GetSessionsResponse<200, 'application/json'>;
export type GetSessionsError = any;

export type DeleteSessionData = DeleteSessionResponse<200, 'application/json'>;
export type DeleteSessionError = any;

export type GetUsersData = GetUsersResponse<200, 'application/json'>;
export type GetUsersError = any;

export type GetUsersListData = GetUsersListResponse<200, 'application/json'>;
export type GetUsersListError = any;

export type GetUserByIdData = GetUserByIdResponse<200, 'application/json'>;
export type GetUserByIdError = any;

export type CreateUserBody = CreateUserRequest<'application/json'>;
export type CreateUserData = CreateUserResponse<200, 'application/json'>;
export type CreateUserError = any;

export type UpdateUserBody = UpdateUserRequest<'application/json'>;
export type UpdateUserData = UpdateUserResponse<200, 'application/json'>;
export type UpdateUserError = any;

export type DeleteUserData = DeleteUserResponse<200, 'application/json'>;
export type DeleteUserError = any;

export type GetLocalesListData = GetLocalesListResponse<
  200,
  'application/json'
>;
export type GetLocalesListError = any;

export type GetProjectsData = GetProjectsResponse<200, 'application/json'>;
export type GetProjectsError = any;

export type GetProjectsListData = GetProjectsListResponse<
  200,
  'application/json'
>;
export type GetProjectsListError = any;

export type GetProjectByIdData = GetProjectByIdResponse<
  200,
  'application/json'
>;
export type GetProjectByIdError = any;

export type GetProjectStatsByIdData = GetProjectStatsByIdResponse<
  200,
  'application/json'
>;
export type GetProjectStatsByIdError = any;

export type CreateProjectBody = CreateProjectRequest<'application/json'>;
export type CreateProjectData = CreateProjectResponse<200, 'application/json'>;
export type CreateProjectError = any;

export type UpdateProjectByIdBody = UpdateProjectByIdRequest<
  'application/json'
>;
export type UpdateProjectByIdData = UpdateProjectByIdResponse<
  200,
  'application/json'
>;
export type UpdateProjectByIdError = any;

export type DeleteProjectByIdData = DeleteProjectByIdResponse<
  200,
  'application/json'
>;
export type DeleteProjectByIdError = any;

export type SelectMessagesData = SelectMessagesResponse<
  200,
  'application/json'
>;
export type SelectMessagesError = any;

export type SearchMessagesData = SearchMessagesResponse<
  200,
  'application/json'
>;
export type SearchMessagesError = any;

export type ExportMessagesData = ExportMessagesResponse<
  200,
  'application/json'
>;
export type ExportMessagesError = any;

export type ImportMessagesBody = ImportMessagesRequest<'multipart/form-data'>;
export type ImportMessagesData = ImportMessagesResponse<
  200,
  'application/json'
>;
export type ImportMessagesError = any;

export type GetProjectLocaleKeyDataData = GetProjectLocaleKeyDataResponse<
  200,
  'application/json'
>;
export type GetProjectLocaleKeyDataError = any;

export type UpdateProjectLocaleKeyDataBody = UpdateProjectLocaleKeyDataRequest<
  'application/json'
>;
export type UpdateProjectLocaleKeyDataData = UpdateProjectLocaleKeyDataResponse<
  200,
  'application/json'
>;
export type UpdateProjectLocaleKeyDataError = any;

export type GetProjectKeysData = GetProjectKeysResponse<
  200,
  'application/json'
>;
export type GetProjectKeysError = any;

export type GetUsingLocalesListData = GetUsingLocalesListResponse<
  200,
  'application/json'
>;
export type GetUsingLocalesListError = any;

export type GetActionsData = GetActionsResponse<200, 'application/json'>;
export type GetActionsError = any;

export type GetActionByIdData = GetActionByIdResponse<200, 'application/json'>;
export type GetActionByIdError = any;

export type DeleteActionByIdData = DeleteActionByIdResponse<
  200,
  'application/json'
>;
export type DeleteActionByIdError = any;

export type GetActionNavigationsData = GetActionNavigationsResponse<
  200,
  'application/json'
>;
export type GetActionNavigationsError = any;

export type GetProfileData = GetProfileResponse<200, 'application/json'>;
export type GetProfileError = any;

export type UpdateProfileBody = UpdateProfileRequest<'application/json'>;
export type UpdateProfileData = UpdateProfileResponse<200, 'application/json'>;
export type UpdateProfileError = any;

export type ChangePasswordBody = ChangePasswordRequest<'application/json'>;
export type ChangePasswordData = ChangePasswordResponse<
  200,
  'application/json'
>;
export type ChangePasswordError = any;

export type LoadProfileImageBody = LoadProfileImageRequest<
  'multipart/form-data'
>;
export type LoadProfileImageData = LoadProfileImageResponse<
  200,
  'application/json'
>;
export type LoadProfileImageError = any;

export type UploadImageBody = UploadImageRequest<'multipart/form-data'>;
export type UploadImageData = UploadImageResponse<200, 'application/json'>;
export type UploadImageError = any;

export type GetProjectVersionsData = GetProjectVersionsResponse<
  200,
  'application/json'
>;
export type GetProjectVersionsError = any;

export type GetProjectVersionsListData = GetProjectVersionsListResponse<
  200,
  'application/json'
>;
export type GetProjectVersionsListError = any;

export type GetProjectVersionByIdData = GetProjectVersionByIdResponse<
  200,
  'application/json'
>;
export type GetProjectVersionByIdError = any;

export type GetProjectVersionStatsByIdData = GetProjectVersionStatsByIdResponse<
  200,
  'application/json'
>;
export type GetProjectVersionStatsByIdError = any;

export type CreateProjectVersionBody = CreateProjectVersionRequest<
  'application/json'
>;
export type CreateProjectVersionData = CreateProjectVersionResponse<
  200,
  'application/json'
>;
export type CreateProjectVersionError = any;

export type UpdateProjectVersionByIdBody = UpdateProjectVersionByIdRequest<
  'application/json'
>;
export type UpdateProjectVersionByIdData = UpdateProjectVersionByIdResponse<
  200,
  'application/json'
>;
export type UpdateProjectVersionByIdError = any;

export type DeleteProjectVersionByIdData = DeleteProjectVersionByIdResponse<
  200,
  'application/json'
>;
export type DeleteProjectVersionByIdError = any;

export type GetMonitoringKeysUpdatesData = GetMonitoringKeysUpdatesResponse<
  200,
  'application/json'
>;
export type GetMonitoringKeysUpdatesError = any;

export type GetMonitoringMessagesUpdatesData = GetMonitoringMessagesUpdatesResponse<
  200,
  'application/json'
>;
export type GetMonitoringMessagesUpdatesError = any;

export type GetKeysUpdatesStatsData = GetKeysUpdatesStatsResponse<
  200,
  'application/json'
>;
export type GetKeysUpdatesStatsError = any;

export type GetMessagesUpdatesStatsData = GetMessagesUpdatesStatsResponse<
  200,
  'application/json'
>;
export type GetMessagesUpdatesStatsError = any;

export type GetProjectVersionSyncsStatsData = GetProjectVersionSyncsStatsResponse<
  200,
  'application/json'
>;
export type GetProjectVersionSyncsStatsError = any;

export type GetActionsStatsData = GetActionsStatsResponse<
  200,
  'application/json'
>;
export type GetActionsStatsError = any;

export type GetDashboardStatsData = GetDashboardStatsResponse<
  200,
  'application/json'
>;
export type GetDashboardStatsError = any;
