import Fingerprint2 from 'fingerprintjs2';
import { UAParser } from 'ua-parser-js';

type RequestIdleCallbackHandle = any;
type RequestIdleCallbackOptions = {
  timeout: number;
};
type RequestIdleCallbackDeadline = {
  readonly didTimeout: boolean;
  timeRemaining: () => number;
};

declare global {
  interface Window {
    requestIdleCallback: (
      callback: (deadline: RequestIdleCallbackDeadline) => void,
      opts?: RequestIdleCallbackOptions,
    ) => RequestIdleCallbackHandle;
    cancelIdleCallback: (handle: RequestIdleCallbackHandle) => void;
  }
}

const fingerprint: Promise<string> = new Promise((resolve, reject) => {
  async function getHash() {
    const options = {
      excludes: {
        plugins: true,
        localStorage: true,
        adBlock: true,
        screenResolution: true,
        availableScreenResolution: true,
        enumerateDevices: true,
        pixelRatio: true,
        doNotTrack: true,
      },
      preprocessor: (key: any, value: any) => {
        if (key === 'userAgent') {
          const parser = new UAParser(value);
          // return customized user agent (without browser version)
          return `${parser.getOS().name} :: ${parser.getBrowser().name} :: ${
            parser.getEngine().name
          }`;
        }
        return value;
      },
    };

    try {
      const components = await Fingerprint2.getPromise(options);
      const values = components.map((component) => component.value);
      return String(Fingerprint2.x64hash128(values.join(''), 31));
    } catch (e) {
      reject(e);
    }
  }

  if (window.requestIdleCallback) {
    window.requestIdleCallback(async () => resolve(await getHash()));
  } else {
    setTimeout(async () => resolve(await getHash()), 500);
  }
});

export const getFingerprint = (): Promise<string> => {
  return fingerprint;
};
