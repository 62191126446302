import { useState } from 'react';

export const useActive = <T>(): [
  (key: T | null) => void,
  (key: T) => boolean,
] => {
  const [key, setKey] = useState<null | T>(null);

  const set = (key: T | null): void => {
    setKey(key);
  };

  const check = (posKey: T): boolean => {
    return key === posKey;
  };

  return [set, check];
};
