import React from 'react';

import { createCn } from 'bem-react-classname';
import cn from 'classnames';

import { KitTitle } from '../KitTitle';

import './KitTitleActions.scss';

interface Props {
  className?: string;
  title: string;
  children?: React.ReactNode;
}

const cx = createCn('KitTitleActions');

export const KitTitleActions: React.FC<Props> = ({
  children,
  className,
  title,
}) => {
  return (
    <div className={cn(className, cx())}>
      <div className={cx('title')}>
        <KitTitle>{title}</KitTitle>
      </div>

      <div className={cx('actions')}>{children}</div>
    </div>
  );
};
