import { useState } from 'react';

import { TablePaginationConfig } from 'antd/lib/table';
import { isEqual } from 'lodash-es';
import { useUpdateEffect } from 'react-use';

export type Pagenation = {
  total: number;
  offset: number;
  limit: number;
};

export type Nav = { limit: number; offset: number; total: number };

export const useNav = (
  nav?: Nav,
  initialValues?: {
    limit?: Nav['limit'];
    offset?: Nav['offset'];
  },
) => {
  const [localNav, setLocalNav] = useState<Omit<Nav, 'total'>>({
    limit: initialValues?.limit ?? nav?.limit ?? 10,
    offset: nav?.offset ?? 0,
  });

  useUpdateEffect(() => {
    if (nav && !isEqual(nav, localNav)) {
      setLocalNav(nav);
    }
  }, [nav?.offset, nav?.limit]);

  const total = nav?.total ?? 0;
  const offset = localNav.offset;
  const limit = localNav.limit;
  const pagesRatio = limit > 0 ? total / limit : 0;

  const pagesMax =
    pagesRatio > 0
      ? Number.isInteger(pagesRatio)
        ? pagesRatio
        : Math.ceil(pagesRatio)
      : 0;

  const page = limit > 0 ? Math.ceil(offset / limit) + 1 : 0;
  const prevPage = page - 1;
  const nextPage = page + 1;

  const changePage = (num: number) => {
    const newOffset = limit * (num - 1);

    setLocalNav({
      limit,
      offset: newOffset,
    });
  };

  const fromAntReq = (
    pagination: TablePaginationConfig | { current: number; pageSize: number },
  ): {
    limit: number;
    offset: number;
  } => {
    const limit = pagination.pageSize ?? nav?.limit ?? localNav.limit;

    return {
      limit,
      offset: ((pagination.current ?? 1) - 1) * limit,
    };
  };

  const toAntPagination = () => {
    return {
      total,
      pageSize: limit,
      current: page,
    };
  };

  const toShiftedReq = (num: number) => {
    if (num < 0 && total - offset * limit <= -num) {
      return {
        limit,
        offset: Math.max(0, offset - Math.ceil(offset / Math.abs(num))),
      };
    }

    return {
      limit,
      offset,
    };
  };

  return {
    initialValues,
    pagination: {
      pagesMax,
      page,
      prevPage,
      nextPage,
      total,
      offset,
      limit,
    },
    changePage,
    values: {
      offset,
      limit,
    },
    fromAntReq,
    toAntPagination,
    toShiftedReq,
  };
};
