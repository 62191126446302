import React from 'react';

import { Result, Button } from 'antd';
import { createCn } from 'bem-react-classname';
import { Link } from 'react-router-dom';
import { Kit } from 'ui/Kit';

const cx = createCn('Result403');

interface Props {}

export const Result403: React.FC<Props> = () => {
  return (
    <Result
      status="403"
      title="403"
      subTitle="Forbidden"
      extra={
        <Link to="/">
          <Button type="primary">Back Home</Button>{' '}
        </Link>
      }
      className={cx()}
    >
      <Kit.Title>You don't have permission</Kit.Title>
    </Result>
  );
};
