import React from 'react';

import { core } from 'core';
import { Switch, Route, Redirect } from 'react-router-dom';

import { MonitoringKeysUpdatesPage } from './pages/MonitoringKeysUpdatesPage';
import { MonitoringMessagesUpdatesPage } from './pages/MonitoringMessagesUpdatesPage';

export const MonitoringModule = () => {
  return (
    <Switch>
      <Route path={core.toPage('/keys')} exact>
        <MonitoringKeysUpdatesPage />
      </Route>

      <Route path={core.toPage('/messages')} exact>
        <MonitoringMessagesUpdatesPage />
      </Route>

      <Redirect to="/404" />
    </Switch>
  );
};
