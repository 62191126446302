import React from 'react';

import { Col, Row, Spin, Divider } from 'antd';
import { createCn } from 'bem-react-classname';
import { core } from 'core';
import { map } from 'lodash-es';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { ServerState } from 'store';
import { MessageCountBadge } from 'ui/MessageCountBadge';
import { ProjectLocale } from 'ui/ProjectLocale';

import './ProjectVersionInfo.scss';

const cx = createCn('ProjectVersionInfo');

interface Props {
  getProjectVersionByIdState: ServerState['getProjectVersionById'];
}

export const ProjectVersionInfo: React.FC<Props> = ({
  getProjectVersionByIdState,
}) => {
  return (
    <div className={cx()}>
      {getProjectVersionByIdState.isFetching &&
        !getProjectVersionByIdState.error && (
          <div className={cx('loader')}>
            <Spin spinning size="large" />
          </div>
        )}

      <Row style={{ alignItems: 'center' }}>
        <Col flex="1">
          <h3>
            Project version: {getProjectVersionByIdState.data?.version.name}
          </h3>
        </Col>

        <Col>
          <Col className={cx('infoActions')}>
            {/* <Kit.Action
              className={cx('infoAction')}
              title={`ProjectVersion info and stats`}
              text={'ProjectVersion info'}
              type={'info'}
              to={core.toPage(
                `/info/${getProjectVersionByIdState.data?.version.id}`,
              )}
            /> */}
          </Col>
        </Col>
      </Row>

      <Divider className={cx('divider')} />

      <Row className={cx('row')}>
        <div className={cx('detailRow')}>
          <div className={cx('detailColLabel')}>ID: </div>
          <div className={cx('detailColValue')}>
            {getProjectVersionByIdState.data?.version.id}
          </div>
        </div>

        <div className={cx('detailRow')}>
          <div className={cx('detailColLabel')}>Param: </div>
          <div className={cx('detailColValue')}>
            {getProjectVersionByIdState.data?.version.param}
          </div>
        </div>

        <div className={cx('detailRow')}>
          <div className={cx('detailColLabel')}>Created: </div>
          <div className={cx('detailColValue')}>
            {moment(getProjectVersionByIdState.data?.version.createdAt).format(
              'DD.MM.YYYY HH:mm:ss',
            )}
          </div>
        </div>

        <div className={cx('detailRow')}>
          <div className={cx('detailColLabel')}>Keys: </div>
          <div className={cx('detailColValue')}>
            <MessageCountBadge
              type="keys"
              count={getProjectVersionByIdState.data?.keys}
            />
          </div>
        </div>
      </Row>

      <Divider className={cx('divider')} />

      <Row className={cx('row')}>
        <Col flex="1" style={{ alignItems: 'center' }}>
          <Row style={{ alignItems: 'center' }}>
            <div className={cx('paramLabel')}>Languages:</div>
            {map(getProjectVersionByIdState.data?.locales, (locale) => (
              <NavLink
                exact={true}
                activeClassName="active"
                to={core.toPage(
                  `/manage/${getProjectVersionByIdState.data?.version.projectId}/messages/${locale.ico}`,
                )}
                key={locale.id}
              >
                <ProjectLocale ico={locale.ico} name={locale.enName} />
              </NavLink>
            ))}
          </Row>
        </Col>
        <Col></Col>
      </Row>
    </div>
  );
};
