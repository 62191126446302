import { AppThunk } from 'store';
import { toasts } from 'ui/toasts';

export const commonActionThunk = ({
  action,
  errorSelector,
  title,
  successMessage,
  confirm,
  confirmMessage,
}: {
  action: any;
  errorSelector: any;
  title: string;
  successMessage?: string;
  confirm?: boolean;
  confirmMessage?: string;
}): AppThunk => async (dispatch, getState) => {
  if (confirm && !window.confirm(confirmMessage || title)) {
    throw new Error('cancel');
  }

  await dispatch(action);

  const error = errorSelector(getState());

  toasts.result({
    title,
    error,
    successMessage,
  });
};
