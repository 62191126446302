import React, { useCallback, useState } from 'react';

import { SelectProps } from 'antd/lib/select';
import { useDispatch } from 'react-redux';
import { AppThunk, server } from 'store';
import { Kit } from 'ui/Kit';

interface Props extends SelectProps<any> {
  className?: string;
  value?: number | string;
}

export const UsersAutoComplete: React.FC<Props> = ({
  className,
  value,
  ...options
}) => {
  const dispatch = useDispatch();

  const [searchUsersOptions, setSearchUsersOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const onSearchUser = useCallback(
    (search: string) => {
      dispatch(
        ((): AppThunk => async (dispatch, getState) => {
          await dispatch(
            server.getUsersList.thunk.request({
              query: { search },
            }),
          );

          const { data } = server.getUsersList.selector.state(getState());

          if (data) {
            setSearchUsersOptions(
              data.list.map((item) => ({
                value: item.id.toString(),
                label: item.name,
              })),
            );
          }
        })(),
      );
    },
    [dispatch],
  );

  return (
    <Kit.AutoComplete
      {...options}
      options={searchUsersOptions}
      onSearch={onSearchUser}
      value={value}
    />
  );
};
