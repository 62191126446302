import React, { useCallback, useEffect, useRef } from 'react';

import { Spin } from 'antd';
import { GetKeysUpdatesStatsQuery } from 'api/server';
import { createCn } from 'bem-react-classname';
import Chart from 'chart.js';
import cn from 'classnames';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunk, server } from 'store';

import { getDashboardTimeChartConfig } from '../../utils/chartOptionsUtil';

import './KeysUpdatesStatsChart.scss';

const cx = createCn('KeysUpdatesStatsChart');

interface Props {
  className?: string;
  header?: string;
  fromDate: string;
}

export const KeysUpdatesStatsChart: React.FC<Props> = ({
  className,
  header,
  fromDate,
}) => {
  const dispatch = useDispatch();
  const chartRef = useRef<HTMLCanvasElement>(null);

  const getKeysUpdatesStatsThunk = useCallback(
    (query: GetKeysUpdatesStatsQuery): AppThunk => async (
      dispatch,
      getState,
    ) => {
      await dispatch(
        server.getKeysUpdatesStats.thunk.request({
          query,
        }),
      );

      const { data } = server.getKeysUpdatesStats.selector.state(getState());

      if (data && chartRef.current) {
        new Chart(
          chartRef.current,
          getDashboardTimeChartConfig({
            borderColor: '#53a4ee',
            yLabelString: 'Message keys',
            data: data.list.map((item) => ({
              x: item.date,
              y: item.amount,
            })),
          }),
        );
      }
    },
    [chartRef],
  );

  const { isFetching, isFetched } = useSelector(
    server.getKeysUpdatesStats.selector.state,
  );

  useEffect(() => {
    dispatch(
      getKeysUpdatesStatsThunk({
        timezone: moment.tz.guess(true),
        fromDate: moment(fromDate).startOf('day').format(),
      }),
    );
  }, [dispatch, fromDate, getKeysUpdatesStatsThunk]);

  return (
    <div className={cn(className, cx())}>
      <div className={cx('container')}>
        {header && (
          <div className={cx('header')}>
            <h3>{header}</h3>
          </div>
        )}

        <div className={cx('graphArea')}>
          {isFetching && !isFetched && (
            <div className={cx('spin')}>
              <Spin size="large" />
            </div>
          )}
          <canvas ref={chartRef} />
        </div>
      </div>
    </div>
  );
};
