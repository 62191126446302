import React, { useState, useCallback, useMemo } from 'react';

import { ReloadOutlined, ClearOutlined } from '@ant-design/icons';
import { faCodeBranch, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Table, Input, Pagination, Switch, Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import {
  OrderByEnum,
  SelectMessagesFieldEnum,
  SelectMessagesItemDto,
  SelectMessagesQuery,
  serverApi,
} from 'api/server';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import {
  useFilter,
  useNav,
  usePermissions,
  useRequest,
  useUiMemory,
} from 'hooks';
import { useStateDebounce, useSort } from 'hooks';
import {
  toInteger,
  toString,
  orderBy,
  keys,
  groupBy,
  sortBy,
  last,
  first,
  pick,
} from 'lodash-es';
import { MessageDefaultKey } from 'modules/projects/shared/MessageDefaultKey';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useLifecycles } from 'react-use';
import { server } from 'store';
import { Kit } from 'ui/Kit';
import { ProjectVersionColor } from 'ui/ProjectVersionColor';
import { tablePaginationConfig, shorten } from 'utils';

import { EditMessagesFormModal } from '../../shared/EditMessagesFormModal';
import { ProjectInfo } from '../../shared/ProjectInfo/ProjectInfo';
import { ProjectsMenu } from '../../shared/ProjectsMenu';
import { VersionMessageCreatedAt } from '../../shared/VersionMessageCreatedAt';

import { ExportMessagesFormModal } from './ExportMessagesFormModal';
import { ImportMessagesFormModal } from './ImportMessagesFormModal';
import { toTagsOptions } from './ManageMessagesPageUtils';

import './ManageMessagesPage.scss';

const cx = createCn('ManageMessagesPage');

interface Props {}

export const ManageMessagesPage: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string; ico: string }>();
  const projectId = toInteger(params.id);
  const ico = toString(params.ico);
  const [isExportModal, setIsExportModal] = useState<boolean>(false);
  const [isImportModal, setIsImportModal] = useState<boolean>(false);
  const permissions = usePermissions();

  const [editKey, setEditKey] = useState<string | null>(null);

  const filter = useFilter<SelectMessagesQuery>({
    search: '',
    selectEmpty: false,
    selectActive: false,
    tags: [],
  });

  const [selectMessagesDebounce, selectMessagesClear] = useStateDebounce();

  const selectMessages = useCallback(
    async (query: SelectMessagesQuery, delay?: number) => {
      selectMessagesDebounce(() => {
        dispatch(
          server.selectMessages.thunk.request({
            params: { projectId, ico },
            query,
          }),
        );
      }, delay);
    },
    [selectMessagesDebounce, dispatch, projectId, ico],
  );

  const selectMessagesState = useSelector(server.selectMessages.selector.state);

  const [memoryLimit, setMemoryLimit] = useUiMemory(
    'table.locale_messages_list.nav.limit',
    tablePaginationConfig.defaultPageSize,
  );

  const nav = useNav(selectMessagesState.data?.nav, {
    limit: memoryLimit,
  });

  const sorting = useSort<SelectMessagesFieldEnum>(
    selectMessagesState.data?.sort,
    {
      field: SelectMessagesFieldEnum.Key,
      order: OrderByEnum.Asc,
    },
  );

  const onChangeSearch = useCallback(
    (event: any) => {
      const search = event.target.value.substr(0, 64);

      selectMessages(
        {
          ...filter.asset({ search }),
          ...nav.initialValues,
          ...sorting.initialValues,
        },
        800,
      );
    },
    [filter, nav.initialValues, selectMessages, sorting.initialValues],
  );

  const onChangeTags = useCallback(
    (value: SelectValue, option: any) => {
      const tags = (value || undefined) as string[] | undefined;

      selectMessages(
        {
          ...filter.asset({ tags }),
          ...nav.initialValues,
          ...sorting.initialValues,
        },
        0,
      );
    },
    [filter, nav.initialValues, selectMessages, sorting.initialValues],
  );

  const getProjectKeys = useRequest(serverApi.getProjectKeys);

  useLifecycles(
    () => {
      selectMessages({
        ...nav.initialValues,
        ...sorting.initialValues,
      });
      dispatch(
        server.getProjectById.thunk.request({
          params: { id: projectId },
        }),
      );

      getProjectKeys.request({ projectId });
    },
    () => {
      selectMessagesClear();
      dispatch(server.getProjectById.action.reset());
      dispatch(server.selectMessages.action.reset());
    },
  );

  const tagsOptions = useMemo(
    () => toTagsOptions(getProjectKeys.state.data?.list, filter.values.tags),
    [getProjectKeys.state, filter.values.tags],
  );

  const periodCompare = (first: string | null, last: string | null) => {
    return (
      first &&
      last &&
      moment(first).format('YYYY.MM.DD') !== moment(last).format('YYYY.MM.DD')
    );
  };

  const getProjectByIdState = useSelector(server.getProjectById.selector.state);

  const list = selectMessagesState.data?.list || [];

  type GroupedRow = {
    defaultMessageKey: string;
    groupKey: number;
    comment: SelectMessagesItemDto['comment'];
    defaultValue: string;
    value: string;
    rowSpan?: number;
    firstCreatedAt: string | null;
    lastCreatedAt: string | null;
    params: {
      param: string;
      isFeature: boolean;
      isDeprecated: boolean;
      name: string;
    }[];
    isFirst: boolean;
    differences: number;
  };

  const versionsMessagesList: GroupedRow[] = [];

  for (const row of list) {
    const groupedVersions = groupBy(
      orderBy(
        row.details,
        [
          (detail) => detail.projectVersion.param === 'feature',
          'isDeprecated',
          'createdAt',
        ],
        ['desc', 'asc', 'asc'],
      ),
      (detail) =>
        `${detail.defaultMessage.value.trim()}|${detail.localeMessage.value.trim()}`,
    );
    let differences = keys(groupedVersions).length;
    let isFirst = true;
    let rowSpan = differences > 1 ? differences : undefined;

    for (const group in groupedVersions) {
      const details = groupedVersions[group];

      const createdAts = details
        .map((detail) => detail.defaultMessage.createdAt)
        .sort();

      versionsMessagesList.push({
        groupKey: details[0].localeMessage.id,
        defaultMessageKey: row.defaultMessageKey,
        comment: row.comment,
        defaultValue: details[0].defaultMessage.value || '',
        value: details[0].localeMessage.value || '',
        firstCreatedAt: first(sortBy(createdAts)) ?? null,
        lastCreatedAt: last(sortBy(createdAts)) ?? null,
        rowSpan,
        isFirst,
        differences,

        params: details.map((detail) => ({
          param: detail.projectVersion.param,
          name: detail.projectVersion.name,
          createdAt: detail.projectVersion.createdAt,
          isFeature: detail.projectVersion.param === 'feature',
          isDeprecated: detail.defaultMessage.isDeprecated,
        })),
      });
      rowSpan = 0;
      isFirst = false;
    }
  }

  const aggFetching =
    selectMessagesState.isFetching || getProjectByIdState.isFetching;

  return (
    <div className={cn(cx())}>
      <ProjectsMenu />

      <Kit.TitleActions
        title={`Project #${projectId} / manage messages [ ${ico} ]`}
      >
        <Kit.Action
          type={'import'}
          text={'Import'}
          disabled={aggFetching || !permissions.has('MESSAGES_IMPORT')}
          onClick={() => {
            setIsImportModal(true);
          }}
        />

        <Kit.Action
          type={'export'}
          text={'Export'}
          disabled={aggFetching || !permissions.has('MESSAGES_EXPORT')}
          onClick={() => {
            setIsExportModal(true);
          }}
        />
      </Kit.TitleActions>

      <Kit.LoadContainer
        error={getProjectByIdState.error}
        isFetched={getProjectByIdState.isFetched}
        isFetching={getProjectByIdState.isFetching}
      >
        <div className={cx('info')}>
          <ProjectInfo getProjectByIdState={getProjectByIdState} />
        </div>

        <Kit.FilterGrid>
          <Kit.FilterCol>
            <Input
              placeholder="Search query"
              allowClear
              onChange={onChangeSearch}
              readOnly={selectMessagesState.isFetching}
              value={filter.values.search}
            />
          </Kit.FilterCol>

          <Kit.FilterCol>
            <Select
              mode="multiple"
              placeholder="Tags"
              onChange={onChangeTags}
              value={filter.values.tags}
              options={tagsOptions}
            />
          </Kit.FilterCol>

          <Kit.FilterCol switcher>
            <Switch
              onChange={(selectEmpty) => {
                selectMessages({
                  ...filter.asset({ selectEmpty }),
                  ...nav.initialValues,
                  ...sorting.initialValues,
                });
              }}
              disabled={selectMessagesState.isFetching}
              checked={filter.values.selectEmpty}
            />
            <div>Select empty</div>
          </Kit.FilterCol>

          <Kit.FilterCol switcher>
            <Switch
              onChange={(selectActive) => {
                selectMessages({
                  ...filter.asset({ selectActive }),
                  ...nav.initialValues,
                  ...sorting.initialValues,
                });
              }}
              disabled={selectMessagesState.isFetching}
              checked={filter.values.selectActive}
            />
            <div>Select active</div>
          </Kit.FilterCol>

          <Kit.FilterCol handler>
            <Button
              type="default"
              onClick={() => {
                selectMessages({
                  ...filter.reset(),
                  ...nav.initialValues,
                  ...sorting.initialValues,
                });
              }}
              className={cn('ant-reset-button')}
              icon={<ClearOutlined />}
              disabled={selectMessagesState.isFetching || filter.isInitial}
            >
              <span>Reset</span>
            </Button>
            <Button
              icon={<ReloadOutlined />}
              onClick={() =>
                selectMessages({
                  ...filter.values,
                  ...nav.values,
                  ...sorting.values,
                })
              }
            >
              Refresh
            </Button>
          </Kit.FilterCol>
        </Kit.FilterGrid>

        <Table
          dataSource={versionsMessagesList}
          columns={[
            {
              key: 'key',
              title: 'Key',
              render: (row: GroupedRow) => ({
                children: <MessageDefaultKey value={row.defaultMessageKey} />,
                props: {
                  rowSpan: row.rowSpan,
                },
              }),
              width: 180,
              sorter: true,
              sortOrder: sorting.toAntOrder(SelectMessagesFieldEnum.Key),
            },

            {
              key: 'differences',
              title: '',
              width: 40,
              render: (row: GroupedRow) => ({
                children: (
                  <>
                    {row.differences > 1 && (
                      <div className={cx('cellDifferences')}>
                        <FontAwesomeIcon
                          className={cx('iconDifferences')}
                          icon={faCodeBranch}
                        />
                      </div>
                    )}
                  </>
                ),
                props: {
                  rowSpan: row.rowSpan,
                },
              }),
            },

            {
              key: 'versions',
              title: 'Versions',
              width: 260,
              render: (row: GroupedRow) => (
                <div>
                  {row.params.map((param) => (
                    <ProjectVersionColor
                      key={param.param}
                      param={param.param}
                      isDeprecated={param.isDeprecated}
                      title={param.name}
                    />
                  ))}
                </div>
              ),
            },
            {
              key: 'defaultValue',
              width: 260,
              title: 'Default message',
              render: (row: GroupedRow) => (
                <div className={cx('cellDefaultValue')}>{row.defaultValue}</div>
              ),
            },
            {
              key: 'value',
              title: 'Translated messages',
              width: 260,
              render: (row: GroupedRow) => (
                <div className={cx('cell', { message: true })}>
                  {row.value.trim() ? (
                    <span>{row.value}</span>
                  ) : (
                    <div className={cx('emptyValue')}>
                      <FontAwesomeIcon
                        className={cx('iconEmptyValue')}
                        icon={faEllipsisH}
                      />
                    </div>
                  )}
                </div>
              ),
            },

            {
              key: 'comment',
              title: 'Comment',
              render: (row: GroupedRow) => ({
                children: (
                  <div className={cx('keyComment')}>
                    {row.comment.value && (
                      <>* {shorten(row.comment.value || '', 120)}</>
                    )}
                  </div>
                ),
                props: {
                  rowSpan: row.rowSpan,
                },
              }),
              width: 140,
            },

            {
              key: 'createdAt',
              width: 90,
              title: 'Created',
              render: (row: GroupedRow) => ({
                children: (
                  <>
                    <VersionMessageCreatedAt createdAt={row.firstCreatedAt} />
                    {periodCompare(row.lastCreatedAt, row.firstCreatedAt) && (
                      <>
                        <div className={cx('createdAtDirection')}>~</div>
                        <VersionMessageCreatedAt
                          createdAt={row.lastCreatedAt}
                        />
                      </>
                    )}
                  </>
                ),
                props: {
                  rowSpan: row.rowSpan,
                },
              }),

              sorter: true,
              sortOrder: sorting.toAntOrder(SelectMessagesFieldEnum.CreatedAt),
            },

            {
              title: null,
              key: 'actions',
              render: (row: GroupedRow) => ({
                children: (
                  <>
                    <Kit.Actions className={cx('rowActions')}>
                      <Kit.Action
                        title={'Edit version messages'}
                        type={'edit'}
                        disabled={aggFetching}
                        onClick={() => {
                          setEditKey(row.defaultMessageKey);
                        }}
                      />
                    </Kit.Actions>
                  </>
                ),
                props: {
                  rowSpan: row.rowSpan,
                },
              }),
              width: 45,
              align: 'right' as 'right',
              fixed: 'right' as 'right',
            },
          ]}
          scroll={{
            x: '100%',
          }}
          showSorterTooltip={false}
          loading={aggFetching}
          rowKey={(row: GroupedRow) => row.groupKey}
          onChange={(pagination, filters, sorter) => {
            setMemoryLimit(pagination.pageSize as number);

            selectMessages({
              ...filter.values,
              ...nav.initialValues,
              ...sorting.fromAntReq(sorter),
            });
          }}
          pagination={false}
        ></Table>

        <div className={cx('pagination')}>
          {selectMessagesState.isFetched && (
            <Pagination
              disabled={aggFetching}
              {...{
                ...tablePaginationConfig,
                ...nav.toAntPagination(),
              }}
              onChange={(current, pageSize) => {
                setMemoryLimit(pageSize as number);

                selectMessages({
                  ...filter.values,
                  ...sorting.values,
                  ...nav.fromAntReq({
                    current,
                    pageSize,
                  }),
                });
              }}
            />
          )}
        </div>

        <EditMessagesFormModal
          visible={editKey !== null}
          projectId={projectId}
          ico={ico}
          editKey={editKey as NonNullable<typeof editKey>}
          close={() => {
            setEditKey(null);
          }}
          success={() => {
            selectMessages({
              ...filter.values,
              ...nav.values,
              ...sorting.values,
            });
          }}
        />
      </Kit.LoadContainer>

      <ExportMessagesFormModal
        visible={isExportModal}
        projectId={projectId}
        ico={ico}
        getProjectByIdState={getProjectByIdState}
        close={() => {
          setIsExportModal(false);
        }}
        success={() => {}}
        initialFilter={pick(filter.values, [
          'search',
          'tags',
          'selectEmpty',
          'selectActive',
        ])}
        tags={getProjectKeys.state.data?.list ?? []}
      />

      <ImportMessagesFormModal
        visible={isImportModal}
        projectId={projectId}
        ico={ico}
        getProjectByIdState={getProjectByIdState}
        close={() => {
          setIsImportModal(false);
        }}
        success={() => {
          selectMessages({
            ...filter.values,
            ...nav.values,
            ...sorting.values,
          });
        }}
      />
    </div>
  );
};
