import React from 'react';

import { usePermissions } from 'hooks';
import { Redirect } from 'react-router-dom';

interface Props {}

export const IndexPage: React.FC = () => {
  const permissions = usePermissions();

  return (
    <Redirect
      to={permissions.has('DASHBOARD_MODULE') ? '/dashboard' : '/home'}
    />
  );
};
