import React, { useCallback } from 'react';

import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Form, Input, Button, Checkbox, Row } from 'antd';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { core } from 'core';
import { useFormError } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { app, AppThunk } from 'store';
import { server } from 'store';
import { loginThunk } from 'thunks';
import { Kit } from 'ui/Kit';
import { toasts } from 'ui/toasts';

import './LoginPage.scss';

const cx = createCn('LoginPage');

export const LoginPage: React.FC = () => {
  const dispatch = useDispatch();

  const initialValues = { remember: true, email: '', password: '' };

  const onFinish = useCallback(
    (values: typeof initialValues) => {
      dispatch(
        ((): AppThunk => async (dispatch, getState) => {
          const { email, password, remember } = values;

          await dispatch(
            loginThunk({
              email,
              password,
              remember,
            }),
          );

          const { error } = server.loginUser.selector.state(getState());

          toasts.result({
            title: 'Login',
            error,
            successMessage: 'You have successfully logged',
            // exceptParams: keys(initialValues),
          });
        })(),
      );
    },
    [dispatch, initialValues],
  );

  const authState = useSelector(app.auth.selector.state);

  const loginUserState = useSelector(server.loginUser.selector.state);
  const disabled = loginUserState.isFetching;
  const formError = useFormError(loginUserState.error);

  if (authState.isLogged) {
    return <Redirect to="/" />;
  }
  return (
    <div className={cn(cx())}>
      <div className={cx('formContainer')}>
        <div className={cx('block')}>
          <div className={cx('header')}>
            <Kit.Title>Login</Kit.Title>
          </div>

          <div className={cx('body')}>
            <div className={cx('formWrap')}>
              <Form
                name="login"
                className={cn(cx('form'))}
                initialValues={initialValues}
                onFinish={onFinish}
              >
                <Form.Item
                  name="email"
                  validateStatus={formError.status('email')}
                  help={formError.note('email')}
                >
                  <Input
                    prefix={<UserOutlined />}
                    placeholder="Email"
                    size={'large'}
                    disabled={disabled}
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  validateStatus={formError.status('password')}
                  help={formError.note('password')}
                >
                  <Input
                    prefix={<LockOutlined />}
                    type="password"
                    placeholder="Password"
                    size={'large'}
                    disabled={disabled}
                  />
                </Form.Item>

                <Row justify="space-between" style={{ alignItems: 'center' }}>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item>

                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    size={'large'}
                    disabled={disabled}
                  >
                    Log in
                  </Button>
                </Row>
              </Form>
            </div>

            <div className={cx('links')}>
              <Link to={core.toPage('/register')}>Create an account</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
