import React from 'react';

import { Form, Input, Button, Select } from 'antd';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { useFormError } from 'hooks';
import { map } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { useLifecycles } from 'react-use';
import { server } from 'store';
import { AnyValues } from 'types';
import { Kit } from 'ui/Kit';

import './ProjectVersionForm.scss';

const cx = createCn('ProjectVersionForm');

interface Props {
  disabled?: boolean;
  onFinish: (values: any) => void;
  formError: ReturnType<typeof useFormError>;
  action: 'create' | 'edit';
  initialValues:
    | AnyValues<
        Parameters<typeof server.createProjectVersion.thunk.request>[0]['body']
      >
    | AnyValues<
        Parameters<
          typeof server.updateProjectVersionById.thunk.request
        >[0]['body']
      >;
  options?: {
    canChangeParam?: boolean;
  };
}

export const ProjectVersionForm: React.FC<Props> = ({
  disabled,
  onFinish,
  formError,
  // action,
  initialValues,
  options,
}) => {
  const dispatch = useDispatch();

  const defOptions = { canChangeParam: true, ...options };

  useLifecycles(
    () => {
      dispatch(server.getLocalesList.thunk.request());
    },
    () => {
      dispatch(server.getLocalesList.action.reset());
      dispatch(server.createProjectVersion.action.reset());
      dispatch(server.updateProjectVersionById.action.reset());
    },
  );

  const getLocalesListState = useSelector(server.getLocalesList.selector.state);

  const aggDisabled = disabled || getLocalesListState.isFetching;

  /// disable

  return (
    <div className={cn(cx())}>
      <Kit.ErrorContainer error={getLocalesListState.error}>
        <Kit.FormContainer>
          <Form
            name="form"
            className={cn(cx('form'))}
            initialValues={initialValues}
            onFinish={onFinish}
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
          >
            <Form.Item
              name="name"
              validateStatus={formError.status('name')}
              help={formError.note('name')}
              label="Name"
              labelAlign={'left'}
            >
              <Input size={'middle'} disabled={aggDisabled} />
            </Form.Item>

            <Form.Item
              name="param"
              validateStatus={formError.status('param')}
              label="Param"
              labelAlign={'left'}
            >
              <Input
                size={'middle'}
                disabled={aggDisabled}
                readOnly={!defOptions.canChangeParam}
              />
            </Form.Item>

            <Form.Item name={'localeIds'} label="Languages" labelAlign={'left'}>
              <Select
                mode="multiple"
                optionFilterProp="title"
                className={'ant-select-block-options'}
              >
                {map(getLocalesListState.data?.list, (locale) => (
                  <Select.Option
                    key={locale.id}
                    value={locale.id}
                    title={locale.name}
                  >
                    {locale.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              validateStatus={formError.status()}
              help={formError.note() && formError.emptyDetails()}
              wrapperCol={{ offset: 12, span: 12 }}
            >
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size={'large'}
                disabled={aggDisabled}
              >
                Save
              </Button>
            </Form.Item>

            <Form.Item name="projectId" noStyle>
              <Input type="hidden" value={initialValues.projectId} />
            </Form.Item>
          </Form>
        </Kit.FormContainer>
      </Kit.ErrorContainer>
    </div>
  );
};
