class Core {
  private modulePath: string = '';

  public fromModule(modulePath: string): void {
    this.modulePath = modulePath;
  }

  public toPage(subPath: string | void = ''): string {
    return `${this.modulePath}${subPath}`;
  }
}

export const core = new Core();
