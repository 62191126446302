import { useState } from 'react';

import { OrderByEnum } from 'api/server';
import { isEqual } from 'lodash-es';
import { useUpdateEffect } from 'react-use';

export type Sort<Field> = { field?: Field; order?: OrderByEnum };

export const useSort = <Field>(
  values: Sort<Field> = {},
  initialValues: Sort<Field> = {},
) => {
  const [localSort, setLocalSort] = useState<Sort<Field>>({
    field: initialValues?.field ?? values.field,
    order: initialValues?.order ?? values.order,
  });

  useUpdateEffect(() => {
    if (!isEqual(values, localSort)) {
      setLocalSort(values);
    }
  }, [values.order, values.field]);

  const fromAntReq = (sorter: any): any => {
    const order = sorter.order?.replace('end', '') as OrderByEnum | undefined;

    return {
      field: sorter.columnKey,
      order:
        order ||
        (localSort.order === OrderByEnum.Desc
          ? OrderByEnum.Asc
          : OrderByEnum.Desc),
    };
  };

  const isAntChanged = (sorter: any): boolean =>
    localSort.field !== sorter.columnKey ||
    localSort.order !== sorter.order?.replace('end', '');

  const toAntOrder = (field: Field): any => {
    return values.field && values.order && values.field === field
      ? `${values.order}end`
      : undefined;
  };

  return {
    values,
    initialValues,
    fromAntReq,
    isAntChanged,
    toAntOrder,
  };
};
