import { uniq } from 'lodash-es';

export const toTagsOptions = (
  list: string[] = [],
  filterTags: string[] = [],
) => [
  ...uniq(
    list
      .filter((key) =>
        filterTags && filterTags.length > 0
          ? filterTags.every((tag) =>
              new RegExp(`(^|\\.)${tag}(\\.|$)`).test(key),
            )
          : true,
      )
      .reduce(
        (tags: string[], key: string) => tags.concat(...key.split('.')),
        [],
      ),
  ).map((tag) => ({
    label: tag,
    value: tag,
  })),
];
