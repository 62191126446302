import React, { useCallback } from 'react';

import { ReloadOutlined, ClearOutlined } from '@ant-design/icons';
import { Table, Button, Input, Select } from 'antd';
import {
  GetUsersFieldEnum,
  GetUsersQuery,
  OrderByEnum,
  UserRoleEnum,
  UserStateEnum,
} from 'api/server';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { core } from 'core';
import {
  useActive,
  useFilter,
  useNav,
  useUiMemory,
  useSort,
  useStateDebounce,
} from 'hooks';
import { keys } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useLifecycles } from 'react-use';
import { app, AppThunk, server, ServerState } from 'store';
import { Kit } from 'ui/Kit';
import { toasts } from 'ui/toasts';
import {
  timestampToFormat,
  tablePaginationConfig,
  getAccessibleRoles,
} from 'utils';

import { UsersMenu } from '../../shared/UsersMenu';

import './UsersListPage.scss';

const cx = createCn('UsersListPage');

type Row = NonNullable<ServerState['getUsers']['data']>['list'][number];

export const UsersListPage = () => {
  const dispatch = useDispatch();
  const [setDeleting, checkDeleting] = useActive<number>();

  const filter = useFilter<GetUsersQuery>({
    role: undefined,
    search: '',
    state: undefined,
  });

  const [getUsersDebounce, getUsersClear] = useStateDebounce();

  const getUsers = useCallback(
    async (query: GetUsersQuery, delay?: number) => {
      getUsersDebounce(() => {
        dispatch(
          server.getUsers.thunk.request({
            query,
          }),
        );
      }, delay);
    },
    [dispatch, getUsersDebounce],
  );

  const getUsersState = useSelector(server.getUsers.selector.state);

  const [memoryLimit, setMemoryLimit] = useUiMemory(
    'table.users_list.nav.limit',
    tablePaginationConfig.defaultPageSize,
  );

  const nav = useNav(getUsersState.data?.nav, {
    limit: memoryLimit,
  });

  const sorting = useSort<GetUsersFieldEnum>(getUsersState.data?.sort, {
    field: GetUsersFieldEnum.Id,
    order: OrderByEnum.Asc,
  });

  const onChangeSearch = useCallback(
    (event: any) => {
      const search = event.target.value.substr(0, 64);

      getUsers(
        {
          ...filter.asset({ search }),
          ...nav.initialValues,
          ...sorting.initialValues,
        },
        800,
      );
    },
    [filter, getUsers, nav.initialValues, sorting.initialValues],
  );

  const onChangeRole = useCallback(
    (role: UserRoleEnum | '') => {
      getUsers({
        ...filter.asset({ role: role || undefined }),
        ...nav.initialValues,
        ...sorting.initialValues,
      });
    },
    [filter, getUsers, nav.initialValues, sorting.initialValues],
  );

  const onChangeState = useCallback(
    (state: UserStateEnum | '') => {
      getUsers({
        ...filter.asset({ state: state || undefined }),
        ...nav.initialValues,
        ...sorting.initialValues,
      });
    },
    [filter, getUsers, nav.initialValues, sorting.initialValues],
  );

  const deleteUser = useCallback(
    async (id: number) => {
      dispatch(
        ((): AppThunk => async (dispatch, getState) => {
          try {
            setDeleting(id);

            if (!window.confirm('Are you sure?')) {
              throw new Error('cancel');
            }

            await dispatch(
              server.deleteUser.thunk.request({
                params: { id },
              }),
            );

            const { error } = server.deleteUser.selector.state(getState());

            toasts.result({
              title: 'Delete user',
              error,
            });

            if (!error) {
              await getUsers({
                ...filter.values,
                ...nav.toShiftedReq(-1),
              });
            } else {
              await getUsers({
                ...filter.values,
                ...nav.values,
                ...sorting.values,
              });
            }
          } catch {
          } finally {
            setDeleting(null);
          }
        })(),
      );
    },
    [dispatch, setDeleting, getUsers, filter.values, nav, sorting.values],
  );

  useLifecycles(
    () => {
      getUsers({ ...nav.initialValues, ...sorting.initialValues });
    },
    () => {
      getUsersClear();
      dispatch(server.getUsers.action.reset());
      dispatch(server.deleteUser.action.reset());
    },
  );

  const deleteUserState = useSelector(server.deleteUser.selector.state);
  const authState = useSelector(app.auth.selector.state);

  const aggDisabled = deleteUserState.isFetching;

  const accessibleRoles = getAccessibleRoles(authState.data?.user?.role);

  const userRoleOptions = [
    {
      label: '',
      value: '',
    },
    ...keys(UserRoleEnum)
      .filter((userRole) =>
        accessibleRoles.includes(
          UserRoleEnum[userRole as keyof typeof UserRoleEnum],
        ),
      )
      .map((userRole) => ({
        label: userRole,
        value: UserRoleEnum[userRole as keyof typeof UserRoleEnum],
      })),
  ];

  const userStateOptions = [
    {
      label: '',
      value: '',
    },
    ...keys(UserStateEnum).map((userState) => ({
      label: userState,
      value: UserStateEnum[userState as keyof typeof UserStateEnum],
    })),
  ];

  const columns = [
    {
      title: 'ID',
      key: GetUsersFieldEnum.Id,
      width: 60,
      render: (row: Row) => (
        <>
          <NavLink to={core.toPage(`/info/${row.user.id}`)}>
            {row.user.id}
          </NavLink>
        </>
      ),
      sorter: true,
      sortOrder: sorting.toAntOrder(GetUsersFieldEnum.Id),
    },
    {
      title: 'First name',
      key: GetUsersFieldEnum.FirstName,
      width: 120,
      render: (row: Row) => <>{row.user.firstName}</>,
      sorter: true,
      sortOrder: sorting.toAntOrder(GetUsersFieldEnum.FirstName),
    },
    {
      title: 'Last name',
      key: GetUsersFieldEnum.LastName,
      width: 120,
      render: (row: Row) => <>{row.user.lastName}</>,
      sorter: true,
      sortOrder: sorting.toAntOrder(GetUsersFieldEnum.LastName),
    },
    {
      title: 'Email',
      key: GetUsersFieldEnum.Email,
      width: 200,
      render: (row: Row) => <>{row.user.email}</>,
      sorter: true,
      sortOrder: sorting.toAntOrder(GetUsersFieldEnum.Email),
    },
    {
      title: 'Role',
      key: GetUsersFieldEnum.Role,
      width: 120,
      render: (row: Row) => <>{row.user.role}</>,
      sorter: true,
      sortOrder: sorting.toAntOrder(GetUsersFieldEnum.Role),
    },
    {
      title: 'State',
      key: GetUsersFieldEnum.State,
      width: 120,
      render: (row: Row) => <>{row.user.state}</>,
      sorter: true,
      sortOrder: sorting.toAntOrder(GetUsersFieldEnum.State),
    },
    {
      key: 'createdAt',
      width: 90,
      title: 'Created',
      render: (row: Row) => (
        <div>
          <div>{timestampToFormat(row.user.createdAt, 'YYYY.MM.DD')}</div>
          <div>{timestampToFormat(row.user.createdAt, 'HH:mm:ss')}</div>
        </div>
      ),
      sorter: true,
      sortOrder: sorting.toAntOrder(GetUsersFieldEnum.CreatedAt),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 78,
      render: (row: Row) => (
        <>
          <Kit.MenuActions>
            <Kit.ItemAction
              type={'info'}
              text="Show info"
              disabled={aggDisabled}
              to={core.toPage(`/info/${row.user.id}`)}
            />
            <Kit.ItemAction
              type={'edit'}
              text="Edit"
              disabled={aggDisabled || !row.canEdit}
              to={core.toPage(`/edit/${row.user.id}`)}
            />
            <Kit.ItemAction
              type={'delete'}
              text="Delete"
              disabled={aggDisabled || !row.canDelete}
              onClick={() => deleteUser(row.user.id)}
            />
          </Kit.MenuActions>
        </>
      ),
      align: 'right' as 'right',
      fixed: 'right' as 'right',
    },
  ];

  return (
    <div className={cn(cx())}>
      <UsersMenu />

      <Kit.TitleActions title={`Users`}>
        <Kit.Action
          type={'create'}
          text={'Create user'}
          disabled={false}
          to={core.toPage('/add')}
        />
      </Kit.TitleActions>

      <Kit.FilterGrid>
        <Kit.FilterCol>
          <Input
            placeholder="Search query"
            allowClear
            onChange={onChangeSearch}
            readOnly={getUsersState.isFetching}
            value={filter.values.search}
          />
        </Kit.FilterCol>

        <Kit.FilterCol>
          <Select
            placeholder="User role"
            onChange={onChangeRole}
            value={filter.values.role}
            options={userRoleOptions}
          />
        </Kit.FilterCol>

        <Kit.FilterCol>
          <Select
            placeholder="User state"
            onChange={onChangeState}
            value={filter.values.state}
            options={userStateOptions}
          />
        </Kit.FilterCol>

        <Kit.FilterCol handler>
          <Button
            type="default"
            onClick={() => {
              getUsers({
                ...filter.reset(),
                ...nav.initialValues,
                ...sorting.initialValues,
              });
            }}
            className={cn('ant-reset-button')}
            icon={<ClearOutlined />}
            disabled={getUsersState.isFetching || filter.isInitial}
          >
            <span>Reset</span>
          </Button>{' '}
          <Button
            icon={<ReloadOutlined />}
            onClick={() =>
              getUsers({
                ...filter.values,
                ...nav.values,
                ...sorting.values,
              })
            }
          >
            Refresh
          </Button>
        </Kit.FilterCol>
      </Kit.FilterGrid>

      <Kit.ErrorContainer error={getUsersState.error}>
        <Table
          dataSource={getUsersState.data?.list ?? []}
          columns={columns}
          rowKey={(row) => row.user.id}
          rowClassName={(row) =>
            cx('row', { deleting: checkDeleting(row.user.id) })
          }
          onChange={(pagination, filters, sorter) => {
            setMemoryLimit(pagination.pageSize as number);

            getUsers({
              ...filter.values,
              ...(sorting.isAntChanged(sorter)
                ? nav.initialValues
                : nav.fromAntReq(pagination)),
              ...sorting.fromAntReq(sorter),
            });
          }}
          pagination={{
            ...tablePaginationConfig,
            ...nav.toAntPagination(),
          }}
          loading={getUsersState.isFetching}
          scroll={{ x: '100%' }}
        />
      </Kit.ErrorContainer>
    </div>
  );
};
