import React, { Fragment } from 'react';

import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { createCn } from 'bem-react-classname';

import './KitMenuActions.scss';

interface Props {
  className?: string;
  children?: React.ReactNode | null | React.ReactNode[];
}

const cx = createCn('KitMenuActions');

export const KitMenuActions: React.FC<Props> = ({ children, className }) => {
  return (
    <Dropdown
      trigger={['hover']}
      className={className}
      overlay={
        <Menu className={cx()}>
          {Array.isArray(children)
            ? children.map((child, index) => (
                <Fragment key={index}>{child}</Fragment>
              ))
            : children}
        </Menu>
      }
    >
      <Button
        type="link"
        size="large"
        icon={<MoreOutlined />}
        className={cx('button')}
      ></Button>
    </Dropdown>
  );
};
