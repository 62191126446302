import React from 'react';

import { Auth } from 'Auth';
import { Module } from 'core';
import { ActionsModule } from 'modules/actions';
import { AuthModule } from 'modules/auth/AuthModule';
import { ContentModule } from 'modules/content';
import { NotFoundPage } from 'modules/content/pages/NotFoundPage';
import { DashboardModule } from 'modules/dashboard';
import { MonitoringModule } from 'modules/monitoring';
import { ProfileModule } from 'modules/profile';
import { ProjectsModule } from 'modules/projects';
import { UsersModule } from 'modules/users';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';

import { MainLayout, OutsideLayout } from './layouts';

export const AppModules: React.FC = () => {
  return (
    <Router>
      <Auth>
        <Switch>
          <Route path="/auth">
            <Module layout={OutsideLayout} module={AuthModule} />
          </Route>

          <Route path="/dashboard">
            <Module
              layout={MainLayout}
              module={DashboardModule}
              permission={'DASHBOARD_MODULE'}
            />
          </Route>

          <Route path="/monitoring">
            <Module
              layout={MainLayout}
              module={MonitoringModule}
              permission={'MONITORING_MODULE'}
            />
          </Route>

          <Route path="/projects">
            <Module
              layout={MainLayout}
              module={ProjectsModule}
              permission={'PROJECTS_MODULE'}
            />
          </Route>

          <Route path="/profile">
            <Module
              layout={MainLayout}
              module={ProfileModule}
              permission={'PROFILE_MODULE'}
            />
          </Route>

          <Route path="/users">
            <Module
              layout={MainLayout}
              module={UsersModule}
              permission={'USERS_MODULE'}
            />
          </Route>

          <Route path="/actions">
            <Module
              layout={MainLayout}
              module={ActionsModule}
              permission={'ACTIONS_MODULE'}
            />
          </Route>

          <Route path="/">
            <Module layout={MainLayout} module={ContentModule} />
          </Route>

          <Route path="*" exact>
            <OutsideLayout>
              <NotFoundPage />
            </OutsideLayout>
          </Route>
        </Switch>
      </Auth>
    </Router>
  );
};
