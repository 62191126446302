import React from 'react';

import { renderToString } from 'react-dom/server';
import { Helmet } from 'react-helmet-async';

interface Props {
  children: React.ReactNode | any;
}

export const KitTitle: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>
          {renderToString(children)
            .replace(/<[^>]+>/g, '')
            .replace(/\s+/g, ' ')}
        </title>
      </Helmet>
      <h1>{children}</h1>
    </>
  );
};
