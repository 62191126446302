import React from 'react';

import { Tooltip } from 'antd';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';

import './ProjectLocale.scss';

const cx = createCn('ProjectLocale');

interface Props {
  className?: string;
  ico: string;
  name: string;
  small?: boolean;
}

export const ProjectLocale: React.FC<Props> = ({
  className,
  ico,
  name,
  small,
}) => {
  const render = (
    <div className={cn(className, cx())}>
      <div className={cx('ico')}>{ico}</div>
      {!small && <div className={cx('name')}>{name}</div>}
    </div>
  );

  return small ? (
    <Tooltip placement="top" title={name}>
      {render}
    </Tooltip>
  ) : (
    render
  );
};
