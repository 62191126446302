import React from 'react';

import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import moment from 'moment';
import { Kit } from 'ui/Kit';

import { DashboardMenu } from '../../components/DashboardMenu';
import { MessagesUpdatesStatsChart } from '../../components/MessagesUpdatesStatsChart';

import './MessagesUpdatesStatsPage.scss';

interface Props {}

const cx = createCn('MessagesUpdatesStatsPage');

export const MessagesUpdatesStatsPage: React.FC = () => {
  return (
    <div className={cn(cx())}>
      <DashboardMenu />

      <Kit.Title>Editing translations</Kit.Title>

      <div className={cx('graph')}>
        <MessagesUpdatesStatsChart
          fromDate={moment().subtract(12, 'month').format()}
        />
      </div>
    </div>
  );
};
