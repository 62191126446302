import React, { useCallback, useEffect, useState } from 'react';

import {
  faEraser,
  faBolt,
  faSpellCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AutoComplete, Modal, Table, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { createCn } from 'bem-react-classname';
import { core } from 'core';
import { orderBy, uniq, values } from 'lodash-es';
import { MessageDefaultKey } from 'modules/projects/shared/MessageDefaultKey';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { ServerState, server, AppThunk } from 'store';
import { Kit } from 'ui/Kit';
import { ProjectLocale } from 'ui/ProjectLocale';
import { ProjectVersionColor } from 'ui/ProjectVersionColor';
import { toasts } from 'ui/toasts';

import { VersionMessageCreatedAt } from '../VersionMessageCreatedAt';

import './EditMessagesFormModal.scss';

interface Props {
  editKey: string;
  projectId: number;
  ico: string;
  visible: boolean;
  close: () => void;
  success: () => void;
}

interface GridValues {
  [localeMessageId: string]: {
    localeMessageId: number;
    value: string;
    isChecked: boolean;
    isDeprecated: boolean;
    deprecatedAt: string | null;
  };
}

type List = NonNullable<ServerState['getProjectLocaleKeyData']['data']>['list'];

type Row = List[number];

const cx = createCn('EditMessagesFormModal');

type MessagesOptions = { label: string; options: { value: string }[] }[];

const toMessagesOptions = (
  data: ServerState['getProjectLocaleKeyData']['data'],
): MessagesOptions => {
  const messagesOptions: MessagesOptions = [];

  const historyMessagesValues = data?.historyMessages ?? [];
  const relatedMessagesValues = data?.relatedMessages ?? [];

  messagesOptions.push({
    label: 'History values of the key',
    options: historyMessagesValues.map((value) => ({ value })),
  });

  messagesOptions.push({
    label: 'Related values based on default message',
    options: relatedMessagesValues
      .filter((value) => !historyMessagesValues.includes(value))
      .map((value) => ({ value })),
  });

  return messagesOptions;
};

const subtractMessagesOptions = (
  messagesOptions: MessagesOptions,
  currentValue?: string,
): MessagesOptions => {
  const newMessagesOptions = messagesOptions
    .map((messagesOption) => ({
      label: messagesOption.label,
      options: messagesOption.options.filter(
        (option) => option.value !== currentValue,
      ),
    }))
    .filter((messagesOption) => messagesOption.options.length > 0);

  return newMessagesOptions;
};

const listToValues = (list: List): GridValues => {
  return list.reduce((obj: GridValues, row) => {
    obj[row.defaultMessage.id] = {
      localeMessageId: row.localeMessage.id,
      value: row.localeMessage.value,
      isChecked: row.localeMessage.isChecked,
      isDeprecated: row.defaultMessage.isDeprecated,
      deprecatedAt: row.defaultMessage.deprecatedAt,
    };
    return obj;
  }, {});
};

const toDurationAgo = (dateAt: string | null): string => {
  return dateAt
    ? moment.duration(moment(dateAt).diff(moment())).humanize() + ' ago'
    : '';
};

const title = 'Edit messages';

export const EditMessagesFormModal: React.FC<Props> = ({
  editKey,
  projectId,
  ico,
  close,
  visible,
  success,
}) => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  const [gridValues, setGridValues] = useState<GridValues>({});
  const [initialGridValues, setInitialGridValues] = useState<GridValues>({});
  const [copingId, setCopingId] = useState<number | null>(null);
  const [commentValue, setCommentValue] = useState<string>('');

  const getProjectLocaleKeyDataThunk = useCallback(
    (): AppThunk => async (dispatch, getState) => {
      await dispatch(
        server.getProjectLocaleKeyData.thunk.request({
          params: {
            key: editKey,
            projectId,
            ico,
          },
        }),
      );

      const getProjectLocaleKeyDataState = server.getProjectLocaleKeyData.selector.state(
        getState(),
      );

      const values = listToValues(
        getProjectLocaleKeyDataState.data?.list || [],
      );

      setInitialGridValues(values);
      setGridValues(values);

      setCommentValue(getProjectLocaleKeyDataState.data?.comment.value ?? '');
    },

    [editKey, ico, projectId],
  );

  useEffect(() => {
    if (editKey) {
      dispatch(getProjectLocaleKeyDataThunk());
      setCopingId(null);
      return () => {
        dispatch(server.getProjectLocaleKeyData.action.reset());
        dispatch(server.updateProjectLocaleKeyData.action.reset());
      };
    }
  }, [dispatch, editKey, projectId, ico, getProjectLocaleKeyDataThunk]);

  const getProjectLocaleKeyDataState = useSelector(
    server.getProjectLocaleKeyData.selector.state,
  );

  const [sort, setSort] = useState<{
    field?: string;
    order?: 'asc' | 'desc';
  }>({ field: 'createdAt', order: 'asc' });

  const toAntOrder = (field: string): any => {
    return sort.field && sort.order && sort.field === field
      ? `${sort.order}end`
      : undefined;
  };

  const onChangeComment = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCommentValue(event.target.value);
  };

  const list = orderBy(
    getProjectLocaleKeyDataState.data?.list || [],
    [sort.field],
    [sort.order ?? false],
  ) as NonNullable<typeof getProjectLocaleKeyDataState.data>['list'];

  const changeValue = (localeMessageId: number, value: string) => {
    setGridValues({
      ...gridValues,
      [localeMessageId]: {
        ...gridValues[localeMessageId],
        value: value,
      },
    });
  };

  const toggleIsChecked = (localeMessageId: number) => {
    setGridValues({
      ...gridValues,
      [localeMessageId]: {
        ...gridValues[localeMessageId],
        isChecked: !gridValues[localeMessageId].isChecked,
      },
    });
  };

  const updateProjectLocaleKeyDataState = useSelector(
    server.updateProjectLocaleKeyData.selector.state,
  );

  const messagesOptions = toMessagesOptions(getProjectLocaleKeyDataState.data);

  const onFinish = useCallback(() => {
    dispatch(
      ((): AppThunk => async (dispatch, getState) => {
        await dispatch(
          server.updateProjectLocaleKeyData.thunk.request({
            params: { projectId, ico, key: editKey },
            body: {
              messages: values(gridValues).map((row) => ({
                id: row.localeMessageId,
                value: row.value,
                isChecked: row.isChecked,
              })),
              commentText: commentValue,
            },
          }),
        );

        const error = server.updateProjectLocaleKeyData.selector.error(
          getState(),
        );

        toasts.result({
          title: 'Update messages',
          error: error,
        });

        if (!error) {
          close();
          success();
        }
      })(),
    );
  }, [
    dispatch,
    projectId,
    ico,
    gridValues,
    editKey,
    commentValue,
    success,
    close,
  ]);

  const checkCopingValue = (localeMessageId: number): boolean => {
    return copingId !== null && copingId === localeMessageId;
  };

  const checkMatchValue = (localeMessageId: number): boolean => {
    return (
      copingId !== null &&
      gridValues[copingId]?.value === gridValues[localeMessageId]?.value
    );
  };

  const checkWarnMatchValue = (localeMessageId: number): boolean => {
    return (
      !!initialGridValues[localeMessageId]?.value &&
      copingId !== null &&
      copingId !== localeMessageId &&
      initialGridValues[copingId]?.value ===
        initialGridValues[localeMessageId]?.value &&
      gridValues[copingId]?.value !== gridValues[localeMessageId]?.value
    );
  };

  const checkDisabledPaste = (localeMessageId: number): boolean => {
    return (
      copingId === null ||
      gridValues[copingId]?.value === gridValues[localeMessageId]?.value
    );
  };

  const isDifferentValue =
    uniq(values(gridValues).map((grid) => grid.value)).length > 1;

  const checkDisabledCopy = (localeMessageId: number): boolean => {
    return (
      !isDifferentValue ||
      !gridValues[localeMessageId]?.value ||
      (copingId !== null &&
        gridValues[copingId]?.value === gridValues[localeMessageId]?.value)
    );
  };

  const checkInitialValue = (localeMessageId: number): boolean => {
    return (
      initialGridValues[localeMessageId]?.value ===
      gridValues[localeMessageId]?.value
    );
  };

  const aggFetching =
    updateProjectLocaleKeyDataState.isFetching ||
    getProjectLocaleKeyDataState.isFetching;

  return (
    <Modal
      visible={visible}
      onCancel={close}
      onOk={onFinish}
      title={title}
      confirmLoading={aggFetching}
      width={width - 48}
      centered
      className={cx()}
    >
      <div className={cx('container')}>
        <Kit.LoadContainer
          error={getProjectLocaleKeyDataState.error}
          isFetched={getProjectLocaleKeyDataState.isFetched}
          isFetching={getProjectLocaleKeyDataState.isFetching}
        >
          <div className={cx('infoBlock')}>
            <div className={cx('infoBlockDescriptions')}>
              <div className={cx('infoBlockRow')}>
                <div className={cx('infoBlockParam')}>Project:</div>
                <div className={cx('infoBlockValue')}>
                  {getProjectLocaleKeyDataState.data?.project.name}
                </div>
              </div>

              <div className={cx('infoBlockRow')}>
                <div className={cx('infoBlockParam')}>Language:</div>
                <div className={cx('infoBlockValue')}>
                  <ProjectLocale
                    ico={ico}
                    name={
                      getProjectLocaleKeyDataState.data?.locale.enName ?? ''
                    }
                  />
                </div>
              </div>

              <div className={cx('infoBlockRow')}>
                <div className={cx('infoBlockParam')}>Key:</div>
                <div className={cx('infoBlockValue')}>
                  <MessageDefaultKey value={editKey} />
                </div>
              </div>
            </div>

            <div className={cx('infoBlockComment')}>
              <TextArea
                className={cx('commentTextarea')}
                placeholder={'A common comment about the key in the project'}
                value={commentValue}
                onChange={onChangeComment}
              />
            </div>
          </div>

          <Table
            dataSource={list}
            columns={[
              {
                key: 'param',
                title: 'Version',
                render: (row: Row) => (
                  <>
                    <div className={cx('versionParam')}>
                      <NavLink
                        exact={true}
                        activeClassName="active"
                        to={core.toPage(
                          `/manage/${projectId}/versions/info/${row.projectVersion.id}`,
                        )}
                        key={row.projectVersion.id}
                      >
                        <ProjectVersionColor
                          key={row.projectVersion.param}
                          param={row.projectVersion.param}
                          isDeprecated={row.defaultMessage.isDeprecated}
                        />
                      </NavLink>
                    </div>
                    <div className={cx('versionName')}>
                      {row.projectVersion.name}
                    </div>
                  </>
                ),
                width: 120,
                sorter: true,
                sortOrder: toAntOrder('param'),
              },

              {
                key: 'defaultValue',
                title: 'Default message',
                render: (row: Row) => row.defaultMessage.value,
                width: 240,
                sorter: true,
                sortOrder: toAntOrder('defaultValue'),
              },

              {
                key: 'value',
                title: 'Translated message',
                render: (row: Row) => (
                  <div className={cx('valueContainer')}>
                    <div
                      className={cx('colValueTextarea', {
                        copyActive: checkCopingValue(row.defaultMessage.id),
                        copyMatch: checkMatchValue(row.defaultMessage.id),
                        warnMatch: checkWarnMatchValue(row.defaultMessage.id),
                      })}
                    >
                      <AutoComplete
                        options={subtractMessagesOptions(
                          messagesOptions,
                          gridValues[row.defaultMessage.id]?.value,
                        )}
                        className={cx('valueAutoComplete')}
                        onSelect={(value) => {
                          changeValue(row.defaultMessage.id, value);
                        }}
                        value={gridValues[row.defaultMessage.id]?.value}
                      >
                        <TextArea
                          value={gridValues[row.defaultMessage.id]?.value}
                          className={cx('valueTextarea', {
                            empty: !gridValues[row.defaultMessage.id]?.value,
                          })}
                          onChange={(e) =>
                            changeValue(row.defaultMessage.id, e.target.value)
                          }
                        />
                      </AutoComplete>
                    </div>

                    <div className={cx('colValueHandle')}>
                      {checkCopingValue(row.defaultMessage.id) ? (
                        <Kit.Action
                          title={'Clone message'}
                          type={'stopCopy'}
                          disabled={false}
                          onClick={() => {
                            setCopingId(null);
                          }}
                        />
                      ) : (
                        <Kit.Action
                          title={'Clone message'}
                          type={'copy'}
                          disabled={checkDisabledCopy(row.defaultMessage.id)}
                          onClick={() => {
                            setCopingId(row.defaultMessage.id);
                          }}
                        />
                      )}

                      <Kit.Action
                        title={'Paste and replace message'}
                        type={'paste'}
                        disabled={checkDisabledPaste(row.defaultMessage.id)}
                        onClick={() => {
                          if (copingId && gridValues[copingId].value) {
                            changeValue(
                              row.defaultMessage.id,
                              gridValues[copingId]?.value,
                            );
                          }
                        }}
                      />

                      <Kit.Action
                        title={'Take back message'}
                        type={'takeBack'}
                        disabled={checkInitialValue(row.defaultMessage.id)}
                        onClick={() => {
                          changeValue(
                            row.defaultMessage.id,
                            initialGridValues[row.defaultMessage.id].value ||
                              '',
                          );
                        }}
                      />
                    </div>
                  </div>
                ),
                width: 280,
                sorter: true,
                sortOrder: toAntOrder('value'),
              },

              {
                key: 'isChecked',
                title: 'Checked',
                render: (row: Row) => (
                  <div className={cx('cellIsChecked')}>
                    <button
                      type="button"
                      className={cx('btnIsChecked', {
                        is: gridValues[row.defaultMessage.id]?.isChecked,
                      })}
                      onClick={() => toggleIsChecked(row.defaultMessage.id)}
                    >
                      <FontAwesomeIcon
                        icon={faSpellCheck}
                        size="1x"
                        className={cx('btnIsCheckedIcon')}
                      />
                    </button>
                  </div>
                ),
                width: 60,
                sorter: true,
                sortOrder: toAntOrder('isDeprecated'),
              },

              {
                key: 'isDeprecated',
                title: 'Status',
                render: (row: Row) => (
                  <div className={cx('cellDeprecated')}>
                    {gridValues[row.defaultMessage.id]?.isDeprecated ? (
                      <>
                        <Tooltip placement="top" title={'Deprecated'}>
                          <FontAwesomeIcon icon={faEraser} size="1x" />

                          <div className={cx('deprecatedAgo')}>
                            {toDurationAgo(
                              gridValues[row.defaultMessage.id].deprecatedAt,
                            )}
                          </div>
                        </Tooltip>
                      </>
                    ) : (
                      <Tooltip placement="top" title={'Active'}>
                        <div className={cx('activeMessage')}>
                          <FontAwesomeIcon
                            icon={faBolt}
                            size="1x"
                            className={cx('activeMessageIcon')}
                          />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                ),
                width: 60,
                sorter: true,
                sortOrder: toAntOrder('isDeprecated'),
              },
              {
                key: 'createdAt',
                width: 90,
                title: 'Created',
                render: (row: Row) => (
                  <VersionMessageCreatedAt
                    createdAt={row.defaultMessage.createdAt}
                  />
                ),
                sorter: true,
                sortOrder: toAntOrder('createdAt'),
              },
            ]}
            onChange={(pagination, filters, sorter: any) => {
              setSort({
                field: sorter.columnKey,
                order: sorter.order ? sorter.order?.replace('end', '') : 'asc',
              });
            }}
            scroll={{
              x: '100%',
            }}
            showSorterTooltip={false}
            loading={aggFetching}
            rowKey={(row: Row) => row.defaultMessage.id}
            pagination={false}
          ></Table>
        </Kit.LoadContainer>
      </div>
    </Modal>
  );
};
