import { floor } from 'lodash-es';

export const shorten = (srt: string, maxLength: number): string => {
  return srt.length > maxLength ? srt.substr(0, maxLength) + '...' : srt;
};

export const roundPercent = (amount: number, total: number): number => {
  const prop = total && amount / total;
  return amount && Math.max(1, floor(prop * 100));
};
