export const formDataFromObject = (obj: { [key: string]: any }): FormData => {
  const formData = new FormData();
  for (let key in obj) {
    formData.append(
      key,
      Array.isArray(obj[key]) ? JSON.stringify(obj[key]) : obj[key],
    );
  }
  return formData;
};
