import React, { useCallback } from 'react';

import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { core } from 'core';
import { useFormError } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { server, AppThunk } from 'store';
import { Kit } from 'ui/Kit';
import { toasts } from 'ui/toasts';

import { ProjectForm } from '../../shared/ProjectForm';
import { ProjectsMenu } from '../../shared/ProjectsMenu';

import './AddProjectPage.scss';

const cx = createCn('AddProjectPage');

interface Props {}

export const AddProjectPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const createProjectState = useSelector(server.createProject.selector.state);
  const formError = useFormError(createProjectState.error);

  const onFinish = useCallback(
    (values) => {
      dispatch(
        ((): AppThunk => async (dispatch, getState) => {
          await dispatch(
            server.createProject.thunk.request({
              body: values,
            }),
          );
          const { error, data } = server.createProject.selector.state(
            getState(),
          );

          toasts.result({
            title: 'Save project',
            error,
          });

          if (!error) {
            history.push(core.toPage(`/edit/${data?.id}`));
          }
        })(),
      );
    },
    [dispatch, history],
  );

  return (
    <div className={cn(cx())}>
      <ProjectsMenu />
      <Kit.Title>Create project</Kit.Title>

      <ProjectForm
        onFinish={onFinish}
        formError={formError}
        disabled={createProjectState.isFetching}
        action={'create'}
        initialValues={{
          name: '',
          slug: '',
          description: '',
          localeIds: [],
        }}
      />
    </div>
  );
};
