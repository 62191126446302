import React, { useCallback } from 'react';

import { Spin } from 'antd';
import { createCn } from 'bem-react-classname';
import cn from 'classnames';
import { useFormError } from 'hooks';
import { toInteger } from 'lodash-es';
import { ProjectInfo } from 'modules/projects/shared/ProjectInfo';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useLifecycles } from 'react-use';
import { server, AppThunk } from 'store';
import { Kit } from 'ui/Kit';
import { toasts } from 'ui/toasts';

import { ProjectForm } from '../../shared/ProjectForm';
import { ProjectsMenu } from '../../shared/ProjectsMenu';

import './EditProjectPage.scss';

const cx = createCn('EditProjectPage');

interface Props {}

export const EditProjectPage: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const id = toInteger(params.id);

  const refresh = useCallback(async () => {
    dispatch(
      server.getProjectById.thunk.request({
        params: { id },
      }),
    );
  }, [dispatch, id]);

  useLifecycles(
    () => {
      refresh();
    },
    () => {
      dispatch(server.getProjectById.action.reset());
      dispatch(server.updateProjectById.action.reset());
    },
  );

  const getProjectByIdState = useSelector(server.getProjectById.selector.state);

  const updateProjectByIdState = useSelector(
    server.updateProjectById.selector.state,
  );
  const formError = useFormError(updateProjectByIdState.error);

  const onFinish = useCallback(
    (values) => {
      dispatch(
        ((): AppThunk => async (dispatch, getState) => {
          await dispatch(
            server.updateProjectById.thunk.request({
              params: { id },
              body: values,
            }),
          );
          const { error } = server.updateProjectById.selector.state(getState());

          toasts.result({
            title: 'Save project',
            error,
          });

          if (!error) {
            refresh();
          }
        })(),
      );
    },
    [dispatch, id, refresh],
  );

  const aggFetching =
    updateProjectByIdState.isFetching || getProjectByIdState.isFetching;
  const { data } = getProjectByIdState;

  return (
    <div className={cn(cx())}>
      <ProjectsMenu />
      <Kit.Title>
        Project #{id} / edit{' '}
        {aggFetching && <Spin size="small" className="ant-header-spin" />}
      </Kit.Title>

      <Kit.LoadContainer
        error={getProjectByIdState.error}
        isFetched={getProjectByIdState.isFetched}
        isFetching={getProjectByIdState.isFetching}
      >
        <div className={cx('info')}>
          <ProjectInfo
            getProjectByIdState={getProjectByIdState}
            isInfo={false}
          />
        </div>

        <ProjectForm
          onFinish={onFinish}
          formError={formError}
          disabled={aggFetching}
          action={'edit'}
          initialValues={{
            name: data?.project.name || '',
            slug: data?.project.slug || '',
            description: data?.project.description || '',
            localeIds: data?.locales.map((locale) => locale.id) || [],
          }}
          options={{ canChangeSlug: data?.canChangeSlug }}
        />
      </Kit.LoadContainer>
    </div>
  );
};
