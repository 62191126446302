import React from 'react';

import { Alert } from 'antd';
import { get } from 'lodash-es';

interface Props {
  children: React.ReactNode;
  error: any;
  isFetched?: boolean;
}

export const KitErrorContainer: React.FC<Props> = ({
  children,
  error,
  isFetched = true,
}) => {
  const errorText = get(error, 'data.message') || get(error, 'statusText');

  if (!isFetched) {
    return null;
  }

  if (error) {
    return <Alert message={errorText} type="error" />;
  }

  return <>{children}</>;
};
