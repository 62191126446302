import React from 'react';

import { PermissionDto } from 'api/server';
import { core } from 'core';
import { usePermissions } from 'hooks';
import { useSelector } from 'react-redux';
import { Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import { app } from 'store';
import { Result403 } from 'ui/results/Result403';

export const Module: React.FC<{
  layout: React.ElementType;
  module: React.ElementType;
  permission?: PermissionDto['permission'];
}> = ({ layout, module, permission }) => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  core.fromModule(path);

  const authState = useSelector(app.auth.selector.state);
  const permissions = usePermissions();

  if (permission && !permissions.has(permission)) {
    if (authState.isLogged) {
      return React.createElement(layout, {}, <Result403 />);
    } else {
      return <Redirect to="/auth/login" />;
    }
  }

  return React.createElement(
    layout,
    { key: pathname },
    React.createElement(module),
  );
};
